import React, { useState, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _expand from "../../assets/images/expand-icon.svg";
import _collapse from "../../assets/images/collapse-icon.svg";
import CustomTooltip from "../../customComponents/customTooltip";
import CustomDataTableBase from "../../customComponents/customTable";
import ReactDataTables from "../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { ActivityDetailsModal } from "../modals";
import { DataTableLoader } from "../../utils/Loader";

const ActivityHistory = () => {
  const [toggleHistory, setToggleHistory] = useState(false);
  const [isActivityDetails, setActivityDetails] = useState(false);
  const [clickedRow, setClickedRow] = useState({});

  const { activityHistoryInfo, activeOrganizationInfo, isFetching } =
    useSelector(
      ({
        get_activity_dashboard: { data: activityHistoryData, isFetching },
        get_active_organization_detail: { data: activeOrganizationData },
      }) => {
        return {
          activityHistoryInfo: activityHistoryData || [],
          activeOrganizationInfo: activeOrganizationData || {},
          isFetching: isFetching,
        };
      },
      shallowEqual
    );

  const toggleActivityDetailsModal = (rowItem) => {
    setClickedRow(rowItem);
    setActivityDetails((prev) => !prev);
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        data: "id",
        render: function (data, type, row) {
          return `<a class="row-click">${data}</a>`;
        },
        onClick: (rowData, colIndex) =>
          toggleActivityDetailsModal(rowData, colIndex),
      },
      {
        title: "Date",
        data: "date",
        render: (data, type, row) => {
          return `<span class="d-none">${FormatDateFormat(
            data,
            "YYYYMMDD"
          )}</span> ${FormatDate(data)}`;
        },
      },
      {
        title: "For",
        data: "actionperformedto",
      },
      {
        title: "By",
        data: "actionperformedby",
      },
      {
        title: "Action",
        data: "actiondescription",
      },
    ],
    []
  );

  return (
    <div className="activity_history">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-start align-center">
              <div className="expand_bg">
                <img
                  src={toggleHistory ? _collapse : _expand}
                  className={toggleHistory ? "clpse" : "expd"}
                />
              </div>
              <div>
                <a
                  className="view_all cei act_his"
                  onClick={() => setToggleHistory((pre) => !pre)}
                >
                  Activity History
                </a>
              </div>
            </div>
            {toggleHistory && (
              <div className="" id="activity_history">
                <div className="row">
                  <div className="col-md-12">
                    <div className="coach_comparision1">
                      <div className="d-flex flex-start align-center">
                        <div className="flex justify-content-between">
                          <h4>Activity History</h4>
                        </div>
                        <CustomTooltip
                          tooltipText="?"
                          tooltipDesc="Summary of the activity associated with your account"
                        />
                      </div>
                      <div
                        className="agent_activity_history pb-0 "
                        id="activityhistorypartial"
                      ></div>
                      {isFetching ? (
                        <div className="text-center">
                          <DataTableLoader />
                        </div>
                      ) : (
                        <ReactDataTables
                          columns={columns}
                          data={activityHistoryInfo}
                          defaultSort={{ columnIndex: 1, direction: "desc" }}
                          className="px-3"
                          autoWidth={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isActivityDetails && (
          <ActivityDetailsModal
            clickedRow={clickedRow}
            showModal={isActivityDetails}
            toggleModal={toggleActivityDetailsModal}
          />
        )}
      </div>
    </div>
  );
};

export default ActivityHistory;
