import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import TimeFrame from "../../customComponents/timeFrame";

const MetricTimeFrameBlock = (props) => {
  const { workWeeksAllList, activeOrganizationInfo } = useSelector(
    ({
      get_dim_work_weeks_all: { data: workWeeksAllData },
      get_active_organization_detail: { data: activeOrganizationData },
    }) => {
      return {
        workWeeksAllList: workWeeksAllData,
        activeOrganizationInfo: activeOrganizationData || {},
      };
    },
    shallowEqual
  );

  return (
    <div
      className="form-group"
      style={{
        position: "relative",
        marginBottom: "0px",
      }}
    >
      <label className="position-absolute timeframe_lable">Time Frame</label>
      <TimeFrame
        workWeeksAllList={workWeeksAllList}
        getFrameData={props.getFrameData}
        currentFrameData={{
          frameData: activeOrganizationInfo?.FrameData,
          type: activeOrganizationInfo?.Type,
        }}
      />
    </div>
  );
};

export default MetricTimeFrameBlock;
