import React, { useState, useEffect, useContext, useCallback } from "react";
import Marquee from "react-fast-marquee";
import { IntradayMetricsModal } from "../modals";
const TikcerScroll = ({ intraDayInfo }) => {
  const [isIntradayMetrics, setIntradayMetrics] = useState(false);
  const [ClickedTikcer, setClickedTikcer] = useState({});

  const toggleIntradayMetrics = (rowItem) => {
    // setClickedTikcer(rowItem);
    setIntradayMetrics((prev) => !prev);
  };
  const sortedTickerData = [...intraDayInfo].sort(
    (a, b) =>
      new Date(a.recorddate + " " + a.timeofreport) -
      new Date(b.recorddate + " " + b.timeofreport)
  );
  const latestIntradayData = sortedTickerData?.[0];
  return (
    <div className="d-flex flex-start tikcerscroll">
      <div className="ticker_block">
        <div className="last-update-time">
          <div>
            <div
              className="metric_name"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              LAST <br />
              UPDATED
            </div>
            <div className="flex align-center justify-content-start">
              <div className="bold">{latestIntradayData.timeofreport}</div>
            </div>
          </div>
        </div>
        <Marquee
          gradient={false} // Disable gradient effect
          speed={110} // Adjust speed as needed
          pauseOnHover={true} // Pause animation on hover if desired
          className="marquee-container"
        >
          {[...latestIntradayData?.metricsData]
            .sort((a, b) => a.sortorder - b.sortorder)
            .map((item, index) => (
              <div
                key={index}
                className="metric_block"
                style={{
                  justifyContent: "start",
                }}
                onClick={() => toggleIntradayMetrics(item)}
              >
                <div>
                  <div className="metric_name">{item.metricName}</div>
                  <div className="flex align-center justify-content-start">
                    <div className="bold">
                      {item.metricValue === "" || item.metricValue === null
                        ? "0.0"
                        : item.metricValue}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Marquee>
      </div>

      {isIntradayMetrics && (
        <IntradayMetricsModal
          sortedTickerData={sortedTickerData}
          //   ClickedTikcer={ClickedTikcer}
          showModal={isIntradayMetrics}
          toggleModal={toggleIntradayMetrics}
        />
      )}
    </div>
  );
};

export default TikcerScroll;
