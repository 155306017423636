import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { taskManagementModule } from "../../../store/actions";
import taskStyle from "../../../assets/css/modules/taskManagement.module.css";
import MyHistoryTable from "./myHistory_Table";
import MyTaskTable from "./myTask_Table";
import { DataTableLoader } from "../../../utils/Loader";

const TaskFollowUpScreen = ({
  toggleModal,
  actionModule,
  activeOrganizationInfo,
  activityHistoryAllInfo,
  isFetching,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("my-task-history");

  const { myTasksInfo, isFetchingTask } = useSelector(
    ({
      task_management_module: {
        data: lastHistoryData,
        isFetching: isFetchingTask,
      },
    }) => {
      return {
        myTasksInfo: lastHistoryData || {},
        isFetchingTask,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    dispatch(
      taskManagementModule({
        companyguid: activeOrganizationInfo?.Companyguid,
        username: activeOrganizationInfo?.UserName,
        userEmployeeid: activeOrganizationInfo?.Loginid,
        taskType: actionModule,
      })
    );
  }, [dispatch, activeOrganizationInfo, actionModule]);

  const toggleTab = (tab) => activeTab !== tab && setActiveTab(tab);
  const closeBtn = (
    <div className="links_right w-45">
      <button className="modal_close" onClick={toggleModal} type="button">
        <img title="Attached File" src="images/close.svg" />
      </button>
    </div>
  );

  return (
    <div>
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="d-flex flex-start align-center">
          <div className="title">Task Management</div>
        </div>
      </ModalHeader>
      <ModalBody id="task-management-custom">
        <div className="container-fluid">
          <div className="row">
            <div
              className={`col-md-2 ${taskStyle["highlight-section"]}`}
              style={{
                maxWidth: "12.5%",
              }}
            >
              <Nav
                pills
                className="nav flex-column nav-pills"
                id="task-management-tab"
              >
                <NavLink
                  className="nav-link"
                  id="my-task-tab"
                  onClick={() => toggleTab("my-task")}
                >
                  <div
                    className={`${taskStyle.View_all_records} ${
                      activeTab === "my-task" && taskStyle.highlight
                    }`}
                  >
                    <img
                      className={taskStyle.myhistory}
                      src="images/task.jpg"
                    />
                    <h3 className={taskStyle.mytsk_head}>My Tasks</h3>
                  </div>
                </NavLink>
                <NavLink
                  className="nav-link"
                  id="my-task-history-tab"
                  onClick={() => toggleTab("my-task-history")}
                >
                  <div
                    className={`${taskStyle.View_all_history} ${
                      activeTab === "my-task-history" && taskStyle.highlight
                    }`}
                  >
                    <img
                      className={taskStyle.myhistory}
                      src="images/task.png"
                    />
                    <h3 className={taskStyle.mytsk_history}>My History</h3>
                  </div>
                </NavLink>
              </Nav>
            </div>
            <div className="col-md-10 p-0">
              <TabContent
                className="tab-content"
                id="task-management-tabContent"
                activeTab={activeTab}
              >
                <TabPane tabId="my-task">
                  <div className="table-reponsive" id="tblTableData">
                    {isFetchingTask ? (
                      <div className="text-center">
                        <DataTableLoader />
                      </div>
                    ) : (
                      <MyTaskTable rowData={myTasksInfo} />
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="my-task-history">
                  {isFetching ? (
                    <div className="text-center">
                      <DataTableLoader />
                    </div>
                  ) : (
                    <MyHistoryTable rowData={activityHistoryAllInfo} />
                  )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </ModalBody>
    </div>
  );
};

export default TaskFollowUpScreen;
