import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";
import { LeaderBoardGameModal } from "../modals";
import {
  AgentScheduleModal,
  AgentInsentiveModal,
  AgentInsentiveTablesWiseModal,
  CoxIncentivecxpbModal,
  CoxIncentives2sModal,
  AgentMTDdetailsYTDModal,
} from "../modals/RightSideTiles";
import { FormatDateyyyyMMdd } from "../../utils/dateFormat";

const Quadrant3cards = () => {
  const [clickedRow, setClickedRow] = useState({});
  const [modals, setModals] = useState({
    isModal: false,
    isAgentScheduleModal: false,
    isAgentInsentiveModal: false,
    isCoxIncentivecxpbModal: false,
    isCoxIncentives2sModal: false,
    isAgentMTDdetailsYTDModal: false,
    isAgentInsentiveTablesWiseModal: false,
  });

  const { qudarantCardInfo, activeOrganizationInfo } = useSelector(
    ({
      get_qudarant_three_cards_exec_agent: { data, isFetching, error },
      get_active_organization_detail: { data: activeOrganizationData },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        qudarantCardInfo: data,
      };
    },
    shallowEqual
  );
  const toggleModal = (modalName, rowItem) => {
    setClickedRow(rowItem);
    setModals((prevState) => ({
      ...prevState,
      [modalName]: !prevState[modalName],
    }));
  };
  const {
    cxPB_EstimatedPayoutDetails,
    cxPB_RankingDetails,
    get_WorkOrderDetails,
    get_PayoutHistory,
    get_TiersForAgent,
    s2S_EstimatedPayoutDetails,
    getPreselectIncentives,
    agentGameTileModel,
    agentSchedulinglist,
    hideIncentiveTiles,
    hideCoxCxPBIncentiveTile,
    hideCoxS2SIncentiveTile,
    cxPBCFTThresholdValue,
    mtdCoachingsCount,
    getAgentGameCards,
    incentivesCards,
    agentscheduletileview,
    agentschedulemessage,
    incentivesTileDetails,
    incentivesCards_OmniCare,
    mtdValue,
  } = qudarantCardInfo || {};

  const agentGameTile = agentGameTileModel?.slice().sort((a, b) => {
    if (a.endDate < b.endDate) return -1;
    if (a.endDate > b.endDate) return 1;
    if (a.gameName < b.gameName) return -1;
    if (a.gameName > b.gameName) return 1;
    return 0;
  });
  const organization =
    activeOrganizationInfo?.Organization?.toLowerCase().replaceAll(" ", "");
  const primaryprogram =
    activeOrganizationInfo?.Program?.toLowerCase().replaceAll(" ", "");
  return (
    <div>
      <ul id="quadrant3cards">
        {hideIncentiveTiles?.toLowerCase() != "y" &&
        agentscheduletileview == "Y" ? (
          <div
            className="right_tile metric-number-tile no-right-margin my_schedule"
            onClick={() => {
              toggleModal("isAgentScheduleModal");
            }}
          >
            <div className="metric-number-tile-header">My Schedule</div>
            <div className="div-block-588 mt-2 justify-content-center align-center">
              <div className="div-block-590 m-0">
                <div className="samp metric-numbers text-center">
                  View My Schedule
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {currentCompanyGuid === CompanyGUIDs["Premier Bank"] ? (
          <>
            {organization == "premierbankcard" && (
              <>
                <div
                  className="right_tile metric-number-tile no-right-margin my_schedule"
                  onClick={() => toggleModal("isAgentMTDdetailsYTDModal")}
                >
                  <div className="metric-number-new-tile-header">
                    My Level Up
                  </div>
                  <div className="div-block-588 mt-2 justify-content-start align-center">
                    <div className="div-block-590 m-0">
                      <div className="metric-number-pbc">
                        {mtdValue || "$0.00"}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        MTD
                      </div>
                    </div>
                  </div>
                </div>
                {primaryprogram != "coll-erms" && (
                  <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard">
                    <a
                      href="https://app.powerbi.com/groups/aaaf6ac3-7acf-4922-adeb-003e8c651f60/reports/957c37bb-b200-490e-8014-1e1da94ed868/ReportSection51f6411f80e5b7288f18"
                      target="_blank"
                      className="metric-number-tile-header"
                      style={{
                        color: "#54589d !important;",
                      }}
                    >
                      Performance Dashboard
                    </a>
                    <div className="div-block-588 mt-2 justify-content-center">
                      <div className="div-block-590 m-0">
                        <div className="samp">Incentive and Rep Ranking</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard pbc_cuurent_month_score">
                  <a
                    href="https://app.powerbi.com/groups/aaaf6ac3-7acf-4922-adeb-003e8c651f60/reports/49e409c0-dc1e-4cf6-a001-2074d6a15b91/ReportSection1f00340e6ed9e4d21050"
                    target="_blank"
                    className="metric-number-tile-header"
                  >
                    Current Month Quality Score
                  </a>
                </div>
                {primaryprogram == "coll-erms" && (
                  <>
                    <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                      <a
                        href="https://sp.nsgslb.premier.local/sites/Collections/AmplifAi/ERMS%20Incentive.xlsx?d=wf8cd041284874a3884788ded6b8df5fe"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        ERMS Incentive
                      </a>
                    </div>

                    <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                      <a
                        href="https://sp.nsgslb.premier.local/sites/Collections/AmplifAi/Rep%20Ranking.xlsx?d=wc55acf8cd9b24410bdfdd2daca280e80"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        ERMS Rep Ranking
                      </a>
                    </div>
                    <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                      <a
                        href="https://sp.nsgslb.premier.local/sites/Collections/AmplifAi/ERMS%20QuickView.xlsx?d=we0e28fac4df34f24af92087714e60bba"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        ERMS QuickView
                      </a>
                    </div>
                  </>
                )}
              </>
            )}
            {organization == "newcustomercenter" && (
              <>
                <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <a
                        href="https://app.powerbi.com/singleSignOn?ru=https%3A%2F%2Fapp.powerbi.com%2Fgroups%2F669a00fa-bccf-4a89-a7e0-23f237ef45e7%2Freports%2F8552adb1-c6e2-40e6-9199-dc07cb3279ee%2FReportSection%3FnoSignUpCheck%3D1"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        Current Month NCC Associate Quality
                      </a>
                      <div className="metric-number-tile-header">
                        NCC Quality
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <a
                        href="https://app.powerbi.com/singleSignOn?ru=https%3A%2F%2Fapp.powerbi.com%2Fgroups%2F669a00fa-bccf-4a89-a7e0-23f237ef45e7%2Freports%2F487659dc-0249-4894-89fb-561586983fbd%2FReportSection7a87f1b6332a7d7082e9%3FnoSignUpCheck%3D1"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        NCC Associate Incentive
                      </a>
                      <div className="metric-number-tile-header">
                        NCC Incentive
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_tile metric-number-tile no-right-margin incentive-tile pbc premiercard ncc">
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <a
                        href="https://sp.nsgslb.premier.local/sites/Dashboard/Shared%20Documents/Current%20and%20Monthly%20Team%20Stats.xlsx?d=w4156e94ef3504a319038f39067753cea"
                        target="_blank"
                        className="metric-number-tile-header"
                      >
                        Near Time Report Excel
                      </a>
                      <div className="metric-number-tile-header">
                        Online Stats
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          incentivesCards?.map((item, index) => (
            <div
              style={{
                backgroundColor: item.backgroundcolor,
              }}
              className="right_tile metric-number-tile no-right-margin incentive-tile"
              onClick={() => toggleModal("isAgentInsentiveModal", item)}
            >
              <div className="metric-number-tile-header"> {item.title}</div>
              <div className="div-block-588 mt-2 justify-content-center">
                <div className="div-block-590 m-0">
                  <div className="metric-numbers">{item.value}</div>
                  <div className="samp">{item.subscript}</div>
                </div>
              </div>
            </div>
          ))
        )}
        {currentCompanyGuid === CompanyGUIDs["Opendoor"] &&
          incentivesTileDetails?.length > 0 &&
          incentivesTileDetails.map((tilesdata) => (
            <div
              key={tilesdata.id}
              className="right_tile metric-number-tile no-right-margin incentive-tile pbc opendoor"
              style={{ backgroundColor: tilesdata.backgroundcolor }}
              onClick={() =>
                toggleModal("isAgentInsentiveTablesWiseModal", tilesdata)
              }
            >
              <div className="metric-number-tile-header text-left">
                {tilesdata.title}
              </div>
              <div className="div-block-588 mt-2 justify-content-center">
                <div className="div-block-590 m-0">
                  <div className="metric-numbers">{tilesdata.value}</div>
                  <div className="samp">
                    <em>{tilesdata.subscript}</em>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {currentCompanyGuid === CompanyGUIDs["Omnicare365"] &&
          organization == "unitedhealthgroup" && (
            <>
              {incentivesCards_OmniCare?.map((item) => (
                <div
                  key={item.id}
                  className="right_tile metric-number-tile no-right-margin incentive-tile"
                  onClick={() =>
                    toggleModal("isAgentInsentiveTablesWiseModal", item)
                  }
                >
                  <div className="metric-number-tile-header"> {item.title}</div>
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <div className="metric-numbers">{item.value}</div>
                      <div className="samp">Estimated Payout</div>
                    </div>
                  </div>
                </div>
              ))}
              {incentivesCards_OmniCare?.map((item) => (
                <div
                  key={item.id + "_subscript"}
                  className="right_tile metric-number-tile no-right-margin incentive-tile"
                  onClick={() =>
                    toggleModal("isAgentInsentiveTablesWiseModal", item)
                  }
                >
                  <div className="metric-number-tile-header"> {item.title}</div>
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <div className="metric-numbers"></div>
                      <div className="samp">{item.subscript}</div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

        {getPreselectIncentives?.length > 0 &&
          getPreselectIncentives.map((item) => (
            <div className="right_tile metric-number-tile no-right-margin preselect">
              <a href="~/Doc/Preselect tile popout.pdf" target="_blank">
                <h3 className="metric-number-tile-header text-center">
                  Preselect Status
                </h3>
                <div className="mt-2">
                  <p className={`preselect_status ${item.preselect}`}>
                    {item.preselect}
                  </p>
                  {item.recorddate == "" || item.recorddate == null ? (
                    <div className="bal-val rec_date h-25"></div>
                  ) : (
                    <div className="bal-val rec_date">
                      Last Updated Date :{FormatDateyyyyMMdd(item.recorddate)}
                    </div>
                  )}
                  <div id="highlightstatus" className={item.preselect}></div>
                </div>
              </a>
            </div>
          ))}

        <div className="right_tile metric-number-tile no-right-margin">
          {currentCompanyGuid ===
          CompanyGUIDs["Arise Virtual Solutions Inc."] ? (
            <div className="metric-number-tile-header"> MTD Enhancements</div>
          ) : (
            <div className="metric-number-tile-header"> MTD Coachings</div>
          )}
          <div className="div-block-588 mt-2">
            <div className="div-block-590">
              <div className="metric-numbers">{mtdCoachingsCount}</div>
              {currentCompanyGuid ===
              CompanyGUIDs["Arise Virtual Solutions Inc."] ? (
                <div>Total Enhancements</div>
              ) : (
                <div>Total Coachings</div>
              )}
            </div>
          </div>
        </div>
        {currentCompanyGuid === CompanyGUIDs["Cox Communications"] &&
          hideIncentiveTiles?.toLowerCase() != "y" && (
            <>
              {hideCoxCxPBIncentiveTile?.toLowerCase() == "n" && (
                <div
                  className="right_tile metric-number-tile no-right-margin incentive-tile pbc Agent_incentive_tile"
                  onClick={() => toggleModal("isCoxIncentivecxpbModal")}
                >
                  <div className="metric-number-tile-header">
                    CxPB Agent Incentive
                  </div>
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <div className="metric-numbers">
                        <div className="flex">
                          {cxPB_EstimatedPayoutDetails?.length > 0 ? (
                            <>
                              <div>$</div>
                              <div>
                                {
                                  cxPB_EstimatedPayoutDetails[0]
                                    .estimateD_PAYOUT
                                }
                              </div>
                            </>
                          ) : (
                            <div>No Data</div>
                          )}
                        </div>
                      </div>
                      <div className="samp">Estimated Payout</div>
                    </div>
                  </div>
                </div>
              )}
              {hideCoxS2SIncentiveTile?.toLowerCase() == "n" && (
                <div
                  className="right_tile metric-number-tile no-right-margin incentive-tile pbc Agent_incentive_tile"
                  onClick={() => toggleModal("isCoxIncentives2sModal")}
                >
                  <div className="metric-number-tile-header">
                    S2S Agent Incentive
                  </div>
                  <div className="div-block-588 mt-2 justify-content-center">
                    <div className="div-block-590 m-0">
                      <div className="metric-numbers">
                        <div className="flex">
                          {s2S_EstimatedPayoutDetails?.length > 0 ? (
                            <>
                              <div>$</div>
                              <div>
                                {s2S_EstimatedPayoutDetails[0].estimated_Payout}
                              </div>
                            </>
                          ) : (
                            <div>No Data</div>
                          )}
                        </div>
                      </div>
                      <div className="samp">Estimated Payout</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        {agentGameTile?.map((item, index) => {
          let tfVal = item.gameTimeFrame?.substring(0, 1)?.toLowerCase();
          let timeframe = {
            m: "Monthly",
            w: "Weekly",
            d: "Daily",
            i: "Intraday",
          };
          let isMemberRank = item.memberRank != null && item.memberRank != "";
          let memberRankStr = isMemberRank && item.memberRank.toString();
          let suffix = "";
          if (isMemberRank) {
            let lastDigit = memberRankStr.slice(-1);
            let secondLastDigit = memberRankStr.slice(-2, -1);
            if (secondLastDigit !== "1") {
              if (lastDigit === "1") {
                suffix = "st";
              } else if (lastDigit === "2") {
                suffix = "nd";
              } else if (lastDigit === "3") {
                suffix = "rd";
              }
            }
          }
          if (suffix === "") {
            suffix = "th";
          }
          return (
            <a
              onClick={() => toggleModal("isModal", item)}
              style={{ cursor: "pointer" }}
            >
              <div className="new_game_tile">
                <div className="tile_block">
                  <div className="flex space-between align-start">
                    <div className="game_metric_name">
                      <div
                        className="game_name"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        title={item.gameName}
                      >
                        {item.gameName}
                      </div>
                      <div
                        className="metric_name"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        title={item.metricname}
                      >
                        {item.metricname}
                      </div>
                    </div>
                    <div className="game_status_indication position-relative">
                      <div className="med">
                        <img src={item.gameBadgeidUrl} />
                      </div>
                      <div className="position_absolute">
                        {item.color?.toLowerCase() === "red" ? (
                          <div
                            className="yellow @item.color"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-html="true"
                            title="Getting there"
                          >
                            <img src="images/close_white.svg" />
                          </div>
                        ) : (
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-html="true"
                            title="On track to <b>win</b>"
                          >
                            <img src="images/correct.png" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="chat_count">
                    <div
                      className="d-flex flex-start"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-html="true"
                      title={
                        isMemberRank
                          ? "Current game rank is" + item.memberRank
                          : "No Data"
                      }
                    >
                      <div>
                        <img
                          src="images/rank_icon.svg"
                          className="no_opacity"
                        />
                      </div>
                      <div className="d-flex flex-start align-start">
                        <div className="pr-2">{item.memberRank}</div>
                        {isMemberRank && (
                          <div className="absolute-s">{suffix}</div>
                        )}
                      </div>
                    </div>
                    <div
                      className="d-flex flex-start"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bshtml="true"
                      title={`${item.chatcount} messages in chat`}
                    >
                      <div>
                        <img src="images/msg_icn.svg" />
                      </div>
                      <div>{item.chatcount}</div>
                    </div>
                  </div>
                </div>
                <div className="tile_bottom">
                  <div className="flex space-between align-center">
                    <div className="">
                      <b>{timeframe[tfVal]}</b> game ends in{" "}
                      <b>{item.numberOfDaysRemaining} day(s)</b>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </ul>
      {modals.isModal && (
        <LeaderBoardGameModal
          clickedRow={clickedRow}
          showModal={modals.isModal}
          toggleModal={() => toggleModal("isModal")}
        />
      )}
      {modals.isAgentScheduleModal && (
        <AgentScheduleModal
          clickedRow={clickedRow}
          showModal={modals.isAgentScheduleModal}
          toggleModal={() => toggleModal("isAgentScheduleModal")}
          agentschedulemessage={agentschedulemessage}
        />
      )}
      {modals.isAgentInsentiveModal && (
        <AgentInsentiveModal
          clickedRow={clickedRow}
          showModal={modals.isAgentInsentiveModal}
          toggleModal={() => toggleModal("isAgentInsentiveModal")}
        />
      )}
      {modals.isAgentInsentiveTablesWiseModal && (
        <AgentInsentiveTablesWiseModal
          clickedRow={clickedRow}
          showModal={modals.isAgentInsentiveTablesWiseModal}
          toggleModal={() => toggleModal("isAgentInsentiveTablesWiseModal")}
        />
      )}
      {modals.isCoxIncentivecxpbModal && (
        <CoxIncentivecxpbModal
          clickedRow={clickedRow}
          showModal={modals.isCoxIncentivecxpbModal}
          toggleModal={() => toggleModal("isCoxIncentivecxpbModal")}
          activeOrganizationInfo={activeOrganizationInfo}
          cxPB_EstimatedPayoutDetails={cxPB_EstimatedPayoutDetails}
          cxPB_RankingDetails={cxPB_RankingDetails}
        />
      )}
      {modals.isCoxIncentives2sModal && (
        <CoxIncentives2sModal
          clickedRow={clickedRow}
          showModal={modals.isCoxIncentives2sModal}
          toggleModal={() => toggleModal("isCoxIncentives2sModal")}
          s2S_EstimatedPayoutDetails={s2S_EstimatedPayoutDetails}
          get_WorkOrderDetails={get_WorkOrderDetails}
          get_PayoutHistory={get_PayoutHistory}
          get_TiersForAgent={get_TiersForAgent}
        />
      )}
      {modals.isAgentMTDdetailsYTDModal && (
        <AgentMTDdetailsYTDModal
          clickedRow={clickedRow}
          showModal={modals.isAgentMTDdetailsYTDModal}
          toggleModal={() => toggleModal("isAgentMTDdetailsYTDModal")}
        />
      )}
    </div>
  );
};

export default Quadrant3cards;
