import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactDataTables from "../../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../../utils/dateFormat";

const WorkOrderDetails = ({ rowData, showModal, toggleModal }) => {
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  const columns = [
    {
      title: "checked in Date",
      data: "checked_in_Date",
      render: (data, type, row) => {
        return `<span class="d-none">${FormatDateFormat(
          data,
          "YYYYMMDD"
        )}</span> ${FormatDateFormat(data, "DD/MM/YYYY")}`;
      },
    },
    {
      title: "customer Site",
      data: "customer_Site",
    },
    {
      title: "work Order No",
      data: "work_Order_No",
    },
    {
      title: "status",
      data: "status",
    },
    {
      title: "sale Type",
      data: "sale_Type",
    },
    {
      title: "ancillaries",
      data: "ancillaries",
    },
    {
      title: "psUs",
      data: "psUs",
    },
    {
      title: "Cox Mobile",
      data: "wireless",
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      external={closeBtn}
      id="CxPBRankingDetails_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="">
        <div class="row">
          <div
            class="col-md-12"
            style={{
              padding: "15px 30px",
            }}
          >
            <ReactDataTables
              data={rowData}
              columns={columns}
              // defaultSort={{ columnIndex: 0, direction: "desc" }}
              id="summary"
              paging={false}
              scrollY="75vh"
              fixedHeader={{
                header: false,
                footer: true,
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WorkOrderDetails;
