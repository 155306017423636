import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getAgentScheduling } from "../../../store/actions";
import { FormatDate, FormathhmmA } from "../../../utils/dateFormat";

const AgentScheduleModal = ({
  clickedRow,
  showModal,
  toggleModal,
  agentschedulemessage,
}) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, agentSchedulingInfo, isFetching } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },
        get_agent_scheduling: { data: agentSchedulingData, isFetching },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          agentSchedulingInfo: agentSchedulingData,
          isFetching: isFetching,
        };
      },
      shallowEqual
    );
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  useEffect(() => {
    dispatch(
      getAgentScheduling({
        companyguid: activeOrganizationInfo?.Companyguid,
        employeeid: activeOrganizationInfo?.Employeeid,
      })
    );
  }, [dispatch]);
  const sortedList = [...(agentSchedulingInfo || [])].sort(
    (a, b) => new Date(a.startmoment) - new Date(b.startmoment)
  );

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      //   centered={true}
      id="agent_scheduling_modal"
      external={closeBtn}
    >
      <ModalHeader
        // toggle={toggleModal}
        // close={closeBtn}
        className="custom-modal metric_header"
      >
        <h3>Agent Schedule</h3>
      </ModalHeader>
      <ModalBody id="">
        {sortedList?.map((schedule, index) => {
          const sortedAgentSchedulingdetails = [
            ...(schedule.agentSchedulingdetails || []),
          ].sort((a, b) => new Date(a.startmoment) - new Date(b.startmoment));
          return (
            <div className="row">
              <div className="col-md-12">
                <div className="agent_schedule_date">
                  {FormatDate(schedule.nominaldate)}
                </div>
              </div>
              <div className="col-md-12">
                <table className="table tiles-table tiles-table">
                  <thead>
                    <tr>
                      <th className="text-left">Description</th>
                      <th className="custom-pd">
                        <div className="row pl-0 bold">
                          <div className="col-md-12">
                            {schedule.segmentDisplayname == null
                              ? schedule.segmentname
                              : schedule.segmentDisplayname}
                          </div>
                          <div className="col-md-6 text-left p-rl-20">
                            Start
                          </div>
                          <div className="col-md-6 text-right p-rl-20">End</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedAgentSchedulingdetails?.map((details, index) => (
                      <tr>
                        <td className="text-left">
                          {details.segmentDisplayname == null
                            ? details.segmentname
                            : details.segmentDisplayname}
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-6 text-left">
                              <span className="localtimeconversion">
                                {FormathhmmA(details.startmoment)}
                              </span>
                            </div>
                            <div className="col-md-6 text-right">
                              <span className="localtimeconversion">
                                {FormathhmmA(details.stopmoment)}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-md-12">
            <h3 className="mt-20">{agentschedulemessage}</h3>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AgentScheduleModal;
