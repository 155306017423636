import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _editProfile from "../../assets/images/edit-profile.svg";
import CustomSubMenu from "../../customComponents/subMenu";
import { AuthContext, CompanyContext } from "../../utils/CompanyContext";
import {
  Recognition,
  Badge,
  ScorecardCircle,
  ViewDetailsFAQ,
} from "../partialScreens";
import { setOrganizationDetail } from "../../store/actions";
import {
  BalancedScorecard,
  MultiMonthScorecardModal,
  AIMLMessageModal,
} from "../modals";
import { getFirstChar, isNullOrEmpty } from "../../utils";
import {
  AvatarWithTextLoader,
  BalancedScorecardLoader,
} from "../../utils/Loader";

const AgentProfileInfo = () => {
  const dispatch = useDispatch();
  const [showSubMenu, setShowSubMenu] = useState({});
  const [isScoreModal, setScoreModal] = useState(false);
  const [isAIMLMessagesModal, setAIMLMessagesModal] = useState(false);
  const [isMultiMonthScoreModal, setMultiMonthScoreModal] = useState(false);
  const [showMultiMonthScoreCard, setshowMultiMonthScoreCard] = useState(false);
  const [showBadge, setShowBadge] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const { userfirstname, userlastname } = useContext(AuthContext);
  const {
    agentDetailInfo,
    isFetching,
    isFetchingOrg,
    multiMonthScoreCardPermissionInfo,
    aimlMessagesData,
    dashboardConfigurationInfo,
  } = useSelector(
    ({
      get_agent_dashboard_org_programs: { isFetching: isFetchingOrg },
      get_dashboard_configuration_values: { data: dashboardConfigurationData },
      get_agent_details: { data: agentDetailData, isFetching },
      get_aiml_messages: { data: aimlMessagesData },
      get_multi_month_score_card_permission: {
        data: multi_monthScoreCardPermissionData,
      },
    }) => {
      return {
        agentDetailInfo: agentDetailData,
        aimlMessagesData: aimlMessagesData?.[0],
        multiMonthScoreCardPermissionInfo: multi_monthScoreCardPermissionData,
        isFetching: isFetching,
        isFetchingOrg: isFetchingOrg,
        dashboardConfigurationInfo: dashboardConfigurationData || [],
      };
    },
    shallowEqual
  );

  useEffect(() => {
    if (agentDetailInfo) {
      setUserInfo(agentDetailInfo);
      dispatch(
        setOrganizationDetail({
          UserName: agentDetailInfo.firstName + " " + agentDetailInfo.lastName,
          LoginEmpTitle: agentDetailInfo.title,
        })
      );
    }
    return () => {
      setUserInfo({});
    };
  }, [agentDetailInfo, dispatch]);

  useEffect(() => {
    if (aimlMessagesData?.loginmessages != null) {
      toggleAIMLMessagesModal();
    }
  }, [aimlMessagesData]);

  // useEffect(() => {
  //   if (multiMonthScoreCardPermissionInfo != null) {
  //     setshowMultiMonthScoreCard(multiMonthScoreCardPermissionInfo.length > 0);
  //   }
  // }, [multiMonthScoreCardPermissionInfo]);

  useEffect(() => {
    if (dashboardConfigurationInfo?.length > 0) {
      const updatedThroughOption = dashboardConfigurationInfo?.some(
        (c) =>
          c.configurationName === "MULTI-MONTH SCORECARD" &&
          c.configurationValue === "Y"
      );
      setshowMultiMonthScoreCard(updatedThroughOption);
    }
  }, [dashboardConfigurationInfo]);

  const toggleBadge = useCallback((badgeId) => {
    setShowBadge((pre) => ({
      ...pre,
      [badgeId]: !pre[badgeId],
    }));
  }, []);

  const toggleAIMLMessagesModal = useCallback(() => {
    setAIMLMessagesModal((pre) => !pre);
  }, []);

  const toggleScoreModal = useCallback(() => {
    setScoreModal((pre) => !pre);
  }, []);

  const toggleMultiMonthScoreModal = useCallback(() => {
    setMultiMonthScoreModal((pre) => !pre);
  }, []);

  const {
    firstName,
    lastName,
    avataruri,
    title,
    backgroundmediaurl,
    backgroundmediaid,
    profileimage,
    glidepathtitle,
    badges,
    imagepath,
    balancescorecardvalue,
    balancescorecardtitle,
    balancescorecardsubtitle,
    showBalanceScoreCardMetric,
    avatarid,
    balancescorecardcolorandrange,
  } = userInfo;
  const backgroundImageStyle = useMemo(
    () => ({
      backgroundImage: `linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url('${backgroundmediaurl}')`,
    }),
    [backgroundmediaurl]
  );
  const renderBadges = useMemo(
    () =>
      badges?.map(({ badgeid, badgeicon, superscriptvalue, badgetitle }) => {
        const handleToggleBadge = () => toggleBadge(badgeid);
        const handleMouseEnterLeave = () =>
          setShowSubMenu((prevStatus) => ({
            ...prevStatus,
            [badgeid]: !prevStatus[badgeid],
          }));

        return (
          <div
            key={badgeid}
            className={`badge_icon ml-20 ${badgeid}`}
            onClick={handleToggleBadge}
            // onMouseEnter={handleMouseEnterLeave}
            // onMouseLeave={handleMouseEnterLeave}
          >
            <div className="position-relative tool">
              <div>
                <img
                  src={
                    badgeicon.startsWith("https://")
                      ? badgeicon
                      : badgeicon.replace("/Content/assets/", "")
                  }
                  className="medal"
                  alt="badge"
                />
              </div>
              <div className="badge_count">
                <div>{superscriptvalue}</div>
              </div>
              <div className="commit-tooltip">
                <div>{badgetitle}</div>
              </div>
            </div>
          </div>
        );
      }),
    [badges, showSubMenu, toggleBadge]
  );
  const getOptionsData = useCallback(
    () => (
      <ul>
        {/* {showBalanceScoreCardMetric == "YES" && (
          <li onClick={toggleScoreModal}>Balanced Scorecard</li>
        )} */}
        <li onClick={toggleScoreModal}>Balanced Scorecard</li>
        <ViewDetailsFAQ />

        {showMultiMonthScoreCard && (
          <li onClick={toggleMultiMonthScoreModal}>Multi-Month Scorecard</li>
        )}
      </ul>
    ),
    [showMultiMonthScoreCard]
  );
  return (
    <div className="agent_profile_block">
      <div className="row" id="agentdetailsstrip">
        <div className="col-md-12">
          <div className="agent_profile" style={backgroundImageStyle}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="agent_name">
                {isFetching ? (
                  <div className="text-center">
                    <AvatarWithTextLoader />
                  </div>
                ) : (
                  agentDetailInfo && (
                    <>
                      <div className="agent_image">
                        {!isNullOrEmpty(profileimage) ? (
                          <img
                            src={`data:image/gif;base64,${profileimage}`}
                            alt="Profile"
                          />
                        ) : !isNullOrEmpty(profileimage) ? (
                          <img src={avataruri} alt="Profile" />
                        ) : (
                          <div className="agent_alpha">
                            {getFirstChar(firstName)} {getFirstChar(lastName)}
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="agent_name">
                          {firstName} {lastName}
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="agent_title">{title}</div>
                          {!isNullOrEmpty(glidepathtitle) && (
                            <div className="glidepath_title">
                              <div>{glidepathtitle}</div>
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                          <div className="badge">{renderBadges}</div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>

              <div className="agent_scorecard position-relative">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="d-flex justify-content-start align-items-center">
                    {isFetching ? (
                      <div className="text-center">
                        <BalancedScorecardLoader />
                      </div>
                    ) : (
                      // showBalanceScoreCardMetric == "YES" && (
                      <>
                        <div
                          className="scorecard_circle position-relative cursor-pointer"
                          onClick={toggleScoreModal}
                        >
                          <ScorecardCircle
                            rangeData={balancescorecardcolorandrange}
                            balancescorecardvalue={balancescorecardvalue}
                          />
                        </div>
                        <div
                          className="scorecard_data cursor-pointer"
                          onClick={toggleScoreModal}
                        >
                          <div className="score_val">
                            {balancescorecardvalue}
                          </div>
                          <div className="score_range">
                            {balancescorecardtitle}
                          </div>
                          <div className="score_progress">
                            {balancescorecardsubtitle}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="edit_profile">
                    {/* <div
                    // data-bs-toggle="modal"
                    // data-bs-target="#edit_profile_modal"
                    >
                      <img src={_editProfile} />
                    </div> */}
                  </div>
                </div>
                <CustomSubMenu
                  optionItem={getOptionsData()}
                  menuIcon="images/more-options-white.svg"
                />
              </div>
            </div>
          </div>
        </div>
        {/*  badge collapse*/}
        {showBadge["recognition_badges_collapse"] && (
          <Recognition toggleBadge={toggleBadge} />
        )}

        {/* recognition collapse */}
        {showBadge["collapseExample"] && (
          <Badge showBadge={showBadge.badgeid} toggleBadge={toggleBadge} />
        )}

        {isScoreModal && (
          <BalancedScorecard
            showModal={isScoreModal}
            showMultiMonthScoreCard={showMultiMonthScoreCard}
            toggleModal={toggleScoreModal}
            toggleMultiMonthScoreModal={toggleMultiMonthScoreModal}
          />
        )}
        {isMultiMonthScoreModal && (
          <MultiMonthScorecardModal
            showModal={isMultiMonthScoreModal}
            toggleModal={toggleMultiMonthScoreModal}
          />
        )}
        {isAIMLMessagesModal && (
          <AIMLMessageModal
            showModal={isAIMLMessagesModal}
            toggleModal={toggleAIMLMessagesModal}
            aimlMessagesData={aimlMessagesData}
            agentDetailInfo={agentDetailInfo}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(AgentProfileInfo);
