import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AuthContext, CompanyContext } from "../../utils/CompanyContext";
import TimeFrame from "../../customComponents/timeFrame";
import { CustomSelect } from "../../customComponents/select";
import { TikcerScroll } from "../partialScreens";
import {
  setOrganizationDetail,
  getDimWorkWeeksAllRequest,
  getAgentDashboardOrgProgramsTimeFrame,
  getAgentDetails,
  getUserMetaData,
  getAgentCoachHistory,
  getActivityDashboard,
  getAgentGameTable,
  getAgentCurrentCommitments,
  getAgentBestPractices,
  getExecAgentDashboardMetrics,
  getdashboardconfigurationValues,
  latestcompletedrecord,
  getqudarantthreecardsExecAgent,
  agentdbRecognizationDetails,
  getMultiMonthScoreCardPermission,
  getAgentAIMLConfigurations,
  getAIMLMessages,
  getIntradayAgentDashboardTicker,
} from "../../store/actions";

const TimeFrameBlock = (props) => {
  const dispatch = useDispatch();

  const [frameDataValue, setFrameData] = useState({});
  const [orgValue, setOrgValue] = useState();
  const [programValue, setProgramValue] = useState();
  const [programOption, setProgramOption] = useState([]);
  const {
    CGUID,
    Employeeid,
    LoginEmail,
    usertype,
    loginemptitle,
    DefaultOrganization,
  } = useContext(AuthContext);
  const { currentCompanyGuid } = useContext(CompanyContext);
  const defaultParam = {
    companyguid: currentCompanyGuid,
  };
  const {
    orgProgramsData,
    workWeeksAllList,
    activeOrganizationInfo,
    userMetaInfo,
    aimlConfigurationsInfo,
    intraDayInfo,
    agentDashboardTimeFrameInfo,
  } = useSelector(
    ({
      get_agent_dashboard_org_programs: { data },
      get_dim_work_weeks_all: { data: workWeeksAllData },
      get_active_organization_detail: { data: activeOrganizationData },
      get_user_meta_data: { data: userMetaData },
      get_agent_aiml_configurations: { data: aimlConfigurationsData },
      get_intra_day_agent_dashboard_ticker: { data: intraDayData },
      get_agent_dashboard_org_programs_time_frame: {
        data: agentDashboardTimeFrameData,
      },
    }) => ({
      orgProgramsData: data,
      workWeeksAllList: workWeeksAllData,
      activeOrganizationInfo: activeOrganizationData || {},
      userMetaInfo: userMetaData || {},
      aimlConfigurationsInfo: aimlConfigurationsData || {},
      intraDayInfo: intraDayData || [],
      agentDashboardTimeFrameInfo: agentDashboardTimeFrameData,
    }),
    shallowEqual
  );

  const saveSelectionInStore = (payload) =>
    dispatch(setOrganizationDetail(payload));

  const getDimWorkWeeksAllData = useCallback(
    (orgVal) =>
      dispatch(
        getDimWorkWeeksAllRequest({
          ...defaultParam,
          organization: orgVal,
        })
      ),
    [dispatch]
  );
  const getAIMLConfigurations = useCallback(
    (orgVal) =>
      dispatch(
        getAgentAIMLConfigurations({
          ...defaultParam,
          organization: orgVal,
        })
      ),
    [dispatch]
  );
  const handleOrgChange = (event) => {
    let orgVal = event.target.value;
    const selectedOrg = orgProgramsData.find(
      ({ organization }) => organization === orgVal
    );
    setProgramOption(selectedOrg.primaryPrograms);
    setProgramValue(selectedOrg.primaryPrograms[0]);
    getDimWorkWeeksAllData(orgVal);
    getAIMLConfigurations(orgVal);
    setOrgValue(orgVal);
    saveSelectionInStore({
      ...defaultParam,
      Organization: orgVal,
      Program: selectedOrg.primaryPrograms?.[0],
    });
    dispatch(
      getIntradayAgentDashboardTicker({
        CompanyGuid: currentCompanyGuid,
        organization: orgVal,
        primaryprogram: selectedOrg.primaryPrograms?.[0],
        Employee_id: Employeeid,
      })
    );
  };
  const refreshAgentDetails = useCallback(
    ({ recordtype, recorddate, primaryPrograms }) => {
      const employeeid = Employeeid;
      const fetchActions = [
        getAgentDetails({
          ...defaultParam,
          organization: orgValue,
          primaryprogram: primaryPrograms || programValue,
          employeeid,
          recordtype,
          recorddate,
        }),
        getUserMetaData({
          ...defaultParam,
          loginid: LoginEmail,
          varcharemployeeid: employeeid,
        }),
        getAgentCoachHistory({
          ...defaultParam,
          organization: orgValue,
          employeeid,
          program: primaryPrograms || programValue,
          accessloginid: employeeid,
        }),
        getActivityDashboard({
          ...defaultParam,
          organization: orgValue,
          employeeid,
          primaryprogram: primaryPrograms || programValue,
          dashboard: "L1",
        }),
        agentdbRecognizationDetails({
          ...defaultParam,
          organization: orgValue,
          employeeid,
          program: primaryPrograms || programValue,
          accessloginid: employeeid,
        }),
        getAgentGameTable({
          ...defaultParam,
          organization: orgValue,
          employeeid,
          program: primaryPrograms || programValue,
        }),
        getAgentCurrentCommitments({
          ...defaultParam,
          organization: orgValue,
          employeeid,
          program: primaryPrograms || programValue,
        }),
        getqudarantthreecardsExecAgent({
          ...defaultParam,
          organization: orgValue,
          employeenumber: employeeid,
          primaryprogram: primaryPrograms || programValue,
        }),
        getAgentBestPractices({
          ...defaultParam,
          organization: orgValue,
          program: primaryPrograms || programValue,
          employeeid,
        }),
        getdashboardconfigurationValues({
          ...defaultParam,
          organization: orgValue,
          dashboard: "Agent Dashboard",
          configurationName: "",
        }),
        latestcompletedrecord({ ...defaultParam, organization: orgValue }),
        getMultiMonthScoreCardPermission({
          ...defaultParam,
          organization: orgValue,
          employeenumber: employeeid,
        }),
        getExecAgentDashboardMetrics({
          ...defaultParam,
          organization: orgValue,
          varemployee_loginid: employeeid,
          primaryprogram: primaryPrograms || programValue,
          userlogedinemail: LoginEmail,
          loggedintitle: loginemptitle || "TEAM LEADER",
          canenhance: userMetaInfo?.[0]?.performenchance || "N",
          usertype: usertype,
          timeperiod: recordtype,
          recorddate,
        }),
      ];
      fetchActions.forEach((action) => dispatch(action));
    },
    [dispatch, orgValue, programValue, Employeeid]
  );

  const getFrameData = ({ type, frameData }) => {
    dispatch(
      getAgentDashboardOrgProgramsTimeFrame({
        ...defaultParam,
        organization: orgValue,
        primaryprogram: programValue,
        loginid: Employeeid,
        recordtype: type,
        recorddate: frameData.transdate,
      })
    );
    setFrameData({
      recordtype: type,
      recorddate: frameData.transdate,
    });
    // refreshAgentDetails({
    //   recordtype: type,
    //   recorddate: frameData.transdate,
    // });
    saveSelectionInStore({ FrameData: frameData, Type: type });
  };

  const onProgramChange = (event) => {
    let proVal = event.target.value;
    setProgramValue(proVal);
    saveSelectionInStore({
      Program: proVal,
    });
    refreshPartial(proVal);
  };

  const refreshPartial = useCallback(
    (programValue) => {
      let employeeid = Employeeid;
      let recorddate = activeOrganizationInfo?.FrameData.transdate || {};
      let recordtype = activeOrganizationInfo?.Type || {};
      // dispatch(
      //   getAgentDashboardOrgProgramsTimeFrame({
      //     ...defaultParam,
      //     organization: orgValue,
      //     primaryprogram: programValue,
      //     loginid: employeeid,
      //     recordtype: recordtype,
      //     recorddate: recorddate,
      //   })
      // );
      dispatch(
        getAgentDetails({
          ...defaultParam,
          organization: orgValue,
          primaryprogram: programValue,
          employeeid,
          recordtype,
          recorddate,
        })
      );
      dispatch(
        getUserMetaData({
          ...defaultParam,
          loginid: LoginEmail,
          varcharemployeeid: employeeid,
        })
      );
      dispatch(
        getIntradayAgentDashboardTicker({
          CompanyGuid: currentCompanyGuid,
          organization: orgValue,
          primaryprogram: programValue,
          Employee_id: employeeid,
        })
      );
      dispatch(
        getExecAgentDashboardMetrics({
          ...defaultParam,
          organization: orgValue,
          varemployee_loginid: employeeid,
          primaryprogram: programValue,
          userlogedinemail: LoginEmail,
          loggedintitle: "TEAM LEADER",
          canenhance: "N",
          usertype: "2",
          timeperiod: recordtype,
          recorddate: recorddate,
        })
      );
    },
    [dispatch, orgValue, activeOrganizationInfo, Employeeid]
  );

  useEffect(() => {
    if (orgProgramsData && orgProgramsData[0]) {
      const activeOrg = orgProgramsData.find(
        (or) => DefaultOrganization === or.organization
      );
      const { organization, primaryPrograms } = activeOrg
        ? activeOrg
        : orgProgramsData[0];
      setOrgValue(DefaultOrganization);
      setProgramOption(primaryPrograms);
      setProgramValue(primaryPrograms[0]);
      getDimWorkWeeksAllData(organization);
      getAIMLConfigurations(organization);
      dispatch(
        getIntradayAgentDashboardTicker({
          CompanyGuid: currentCompanyGuid,
          organization: organization,
          primaryprogram: primaryPrograms[0],
          Employee_id: Employeeid,
        })
      );
    }
  }, [orgProgramsData, getDimWorkWeeksAllData]);
  useEffect(() => {
    if (userMetaInfo?.length > 0) {
      saveSelectionInStore({
        titleroletype: userMetaInfo[0]?.titleroletype,
        performenchance: userMetaInfo[0]?.performenchance,
      });
    }
  }, [userMetaInfo]);
  useEffect(() => {
    if (
      aimlConfigurationsInfo?.[0]?.configurationName == "GENAIMESSAGING" &&
      aimlConfigurationsInfo?.[0]?.configurationValue == "Y"
    ) {
      dispatch(
        getAIMLMessages({
          ...defaultParam,
          organization: orgValue,
          program: programValue,
          employeeid: Employeeid,
        })
      );
    }
  }, [aimlConfigurationsInfo]);
  useEffect(() => {
    if (agentDashboardTimeFrameInfo?.length > 0) {
      const { primaryPrograms } = agentDashboardTimeFrameInfo[0];
      if (programValue != primaryPrograms[0]) {
        setProgramOption(primaryPrograms);
        setProgramValue(primaryPrograms[0]);
      }
      refreshAgentDetails({
        ...frameDataValue,
        primaryPrograms: primaryPrograms[0],
      });
    }
  }, [agentDashboardTimeFrameInfo]);
  return (
    <div className="org_prog_header">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex flex-start align-start">
            <div className="w-40">
              <div className="org_block">
                <div className="form-group w-32">
                  <label htmlFor="organization">Organization</label>
                  <select
                    className="form-select"
                    id="organization"
                    onChange={handleOrgChange}
                    value={orgValue}
                  >
                    {orgProgramsData?.map(({ organization }, optIndex) => {
                      return (
                        <option
                          key={`${organization}${optIndex}`}
                          value={organization}
                        >
                          {organization}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <CustomSelect
                  id="primaryprogram"
                  label="Program"
                  options={programOption}
                  value={programValue}
                  onChange={onProgramChange}
                />

                <div className="form-group w-32">
                  <label>Time Frame</label>
                  <TimeFrame
                    workWeeksAllList={workWeeksAllList}
                    getFrameData={getFrameData}
                  />
                </div>
              </div>
            </div>
            <div className="w-60">
              {intraDayInfo?.length > 0 && (
                <TikcerScroll intraDayInfo={intraDayInfo} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeFrameBlock;
