import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import TimeFrameTabContent from "./timeFrameTabContent";
import TimeFrameNavTab from "./timeFrameNavTab";
const DefaultTab = "v-pillstimeframe-monthly";
const Tabs = {
  "v-pillstimeframe-monthly": "monthly",
  "v-pillstimeframe-weekly": "weekly",
  "v-pillstimeframe-daily": "daily",
};
const TimeFrame = ({
  workWeeksAllList,
  getFrameData,
  currentFrameData,
  isMonthOnly,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(DefaultTab);
  const [activeTime, setActiveTime] = useState({});
  const toggleTab = (tab) => activeTab !== tab && setActiveTab(tab);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const getSeletedTime = (frameData) => {
    setActiveTime(frameData);
    getFrameData({
      frameData,
      type: Tabs[activeTab],
    });
    toggle();
  };
  useEffect(() => {
    if (workWeeksAllList?.length > 0) {
      const DefaultTimeframe = workWeeksAllList[0];
      if (currentFrameData) {
        setActiveTime(currentFrameData?.frameData);
        const activeType = Object.keys(Tabs).find(
          (key) => Tabs[key] === currentFrameData?.type
        );
        setActiveTab(activeType);
      } else {
        setActiveTime(DefaultTimeframe);
        getFrameData({
          frameData: DefaultTimeframe,
          type: Tabs[DefaultTab],
        });
      }
    }
  }, [workWeeksAllList]);

  useEffect(() => {
    if (currentFrameData) {
      setActiveTime(currentFrameData?.frameData);
      const activeType = Object.keys(Tabs).find(
        (key) => Tabs[key] === currentFrameData?.type
      );
      setActiveTab(activeType);
    }
  }, [currentFrameData]);

  return (
    <div className="dropdown" id="time_frame_dropdown">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          id="current_date"
          className="form-select"
          data-toggle="dropdown"
          tag="span"
        >
          {activeTime.displayname}
        </DropdownToggle>
        <DropdownMenu className="timeframe_selection">
          <div className="d-flex space-between align-start timeframe-scrollable">
            <div className="border-right border-transaprent">
              <TimeFrameNavTab
                activeTab={activeTab}
                toggleTab={toggleTab}
                isMonthOnly={isMonthOnly}
              />
            </div>
            <div className="border-left">
              <TimeFrameTabContent
                activeTab={activeTab}
                defaultActiveTime={activeTime}
                workWeeksAllList={workWeeksAllList}
                applyAction={getSeletedTime}
                cancelAction={toggle}
              />
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default TimeFrame;
