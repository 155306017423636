import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormatDateFormat } from "../../../utils/dateFormat";
import { getInitials, S2sEstimatedPayoutDetailsTable } from "./coxUtils";
import PayoutHistoryDetails from "./PayoutHistoryDetails";
import TiersDetails from "./TiersDetails";
import WorkOrderDetails from "./WorkOrderDetails";
import "../../../assets/css/coxModal.css";

const CoxIncentives2sModal = ({
  clickedRow,
  showModal,
  toggleModal,
  s2S_EstimatedPayoutDetails,
  get_WorkOrderDetails,
  get_PayoutHistory,
  get_TiersForAgent,
}) => {
  const [nestedModal, setNestedModal] = useState({
    isPayoutHistoryModal: false,
    isTiersModal: false,
    isWorkOrderModal: false,
  });
  const toggleNested = useCallback((modalName) => {
    setNestedModal((prevState) => ({
      ...prevState,
      [modalName]: !prevState[modalName],
    }));
  }, []);
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );

  const { agent_Name, quarter, estimated_Payout, scorecards, results, date } =
    s2S_EstimatedPayoutDetails?.[0] || {};
  const initials = getInitials(agent_Name);
  // const initials = "";
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      //   centered={true}
      id="cox_incentive"
      external={closeBtn}
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="cox_incentive_s2s" className="p-0">
        {s2S_EstimatedPayoutDetails?.length > 0 ? (
          <div className="container-fluid">
            <div
              className="row"
              style={{
                backgroundColor: "#f5f8fa",
              }}
            >
              <div className="col-md-7 pr-0">
                <div className="inc_leftblock">
                  <div className="incentive_block1">
                    <div className="flex space-between align-start">
                      <div className="detail_block">
                        <div className="inc_profile flex align-start">
                          <div className="inc_name_letters">
                            <div className="fs">{initials}</div>
                          </div>
                          <div className="inc_name_details">
                            <div className="namee" id="agent_details">
                              {agent_Name}
                            </div>
                            <p
                              style={{
                                fontWeight: "600px",
                              }}
                            >
                              Agent
                            </p>
                          </div>
                        </div>
                        <div className="inc_month flex align-center">
                          <div className="">Month</div>
                          <div>:</div>
                          <div
                            style={{
                              padding: "5px",
                              fontWeight: "500",
                            }}
                          >
                            {FormatDateFormat(date, "MMMM")}{" "}
                            {FormatDateFormat(date, "YYYY")}
                          </div>
                        </div>
                      </div>
                      <div
                        className="estimate_block"
                        style={{ marginTop: "46px" }}
                      >
                        <div className="white_bg">
                          <div
                            className={`mb-10 ${
                              estimated_Payout > 0 ? "" : "inc_red"
                            }`}
                            style={{ fontWeight: "200" }}
                          >
                            Estimated Payout
                          </div>
                          <div
                            className={`inc_dollar_s2s ${
                              estimated_Payout > 0 ? "inc_green" : "inc_red"
                            }`}
                          >
                            ${estimated_Payout}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="incentive_block2"
                    style={{
                      marginBottom: "65px",
                    }}
                  >
                    <div className="table-responsive">
                      <table className="table tiles-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Count</th>
                            <th>Rate</th>
                            <th>Estimated Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          <S2sEstimatedPayoutDetailsTable
                            estimatedPayoutDetails={s2S_EstimatedPayoutDetails}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pr-0">
                <div
                  className="inc_right_block1"
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <div
                    className="bgg"
                    style={{
                      marginTop: "-6px",
                    }}
                  >
                    <div className="pl-15">
                      <h3>Calculate Potential S2S Earnings</h3>
                      <div
                        className="pt-8 flex justify-content-center align-start"
                        style={{
                          marginBottom: "36px",
                        }}
                      >
                        <div className="inc_potential_tile ideal">
                          <div>Potential Payout</div>
                          <div className="inc_val">$--.--</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val"></div>
                          </div>
                        </div>
                        <div className="show_money_gif hid">
                          <img
                            src="images/slotmachine.gif?2"
                            style={{ width: "180px" }}
                            alt="Slot Machine"
                          />
                        </div>
                        <div className="inc_potential_tile redd_tile hid">
                          <div className="headd">Potential Payout</div>
                          <div className="inc_val">$0.00</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val">$166.67</div>
                          </div>
                        </div>
                        <div className="inc_potential_tile green_tile hid">
                          <div className="headd">Potential Payout</div>
                          <div className="inc_val">$0.00</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val">$166.67</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="potential_table">
                      <div className="table-responsive">
                        <table className="table tiles-table">
                          <thead>
                            <tr>
                              <th>New Count</th>
                              <th>New Rate</th>
                              <th>Potential Payout</th>
                            </tr>
                          </thead>
                          <tbody>
                            {s2S_EstimatedPayoutDetails?.map((item, index) => {
                              if (item.Type === "Ancillary Tier A") {
                                return (
                                  <tr key={index}>
                                    <td className="inc_input">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="anc_value"
                                        value={item.metric_Value}
                                        min="0"
                                        readOnly
                                      />
                                    </td>
                                    <td className="inc_input" id="anc_rate">
                                      --.--
                                    </td>
                                    <td className="inc_input" id="anc_payout">
                                      $--.--
                                    </td>
                                  </tr>
                                );
                              } else if (item.Type === "Cox Home Life") {
                                return (
                                  <tr key={index}>
                                    <td className="inc_input">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="psu_value"
                                        value={item.metric_Value}
                                        min="0"
                                        readOnly
                                      />
                                    </td>
                                    <td className="inc_input" id="psu_rate">
                                      --.--
                                    </td>
                                    <td className="inc_input" id="psu_payout">
                                      $--.--
                                    </td>
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr key={index}>
                                    <td className="inc_input">
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="wireless_value"
                                        value={item.metric_Value}
                                        min="0"
                                        readOnly
                                      />
                                    </td>
                                    <td
                                      className="inc_input"
                                      id="wireless_rate"
                                    >
                                      --.--
                                    </td>
                                    <td
                                      className="inc_input"
                                      id="wireless_payout"
                                    >
                                      $--.--
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="show_money">
                        <button
                          className="btn btn-primary"
                          id="calculate_potential_earn_s2s"
                          style={{
                            fontSize: "17px",
                            background: "#348bf2",
                          }}
                        >
                          Show Me the money
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="term_cond"
              style={{
                backgroundColor: "#f5f8fa",
              }}
            >
              <div
                className="row"
                style={{
                  marginTop: "-19px",
                }}
              >
                <div
                  className="col-md-7"
                  style={{ paddingRight: "0px", marginTop: "-48px" }}
                >
                  <div className="inc_terms_cond">
                    <div>
                      Please remember that this data includes estimates only.
                      Every effort is made to ensure that all data is accurate,
                      but there are regular updates, occasional delays and
                      sometimes corrections that will cause differences between
                      the numbers reported here and elsewhere from one day to
                      the next. The estimates on this page do not guarantee any
                      correlation to final payout for Service2Sales.
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inc_details">
                    <div className="flex justify-content-end">
                      <div className="mr-5">
                        <a
                          className="btn btn-primary"
                          onClick={() => toggleNested("isWorkOrderModal")}
                          style={{
                            background: "#414d5a",
                            borderColor: "#414d5a",
                          }}
                        >
                          Work Order Details
                        </a>
                      </div>
                      <div className="mr-5">
                        <a
                          className="btn btn-primary"
                          onClick={() => toggleNested("isTiersModal")}
                          style={{
                            background: "#414d5a",
                            borderColor: "#414d5a",
                          }}
                        >
                          Tiers
                        </a>
                      </div>
                      <div className="mr-5">
                        <a
                          className="btn btn-primary"
                          onClick={() => toggleNested("isPayoutHistoryModal")}
                          style={{
                            background: "#414d5a",
                            borderColor: "#414d5a",
                          }}
                        >
                          Payout History
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-dialog" style={{ fontSize: "80px" }}>
            <p>No Data</p>
          </div>
        )}
        {nestedModal.isPayoutHistoryModal && (
          <PayoutHistoryDetails
            rowData={get_PayoutHistory}
            showModal={nestedModal.isPayoutHistoryModal}
            toggleModal={() => toggleNested("isPayoutHistoryModal")}
          />
        )}
        {nestedModal.isTiersModal && (
          <TiersDetails
            rowData={get_TiersForAgent}
            showModal={nestedModal.isTiersModal}
            toggleModal={() => toggleNested("isTiersModal")}
          />
        )}
        {nestedModal.isWorkOrderModal && (
          <WorkOrderDetails
            rowData={get_WorkOrderDetails}
            showModal={nestedModal.isWorkOrderModal}
            toggleModal={() => toggleNested("isWorkOrderModal")}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
export default CoxIncentives2sModal;
