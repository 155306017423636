import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TopBarHeader from "./layout/header/topbar";
import MenuBarHeader from "./layout/header/menubar";
import Dashboard from "./screen/dashboard";
import { AuthContext, CompanyContext } from "./utils/CompanyContext";
import {
  getAgentDashboardOrgProgramsRequest,
  setOrganizationDetail,
} from "./store/actions";
import "./assets/css/dataTables.bootstrap4.css";
import "./index.css";

function App({ initialData, context, isTeams }) {
  const dispatch = useDispatch();
  const [appSettings, setAppSettings] = useState(null);
  const {
    comapnyGuid,
    varcharemployeeid,
    loggedinuseremail,
    employeeNo,
    username,
    usertype,
    ispracticevisible,
    clientname,
    userfirstname,
    userlastname,
    loginuserlickey,
    loginemptitle,
    showtaskicon,
    showgameicon,
    organization,
    layoutlogo,
    userImage,
    titlerolename,
  } = initialData || {};
  useEffect(() => {
    // sessionStorage.setItem("currentCompany", comapnyGuid);
    // Save Selection in Store
    dispatch(
      setOrganizationDetail({
        Companyguid: comapnyGuid,
        Organization: organization,
        Loginid: varcharemployeeid,
        Employeeid: varcharemployeeid,
        LoginEmail: loggedinuseremail,
        EmployeeNo: employeeNo,
      })
    );
    // Fetch initial data
    dispatch(
      getAgentDashboardOrgProgramsRequest({
        companyguid: comapnyGuid,
        loginid: varcharemployeeid,
      })
    );

    // Clean up
    return () => {
      // dispatch(resetData());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchAppSettings = async () => {
  //     try {
  //       // Initialize ConfigurationClient with your connection string or other authentication method
  //       const client = new ConfigurationClient("<your-connection-string>");

  //       // Fetch settings from Azure App Configuration
  //       const settings = await client.getConfigurationSetting({
  //         key: "your-setting-key",
  //       });

  //       // Extract and set the setting value
  //       setAppSettings(settings?.value);
  //     } catch (error) {
  //       console.error("Error fetching app settings:", error);
  //     }
  //   };

  //   fetchAppSettings();
  // }, []);
  // const urlParams = new URLSearchParams(window.location.search);
  // const currentCompanyGuid =
  //   urlParams.get("companyguid") || "EC7C39D0-7157-4EEC-B05B-EA723D0216D4";
  // const agent = urlParams.get("agent") || "MSTARKE";
  // const currentCompanyGuid = initialData?.companyguid;
  // const varcharemployeeid = initialData?.varcharemployeeid || "MSTARKE";
  const currentUser = {
    CGUID: comapnyGuid,
    Loginid: varcharemployeeid,
    Employeeid: varcharemployeeid,
    LoginEmail: loggedinuseremail,
    DefaultOrganization: organization,
    ...initialData,
  };
  return (
    <div className="App">
      <CompanyContext.Provider value={{ currentCompanyGuid: comapnyGuid }}>
        <AuthContext.Provider value={currentUser}>
          {/* <TopBarHeader /> */}

          {/* <MenuBarHeader /> */}
          <Dashboard />
        </AuthContext.Provider>
      </CompanyContext.Provider>
    </div>
  );
}

export default App;
