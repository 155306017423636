import React from "react";
import { getFirstChar, isNullOrEmpty } from "../../../utils";

const MetricPerformance = ({
  rowItem,
  agentDetailInfo,
  dashboardConfigurationInfo,
}) => {
  const {
    metricname,
    metricid,
    team_rank,
    goal,
    actual,
    program_rank,
    teamAverage,
    programAverage,
    metrictype,
    programmetriccolor,
    prgramprogress,
    teammetriccolor,
    teamprogressbar,
    metriccolor,
    agentprogress,
    showcoachnow,
    lastupdateddate,
  } = rowItem || {};
  const { firstName, lastName } = agentDetailInfo || {};
  const buble = `${getFirstChar(firstName)}${getFirstChar(lastName)}`;
  return (
    <div className="performance-bar position-relative w-100">
      {isNullOrEmpty(actual) && isNullOrEmpty(goal) ? (
        <p>No Data Available</p>
      ) : (
        <>
          {metrictype?.substring(0, 1).toLowerCase() === "r" &&
            (isNullOrEmpty(actual) || isNullOrEmpty(goal) ? (
              <div className="regular_bar d-flex justify-content-start align-center w-100">
                <div
                  className="regular position-relative"
                  style={{ width: "100%", backgroundColor: "gray" }}
                >
                  <div className="transparent_bg"></div>
                </div>
              </div>
            ) : (
              <div className="regular_bar d-flex justify-content-start align-center w-100">
                <div
                  className="regular position-relative"
                  style={{ width: "67%", backgroundColor: "#ef5350" }}
                >
                  <div className="transparent_bg"></div>
                </div>
                <div
                  className="inverse position-relative"
                  style={{ width: "33%", backgroundColor: "#6ccc76" }}
                ></div>
              </div>
            ))}
          {metrictype?.substring(0, 1).toLowerCase() === "i" &&
            (isNullOrEmpty(actual) || isNullOrEmpty(goal) ? (
              <div className="regular_bar d-flex justify-content-start align-center w-100">
                <div
                  className="regular position-relative"
                  style={{ width: "100%", backgroundColor: "gray" }}
                >
                  <div className="transparent_bg"></div>
                </div>
              </div>
            ) : (
              <div className="regular_bar d-flex justify-content-start align-center w-100">
                <div
                  className="regular position-relative"
                  style={{ width: "50%", backgroundColor: "#6ccc76" }}
                >
                  <div className="transparent_bg"></div>
                </div>
                <div
                  className="inverse position-relative"
                  style={{ width: "50%", backgroundColor: "#ef5350" }}
                ></div>
              </div>
            ))}
          {metrictype?.toLowerCase() === "other" && (
            <div className="regular_bar d-flex justify-content-start align-center w-100">
              <div
                className="regular position-relative"
                style={{ width: "40%", backgroundColor: "gray" }}
              >
                <div className="transparent_bg"></div>
              </div>
              <div
                className="inverse position-relative"
                style={{ width: "60%", backgroundColor: "gray" }}
              ></div>
            </div>
          )}

          {/* Bar */}
          <div
            className="team position-absolute"
            style={{
              left: `${teamprogressbar}%`,
              backgroundColor: `${teammetriccolor}`,
            }}
          >
            <div className="team_bar position-relative">
              <div
                className="tean_icon"
                style={{
                  backgroundColor:
                    metrictype?.toLowerCase() === "other"
                      ? "gray"
                      : `${teammetriccolor}`,
                }}
                data-bs-toggle="tooltip"
                title={`Team ${teamAverage}`}
              >
                T
              </div>
            </div>
          </div>

          <div
            className="program position-absolute"
            style={{
              left: `${prgramprogress}%`,
              backgroundColor: `${programmetriccolor}`,
            }}
          >
            <div className="program_bar position-relative">
              <div
                className="program_icon"
                style={{
                  backgroundColor:
                    metrictype?.toLowerCase() === "other"
                      ? "gray"
                      : `${programmetriccolor}`,
                }}
                data-bs-toggle="tooltip"
                title={`Program ${programAverage}`}
              >
                P
              </div>
            </div>
          </div>

          <div
            className="agent position-absolute"
            style={{
              left: `${agentprogress}%`,
              backgroundColor: `${metriccolor}`,
            }}
          >
            <div className="agent_bar position-relative">
              <div
                className="agent_icon"
                style={{
                  backgroundColor: `${metriccolor}`,
                }}
                data-bs-toggle="tooltip"
                title={`Me ${actual}`}
              >
                {buble}
              </div>
            </div>
          </div>
          {/* {dashboardConfigurationInfo
            ?.filter(
              (c) =>
                c.configurationName === "Q1_PERSONA" &&
                c.configurationValue === "Y"
            )
            ?.map((c, index) => (
              <div
                className="persona position-absolute"
                style={{ left: "100%" }}
                key={index}
              >
                <div>
                  <img
                    src="images/persona.svg"
                    data-bs-toggle="tooltip"
                    title="Persona 30"
                    alt=""
                  />
                </div>
              </div>
            ))} */}
        </>
      )}
    </div>
  );
};
export default MetricPerformance;
