import React, { useState, useEffect, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import MetricTrendChart from "./metricTrendChart";
import MetricDetailTrendChart from "./metricDetailTrendChart";
import { isNullOrEmpty } from "../../utils";
import { FormatDate } from "../../utils/dateFormat";
import { BarChartLoader } from "../../utils/Loader";
const windowHeight = window.innerHeight;

const MetricDetails = ({
  metricDetailsInfo,
  clickedRow,
  metricdetailspopupDetailsview,
  coachings,
  fnGetmetricDetails,
  trendChartMetricDetails,
}) => {
  const [allMetricDetails, setAllMetricDetails] = useState([]);
  const [active, setActive] = useState("count");
  const [activeMetric, setActiveMetric] = useState("");

  const { isFetching1, isFetching2 } = useSelector((state) => ({
    isFetching1: state.get_agent_metric_details_popup.isFetching,
    isFetching2: state.get_metric_details_popup_trend_chart.isFetching,
  }));
  const searchFilter = useCallback(
    (searchVal) => {
      const filteredList = metricDetailsInfo?.filter((item) =>
        item.column1key.toLowerCase().includes(searchVal.toLowerCase())
      );

      setAllMetricDetails(filteredList);
    },
    [metricDetailsInfo]
  );
  useEffect(() => {
    if (metricDetailsInfo) {
      setAllMetricDetails(metricDetailsInfo);
    }
  }, [metricDetailsInfo]);

  useEffect(() => {
    if (clickedRow) {
      searchFilter("");
    }
  }, [clickedRow, searchFilter]);

  const metricHeader = document.querySelector(".metric_header");
  let chart_hei, char_hei, metric_detail_hei;
  if (metricHeader) {
    chart_hei = windowHeight - metricHeader.offsetHeight - 100;
    char_hei = chart_hei / 2;
    const metricDetailsBlockHeight = document.querySelector(
      ".metric_details_block"
    );
    metric_detail_hei =
      windowHeight -
      metricHeader.offsetHeight -
      metricDetailsBlockHeight?.offsetHeight;
  }

  return (
    <div className="row">
      {/* Left Column */}
      <div className="col-md-6">
        {/* Updated Date */}
        <p className="modal_date">
          Updated through {FormatDate(clickedRow?.lastupdateddate)}
        </p>
        {/* Metric Search Input */}
        <div className="metric_details_block">
          <div className="metric_detail_serach">
            <input
              type="text"
              className="form-control typeahead"
              placeholder="Search for metric details.."
              onChange={(event) => searchFilter(event.target.value)}
              style={{
                outline: "1px solid #ced4da",
              }}
            />
          </div>
        </div>
        {/* Metric Details */}
        <div
          className="metric_details"
          style={{
            height: `${metric_detail_hei}px`,
          }}
        >
          <div
            className={`metric_detail_card coach_count ${
              active === "count" ? "active" : ""
            }`}
            onClick={(event) => {
              setActive("count");
            }}
          >
            <div style={{ minHeight: "40px" }}>Number of Coachings</div>
            <span>{coachings || 0}</span>
          </div>
          {allMetricDetails?.map((item, index) => {
            if (!isNullOrEmpty(item.columun2Value)) {
              return (
                <div
                  className={`metric_detail_card ${
                    index == active ? "active" : ""
                  }`}
                  onClick={(event) => {
                    setActive(index);
                    setActiveMetric(item.column1key);
                    fnGetmetricDetails(item.column1key);
                  }}
                >
                  <div className="d-flex space-between align-center">
                    <div
                      title={item.column1key}
                      style={{
                        maxWidth: "25ch",
                        whiteSpace: "normal",
                        minHeight: "40px",
                      }}
                    >
                      {item.column1key?.length > 40
                        ? `${item.column1key.substring(0, 38)}...`
                        : item.column1key}
                    </div>
                  </div>
                  <span>{item.columun2Value}</span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      {/* Right Column */}
      <div className="col-md-6">
        <div className="metric_detail_right_block">
          {/* Metric Trend Block */}
          <div className="metric_trend_block">
            <div>
              <h3>Metric Trend</h3>
            </div>
            <div id="metric_trend_chart">
              {isFetching1 ? (
                <div className="text-center">
                  <BarChartLoader />
                </div>
              ) : (
                <MetricTrendChart
                  char_hei={char_hei}
                  clickedRow={clickedRow}
                  metricData={metricdetailspopupDetailsview}
                />
              )}
            </div>
          </div>
          {/* Metric Detail Trend Block */}
          <div
            className={`metric_detail_trend_block ${
              active === "count" ? "hid" : ""
            }`}
          >
            <div>
              <h3>Metric Detail Trend</h3>
            </div>
            <div id="metric_detail_trend_chart">
              {isFetching2 ? (
                <div className="text-center">
                  <BarChartLoader />
                </div>
              ) : (
                <MetricDetailTrendChart
                  char_hei={char_hei}
                  clickedRow={clickedRow}
                  activeMetric={activeMetric}
                  metricData={trendChartMetricDetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricDetails;
