import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactDataTables from "../../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../../utils/dateFormat";

const TiersDetails = ({ rowData, showModal, toggleModal }) => {
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  const columns = [
    {
      title: "agent ID",
      data: "agent_ID",
    },
    {
      title: "group",
      data: "group",
    },
    {
      title: "type",
      data: "type",
    },
    {
      title: "lower Range",
      data: "lower_Range",
    },
    {
      title: "higher Range",
      data: "higher_Range",
    },
    {
      title: "pay Rate",
      data: "pay_Rate",
    },
    {
      title: "date",
      data: "date",
      render: (data, type, row) => {
        return `<span class="d-none">${FormatDateFormat(
          data,
          "YYYYMMDD"
        )}</span> ${FormatDateFormat(data, "DD/MM/YYYY")}`;
      },
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      external={closeBtn}
      id="CxPBRankingDetails_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="">
        <div class="row">
          <div
            class="col-md-12"
            style={{
              padding: "15px 30px",
            }}
          >
            <ReactDataTables
              data={rowData}
              columns={columns}
              // defaultSort={{ columnIndex: 0, direction: "desc" }}
              id="summary"
              paging={false}
              scrollY="75vh"
              fixedHeader={{
                header: false,
                footer: true,
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TiersDetails;
