import CompanyGUIDs, { currentCompanyGuid } from "./GUIDConst";

const isNullOrEmpty = function (value) {
  return value === null || value?.trim() === "" || value == undefined;
};

const GetOrdinal = (number) => {
  if (number <= 0) return number.toString();

  switch (number % 100) {
    case 11:
    case 12:
    case 13:
      return number + "th";
  }

  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};
const getLocalPathImage = (urlpath) => {
  if (urlpath?.includes("https://")) {
    return urlpath;
  } else {
    const filenames = urlpath.split("/").pop();
    const localPath = `images/${filenames}`;
    return localPath;
  }
};
const getFirstChar = (str) => str?.trimStart().substring(0, 1) || "";
const generateFlname = (agent) => {
  return agent
    .split(",")
    .reverse()
    .join(" ")
    .split(" ")
    .filter((x) => x.length >= 1 && /^[a-zA-Z]/.test(x))
    .map((x) => x.charAt(0).toUpperCase())
    .join("");
};
// function getInitials(firstName, lastName) {
//   const getFirstChar = (str) => str?.substring(0, 1) || "";
//   return {
//     firstInitial: getFirstChar(firstName),
//     lastInitial: getFirstChar(lastName),
//   };
// }

const getInitials = (firstName, lastName) => {
  const firstInitial = getFirstChar(firstName);
  const lastInitial = getFirstChar(lastName);

  if (firstInitial && lastInitial) {
    return `${firstInitial} ${lastInitial}`;
  } else if (firstInitial) {
    return firstInitial;
  } else if (lastInitial) {
    return lastInitial;
  } else {
    return "";
  }
};

export {
  CompanyGUIDs,
  currentCompanyGuid,
  isNullOrEmpty,
  GetOrdinal,
  getLocalPathImage,
  getFirstChar,
  getInitials,
  generateFlname,
};
