import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import VideoPlayer from "../../customComponents/videoPlayer";
import CustomTooltip from "../../customComponents/customTooltip";
import { getLocalPathImage } from "../../utils";
const LearningLibraryViewFrame = ({ clickedItem, showModal, toggleModal }) => {
  const closeBtn = (
    <div className="d-flex">
      <button className="modal_close" onClick={toggleModal} type="button">
        <img src="images/close.svg" />
      </button>
    </div>
  );
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      id="learning_resource_modal"
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal"
      >
        <div className="d-flex flex-start align-center">
          <div className="title">{clickedItem?.skillname}</div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <div>
          <VideoPlayer url={clickedItem?.videopath} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LearningLibraryViewFrame;
