export const URLs = {
  getAgentDashboardOrgPrograms: `AgentDashboard/GetAgentDashboardOrgPrograms`,
  getAgentDashboardOrgProgramsTimeFrame: `AgentDashboard/GetAgentDashboardOrgProgramsTimeFrame`,
  getDimWorkWeeksAll: `WorkWeeks/GetDimWorkWeeksAll`,
  getAgentDetails: `AgentDashboard/GetExecAgentDetails`,
  getUserMetaData: `Login/GetUserMetaData`,
  getAgentCoachHistory: `AgentDashboard/GetAgentCoachHistory`,
  getAgentCoachHistoryById: `AgentDashboard/GetAgentCoachHistoryById`,
  agentdbRecognizationDetails: `AgentDashboard/agentdbrecognizationdetails`,
  getAgentGameTable: `AgentDashboard/GetAgentGameTable`,
  getActivityDashboard: `Common/getActivityDashboard`,
  getAgentCurrentCommitments: `AgentDashboard/GetAgentCurrentCommitments`,
  getAgentBestPractices: `AgentDashboard/GetAgentBestPractices`,
  getExecAgentDashboardMetrics: `AgentDashboard/GetExecAgentDashboardMetrics`,
  getdashboardconfigurationValues: `AgentDashboard/getdashboardconfigurationValues`,
  latestcompletedrecord: `AgentDashboard/latestcompletedrecord`,
  getExecAGentBalanceScoreCardPopup: `AgentDashboard/GetExecAGentBalanceScoreCardPopup`,
  getCompanyOrgProgramConfigurations: `Game/GetCompayOrgConfigurationValues`,
  getMetricDetailsPopupListView: `M1_M2Dashboard/GetAllMetricDetails_MetricDetailsPopup_ListViewTimeFrame`,
  getAgentMetricDetailsPopup: `M1_M2Dashboard/GetPerformanceTrendCharts_AgentMetricDetailsPopupTimeFrame`,
  getCoachingsMetricDetailPopup: `M1_M2Dashboard/GetCoachings_MetricDetailPopup`,
  getMetricDetailsPopup: `M1_M2Dashboard/GetAllMetricDetails_MetricDetailsPopupTimeFrame`,
  getMetricDetailsPopupTrendChart: `M1_M2Dashboard/GetMetricDetailTrendChart_MetricDetailsPopupTimeFrame`,
  getAgentDashboardMetrics: `AgentDashboard/GetLeaderboardMetricsExecAgentDBNew`,
  getExecAgentdashboardCoachingDetails: `CoachingFollowup/GetExecAgentdashboardCoachingDetails`,
  getexecagentactivityhistoryview: `CoachingFollowup/Getexecagentactivityhistoryview`,
  taskManagementModule: `TaskManagement/taskManagementModule`,
  getqudarantthreecardsExecAgent: `AgentDashboard/GetqudarantthreecardsExecAgentDBNew`,
  getAgentCoachHistoryV3byactivityid: `CoachingFollowup/getAgentCoachHistoryV3byactivityid`,
  getmultimonthscorecarddimworkweeks: `AgentDashboard/Getmultimonthscorecarddimworkweeks`,
  getmultimonthScoreCardPopup: `AgentDashboard/GetmultimonthScoreCardPopup`,
  getmultimonthscorecardemployeedetails: `AgentDashboard/getmultimonthscorecardemployeedetails`,
  getGameByCompanyGuidOrgProgramEmpid: `Game/GetGameByCompanyGuidOrganizationProgramEmployeeid`,
  getMetricCalculationsMetricDetailPopup: `M1_M2Dashboard/GetMetricCalculations_MetricDetailPopup`,
  getActivityHistory: `CoachingFollowup/getActivityHistoryAPI`,
  getTaskSaveListForLastHistory: `TaskManagement/getTaskSaveListForLastHistory`,
  leaderBoardAPI: `CoachingFollowup/leaderBoardAPI`,
  taskHistory: `CoachingFollowup/TaskHistory`,
  getMultiMonthScoreCardPermission: `AgentDashboard/GetMultiMonthScoreCardPermission`,
  getAgentScheduling: `AgentDashboard/GetAgentScheduling`,
  getAgentInsentiveTables: `AgentDashboard/GetAgentInsentiveTables`,
  getIncentiveCardsExportAndPopUp: `AgentDashboard/GetIncentiveCards_ExportAndPopUp`,
  getCxPBEstimatedPayout: `CoxIncentives/GetCxPB_EstimatedPayout`,
  getS2SEstimatedPayout: `CoxIncentives/GetS2S_EstimatedPayout`,
  getCompayConfigurationValues: `Game/GetCompayConfigurationValues`,
  cxPBCalculatePotentialPayout: `dashboardNew/CxPB_CalculatePotentialPayout`,
  getMTDMyLevelupTileDetails_YTD: `AgentDashboard/GetMTDMyLevelupTileDetails_YTD`,
  getAIMLMessages: `AgentDashboard/Getaimlmessages`,
  getAgentAIMLConfigurations: `AgentDashboard/GetAgentAIMLMessagePopupConfigurations`,
  getAdditionalLinksFlagMetricDetail: `M1_M2Dashboard/GetAdditionalLinksAndCoachButtonFlag_MetricDetailPopup`,
  getIntradayAgentDashboardTicker: `AgentDashboard/GetIntradayAgentDashboardTicker`,
  getCxPBRankingDetails: `CoxIncentives/Get_CxPBRankingDetails`,
};
// getMetricDetailsPopup: `M1_M2Dashboard/GetAllMetricDetails_MetricDetailsPopup`,
// getAgentDashboardMetrics: `AgentDashboard/GetAgentDashboardMetrics`,
