import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import TeamRanking from "./teamRanking";
import { isNullOrEmpty, GetOrdinal } from "../../utils";
import CustomTooltip from "../../customComponents/customTooltip";
import SortableHeader from "../../utils/SortableHeader";
import ReactDataTables from "../../customComponents/ReactDataTables";
import { LeaderBoardGameModal } from "../modals";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { BlockLoader, RepeatableRowsLoader } from "../../utils/Loader";
import TeamProgramRank from "./teamProgramRank";

const LeaderBoardDetails = ({ clickedRow, agentDashboardMetricsInfo }) => {
  const [gameRow, setGameRow] = useState({});
  const [isModal, setModal] = useState(false);
  const [isAgentGameTable, setAgentGameTable] = useState(false);
  const [gameTable, setData] = useState([]);

  const isFetching = useSelector(
    (state) => state.get_agent_dashboard_metrics.isFetching
  );

  const toggleLeaderBoardGameModal = useCallback((rowItem) => {
    setGameRow(rowItem);
    setModal((prev) => !prev);
  }, []);

  const { getAgentMetricsnew, agentGameTable } =
    agentDashboardMetricsInfo || {};

  const filterByMetEmpData = useMemo(() => {
    return (
      getAgentMetricsnew?.filter(
        (item) =>
          item.metricid === clickedRow?.metricid &&
          item.employeeid === clickedRow?.employeeid
      ) ?? []
    );
  }, [getAgentMetricsnew, clickedRow]);

  const filterByMetData = useMemo(() => {
    return (
      getAgentMetricsnew?.filter(
        (item) =>
          item.metricid === clickedRow?.metricid && !isNullOrEmpty(item.actual)
      ) ?? []
    );
  }, [getAgentMetricsnew, clickedRow]);

  const filterByMetDataWithNull = useMemo(() => {
    return (
      getAgentMetricsnew?.filter(
        (item) =>
          item.metricid === clickedRow?.metricid && isNullOrEmpty(item.actual)
      ) ?? []
    );
  }, [getAgentMetricsnew, clickedRow]);

  const filteredAgentGameTable = useMemo(() => {
    return (
      agentGameTable?.filter(
        (item) =>
          item.metricname?.toLowerCase() ===
          clickedRow?.metricname?.toLowerCase()
      ) ?? []
    );
  }, [agentGameTable, clickedRow]);

  useEffect(() => {
    if (agentGameTable) {
      setData(filteredAgentGameTable);
      setAgentGameTable(filteredAgentGameTable?.length > 0);
    }
  }, [agentGameTable, filteredAgentGameTable]);
  const columns = useMemo(
    () => [
      {
        title: "Game",
        data: "gamename",
        onClick: toggleLeaderBoardGameModal,
        render: (data, type, row) =>
          data
            ? `<div class="d-flex flex-start align-center">            
          <div class="game_img">
            <img src=${row?.badge} alt="Badge" />
          </div>            
          <div class="game_name" style={{ whiteSpace: "wrap" }}>
            <a href="#">
              ${data} ${row?.createdDateTime}
            </a>
          </div>
        </div>`
            : "",
      },
      {
        title: "Date",
        data: "badgedate",
        render: (data) =>
          `<span class="d-none">${FormatDateFormat(
            data,
            "YYYYMMDD"
          )}</span> ${FormatDate(data)}`,
      },
    ],
    [toggleLeaderBoardGameModal]
  );

  return (
    <div className="leader_board_details">
      <div className="left_block">
        {isFetching ? (
          <BlockLoader />
        ) : (
          <TeamProgramRank filterByMetEmpData={filterByMetEmpData} />
        )}

        <div className="games_won">
          <div
            className="card"
            style={{
              border: !isAgentGameTable && "0px",
            }}
          >
            <div
              className="card-header"
              style={{
                borderBottom: !isAgentGameTable && "0px",
              }}
            >
              <div className="d-flex flex-start align-center">
                <div>
                  <b>Games Won</b>
                </div>
                {isAgentGameTable ? (
                  ""
                ) : (
                  <CustomTooltip
                    tooltipText="?"
                    tooltipDesc="You have not won any games for this metric yet."
                  />
                )}
              </div>
              <div className="font-light">
                {isAgentGameTable
                  ? "A list of all the games you have won for this metric."
                  : "You have not won any games for this metric yet."}
              </div>
            </div>
            <div className="card-body custom-sort">
              {isFetching ? (
                <div className="text-center">
                  <RepeatableRowsLoader width={400} rowHeight={100} />
                </div>
              ) : isAgentGameTable ? (
                <div className="games_won_table" id="games_won_table_wrapper">
                  <ReactDataTables
                    id={"games_won_table"}
                    columns={columns}
                    data={gameTable}
                    defaultSort={{ columnIndex: 1, direction: "desc" }}
                    autoWidth={false}
                  />
                </div>
              ) : (
                <div
                  className="games_won_table"
                  id="gamesnot_won_table_wrapper"
                >
                  <BlankTable />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="leader_board_progress">
        <TeamRanking
          clickedRow={clickedRow}
          filterByMetEmpData={filterByMetEmpData}
          filterByMetData={filterByMetData}
          filterByMetDataWithNull={filterByMetDataWithNull}
        />
      </div>
      {isModal && (
        <LeaderBoardGameModal
          clickedRow={gameRow}
          showModal={isModal}
          toggleModal={toggleLeaderBoardGameModal}
        />
      )}
    </div>
  );
};

export default LeaderBoardDetails;

const BlankTable = () => {
  return (
    <table
      className="table table-bordered display w-100"
      id={"gamesnot_won_table"}
    >
      <thead>
        <tr id="cu-srt">
          <th className="">Game Name</th>
          <th className="">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td />
          <td />
        </tr>
        <tr>
          <td />
          <td />
        </tr>
        <tr>
          <td />
          <td />
        </tr>
        <tr>
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  );
};
