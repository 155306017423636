import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getIncentiveCardsExportAndPopUp } from "../../../store/actions";
import { FormatDate, FormatDateMMdd } from "../../../utils/dateFormat";
import CompanyGUIDs, { currentCompanyGuid } from "../../../utils/GUIDConst";
const getDisclaimerMessage = (type, dashboardType) => {
  const baseMessage = (
    <div className="col-md-12">
      <h3
        className="dis"
        style={{ fontSize: "14px", marginTop: "20px", marginBottom: "20px" }}
      >
        <span className="disclaim">Disclaimer:</span>
        The displayed number is an estimation only and does not reflect some
        factors that impact your bonus
        {(type === "acquisition" || type === "resale") &&
          dashboardType === "L1" &&
          " (such as pilot activity or CSAT count beneath the required minimum)."}
        {(type === "acquisition" || type === "resale") &&
          dashboardType !== "L1" &&
          " (such as leave of absence or your team members flexing between acquisition and resale roles)."}
        Your finalized bonus number will be calculated and shared at the end of
        the quarter. Please contact your manager if you have any questions about
        what is shown on this tile.
        <br />
        Note that{" "}
        {dashboardType === "L1"
          ? "your bonus number will appear blank if you have not yet received a CSAT score in the quarter. Once you've received at least one CSAT score in the quarter, your estimated bonus will be displayed."
          : "if your team includes EPs working across both acquisition and resale, your estimated bonus is the sum of what is shown on this tile between both programs."}
      </h3>
    </div>
  );

  return baseMessage;
};

const getUnitedHealthDisclaimerMessage = () => (
  <div className="col-md-12">
    <h3
      className="dis"
      style={{ fontSize: "14px", marginTop: "20px", marginBottom: "20px" }}
    >
      <span className="disclaim">*Disclaimer*</span>
      Values in the table are subject to change based off system updates,
      operational directions and requirements. To qualify for payout Attendance
      must be at 92.00 or higher.
    </h3>
  </div>
);
const DisclaimerComponent = ({ primaryProgram, dashboard }) => {
  if (currentCompanyGuid === CompanyGUIDs["Opendoor"]) {
    if (primaryProgram?.toLowerCase().includes("acquisition")) {
      return getDisclaimerMessage("acquisition", dashboard);
    }
    if (primaryProgram?.toLowerCase().includes("resale")) {
      return getDisclaimerMessage("resale", dashboard);
    }
  } else if (currentCompanyGuid === CompanyGUIDs["Omnicare365"]) {
    if (
      primaryProgram?.toLowerCase().replaceAll(" ", "") === "unitedhealthgroup"
    ) {
      return getUnitedHealthDisclaimerMessage();
    }
  }
  return null;
};

const AgentScheduleModal = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, agentInsentiveInfo, isFetching } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },
        get_incentive_cards_export_and_popup: {
          data: agentInsentiveData,
          isFetching,
        },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          agentInsentiveInfo: agentInsentiveData,
          isFetching: isFetching,
        };
      },
      shallowEqual
    );
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );

  useEffect(() => {
    dispatch(
      getIncentiveCardsExportAndPopUp({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        program: activeOrganizationInfo?.Program,
        employeenumber: activeOrganizationInfo?.Employeeid,
        dashboard: "L1",
        type: "popup",
        startDate: FormatDateMMdd(new Date()),
        endDate: FormatDateMMdd(new Date()),
      })
    );
  }, [dispatch]);

  const headers =
    agentInsentiveInfo?.length > 0 ? Object.keys(agentInsentiveInfo[0]) : [];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      id="agent_insentive_monthly_modal"
      external={closeBtn}
    >
      <ModalHeader
        // toggle={toggleModal}
        // close={closeBtn}
        className="custom-modal metric_header"
      >
        <div class="flex space-between align-center">
          <div class="inc_hd">{clickedRow?.title}</div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <table
          class="table table-bordered incentive1 tiles-table"
          style={{
            fontWeight: "normal",
            marginBottom: "0px",
            border: "1px solid rgb(0 0 0 / 42%)",
          }}
          id="inc_table"
        >
          <thead>
            <tr>
              {headers?.map((header, index) => (
                <th
                  key={index}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {agentInsentiveInfo?.map((rowData, index) => (
              <tr key={index}>
                {headers.map((header, colIndex) => (
                  <td key={colIndex} style={{ textAlign: "center" }}>
                    {rowData[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <DisclaimerComponent
          primaryProgram={activeOrganizationInfo?.Program}
          dashboard="L1"
        />
      </ModalBody>
    </Modal>
  );
};
export default AgentScheduleModal;
