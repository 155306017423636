export const getInitials = (fullName) => {
  if (!fullName) return "";

  return fullName
    .split(" ")
    .filter((word) => word.length >= 1 && /^[a-zA-Z]/.test(word[0]))
    .map((word) => word[0].toUpperCase())
    .join("");
};

export const CftItemRow = ({ estimatedPayout }) => {
  const cftItem = estimatedPayout?.find(
    (item) => item.plaN_CMPNT_NM.toLowerCase() === "customer focus time"
  );

  if (!cftItem) {
    return null;
  }

  return (
    <tr>
      <td id="cft_metric_name">{cftItem.plaN_CMPNT_CD}</td>
      <td id="old_cft_value">{(cftItem.results * 100).toFixed(2)}</td>
      <td>{cftItem.cftgate === "Y" ? cftItem.ranK1 : "N/A"}</td>
      <td>N/A</td>
      <td className={cftItem.cftgate === "Y" ? "inc_green" : "inc_red"}>
        <button
          className={`btn btn-primary ${
            cftItem.cftgate === "Y" ? "gate_met" : "not_met"
          }`}
        >
          {cftItem.cftgate === "Y" ? "Gate Met!" : "Gate Not Met!"}
        </button>
      </td>
    </tr>
  );
};

export const S2sEstimatedPayoutDetailsTable = ({ estimatedPayoutDetails }) => {
  return estimatedPayoutDetails.map((item, index) => (
    <tr key={index}>
      {item.type === "Ancillary Tier A" ? (
        <>
          <td id="anc_metric_name">Ancillaries - Type A</td>
          <td id="old_anc_value">{item.metric_Value}</td>
          <td id="old_anc_rate">{item.pay_Rate}</td>
        </>
      ) : item.type === "Cox Home Life" ? (
        <>
          <td id="psu_metric_name">HomeLife PSUs</td>
          <td id="old_psu_value">{item.metric_Value}</td>
          <td id="old_psu_rate">{item.pay_Rate}</td>
        </>
      ) : (
        <>
          <td id="wireless_metric_name">Mobile Lead Gen</td>
          <td id="old_wireless_value">{item.metric_Value}</td>
          <td id="old_wireless_rate">{item.pay_Rate}</td>
        </>
      )}
      {item.metric_Value * item.pay_Rate > 0 ? (
        item.type === "Ancillary Tier A" ? (
          <td className="inc_green" id="old_anc_payout">
            ${(item.pay_Rate * item.metric_Value).toFixed(2)}
          </td>
        ) : item.type === "Cox Home Life" ? (
          <td className="inc_green" id="old_psu_payout">
            ${(item.pay_Rate * item.metric_Value).toFixed(2)}
          </td>
        ) : (
          <td className="inc_green" id="old_wireless_payout">
            ${(item.pay_Rate * item.metric_Value).toFixed(2)}
          </td>
        )
      ) : item.type === "Ancillary Tier A" ? (
        <td className="inc_red" id="old_anc_payout">
          $0.00
        </td>
      ) : item.type === "Cox Home Life" ? (
        <td className="inc_red" id="old_psu_payout">
          $0.00
        </td>
      ) : (
        <td className="inc_red" id="old_wireless_payout">
          $0.00
        </td>
      )}
    </tr>
  ));
};

export const CXPBEstimatedPayoutDetailsTable = ({ estimatedPayoutDetails }) => {
  return estimatedPayoutDetails?.map((item, index) => {
    const isCustomerResolveRate =
      item.plaN_CMPNT_NM.toLowerCase() === "customer resolve rate";
    const metricNameId = isCustomerResolveRate
      ? "fcr_metric_name"
      : "ce_metric_name";
    const valueId = isCustomerResolveRate ? "old_fcr_value" : "old_ce_value";
    const bandId = isCustomerResolveRate ? "old_fcr_band" : "old_ce_band";
    const payoutId = isCustomerResolveRate ? "old_fcr_payout" : "old_ce_payout";
    const payoutClass =
      item.cftgate === "Y" && item.payout > 0 ? "inc_green" : "inc_red";
    const payoutAmount =
      item.cftgate === "Y" && item.payout > 0 ? item.payout.toFixed(2) : "0.00";
    const rank = item.cftgate === "Y" ? item.ranK1 : "N/A";
    const band = item.cftgate === "Y" ? item.band : "Not Eligible";
    const value = (item.results * 100).toFixed(2);

    return (
      <tr key={index}>
        <td id={metricNameId}>{isCustomerResolveRate ? "FCR" : "Cust Sent"}</td>
        <td id={valueId}>{value}</td>
        <td>{rank}</td>
        <td id={bandId}>{band}</td>
        <td className={payoutClass} id={payoutId}>
          ${payoutAmount}
        </td>
      </tr>
    );
  });
};
