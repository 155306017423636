import React, { useEffect, useState } from "react";
import axios from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
const withInitialData = (WrappedComponent) => {
  return function WrappedWithInitialData(props) {
    const [initialData, setInitialData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [context, setContext] = useState(null);
    const [isTeams, setIsTeams] = useState(false);
    const baseURL = "https://amplifav4webapi-m1coaching.azurewebsites.net/api/";
    const teamValidateURL = "Login/ValidateMsTeamUserCredential";
    const QAURL = "Login/GetClaimsValidateUserLoginFromCosmos";

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      let loginmode = urlParams.get("loginmode");

      const fetchData = async (url, Param) => {
        try {
          const response = await axios.get(baseURL + url, {
            params: { ...Param },
          });
          setInitialData(response.data?.[0]);
          sessionStorage.setItem(
            "currentCompany",
            response.data?.[0]?.comapnyGuid
          );
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      if (loginmode == "teams") {
        // Initialize the Microsoft Teams SDK
        microsoftTeams.app
          .initialize()
          .then(() => {
            // Get the context
            setIsTeams(true);
            microsoftTeams.app
              .getContext()
              .then((context) => {
                setContext(context);
                let tenantID = context?.user.tenant?.id;
                let teamsUserID = context?.user.id;
                const teamsParam = {
                  tenantID: tenantID,
                  teamsUserID: teamsUserID,
                  teamsAadObjectId: "NA",
                };
                fetchData(teamValidateURL, teamsParam);
              })
              .catch((error) => {
                console.error("Error getting context:", error);
              });
          })
          .catch((error) => {
            console.error("Error initializing Teams SDK:", error);
          });

        // let tenantID = urlParams.get("tenantID");
        // let teamsUserID = urlParams.get("teamsUserID");
        // let teamsAadObjectId = urlParams.get("teamsAadObjectId");
        // const teamsParam = {
        //   tenantID: tenantID,
        //   teamsUserID: teamsUserID,
        //   teamsAadObjectId: teamsAadObjectId,
        // };
        // fetchData(teamValidateURL, teamsParam);
      } else if (loginmode == "qa") {
        let agent = urlParams.get("agent");
        let currentCompanyGuid = urlParams.get("companyguid");
        const QAparam = {
          companyguid: currentCompanyGuid,
          loggedinuser: agent,
        };
        fetchData(QAURL, QAparam);
      } else {
        setError({ message: "Invalid Env" });
        setLoading(false);
      }
    }, []);

    if (loading) {
      return <div className="loading">Loading...</div>;
    }

    if (error) {
      return <div>Error:{error.message}</div>;
    }

    return (
      <WrappedComponent
        initialData={initialData}
        context={context}
        isTeams={isTeams}
        {...props}
      />
    );
  };
};

export default withInitialData;
