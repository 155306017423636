import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTooltip from "../../../customComponents/customTooltip";
import { isNullOrEmpty } from "../../../utils";
import { FormatDate } from "../../../utils/dateFormat";
import { ParseHtml } from "../../../utils/parseHtml";
import evalStyle from "../../../assets/css/modules/evalhistory.module.css";
const EvaluationScreen = ({
  toggleModal,
  activeOrganizationInfo,
  activityHistoryAllInfo,
}) => {
  const formid = 247; // need to update this formid
  const closeBtn = (
    <div className="links_right w-45">
      <button className="modal_close" onClick={toggleModal} type="button">
        <img title="Attached File" src="images/close.svg" />
      </button>
    </div>
  );
  const sortedDataLabels = [...(activityHistoryAllInfo?.datalabels || [])].sort(
    (a, b) => a.sortorder - b.sortorder
  );
  const sortedAdditinalnotes = [
    ...(activityHistoryAllInfo?.additinalnotes || []),
  ].sort((a, b) => a.sortorder - b.sortorder);

  const isM1review =
    activityHistoryAllInfo?.myevaluationforms.find((x) => x.formid === formid)
      ?.M1review === true;
  const isM1ReviewFeedBackNotesDateTime =
    activityHistoryAllInfo?.formDetails.some(
      (x) => x.m1ReviewFeedBackNotesDateTime !== null
    );
  let showastrick = false;
  let isEnableAcknowledgementCheckbox = false;
  let isEnableAcknowledgementCheckbox1 = false;
  let isEnableAcknowledgementIsRequired = false;
  if (isM1ReviewFeedBackNotesDateTime) {
    isEnableAcknowledgementIsRequired =
      activityHistoryAllInfo?.myevaluationforms.find((x) => x.formid === formid)
        ?.enableAcknowledgementIsRequired === true;

    showastrick =
      activityHistoryAllInfo?.myevaluationforms !== null &&
      activityHistoryAllInfo?.myevaluationforms.some(
        (x) => x.formid === formid
      ) &&
      activityHistoryAllInfo?.myevaluationforms.find((x) => x.formid === formid)
        ?.enableAcknowledgementIsRequired === true;

    isEnableAcknowledgementCheckbox =
      activityHistoryAllInfo?.myevaluationforms.some(
        (x) => x.formid === formid
      ) &&
      activityHistoryAllInfo?.myevaluationforms.find((x) => x.formid === formid)
        ?.enableAcknowledgementCheckbox === true &&
      activityHistoryAllInfo?.formDetails.some((x) => x.ack === null);

    isEnableAcknowledgementCheckbox1 =
      activityHistoryAllInfo?.myevaluationforms.some(
        (x) => x.formid === formid
      ) &&
      activityHistoryAllInfo?.myevaluationforms.find((x) => x.formid === formid)
        ?.enableAcknowledgementCheckbox === true &&
      activityHistoryAllInfo?.formDetails.some((x) => x.ack !== null);
  }
  return (
    <div id="eval_history_details">
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="d-flex flex-start align-center">
          <div className="title">QA Form History</div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <div className="row">
          {sortedDataLabels?.map((dataLable, index) => (
            <div className="col-md-3">
              <div className="form-group">
                <label>{dataLable.lablename}</label>
                {dataLable.labeltype?.toLowerCase() == "richtext" ? (
                  <div
                    key={index}
                    className="form-control"
                    contentEditable="false"
                    style={{
                      maxHeight: "100%",
                      whiteSpace: "normal",
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {ParseHtml(dataLable.lablevalue)}
                  </div>
                ) : (
                  <div className="form-control" contentEditable="false">
                    {ParseHtml(dataLable.lablevalue)}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-12">
            {activityHistoryAllInfo?.questionanwers?.map((qaItem, index) => {
              const sortedquestions = [...(qaItem.questions || [])].sort(
                (a, b) => a.sortorder - b.sortorder
              );
              const formscoringtype = activityHistoryAllInfo?.myevaluationforms
                .find((x) => x.formid === formid)
                ?.formscoringtype.toLowerCase();

              return (
                <div
                  className={evalStyle.categories_questionsandans}
                  key={index}
                >
                  <div className="card">
                    <div className={evalStyle["card-header"]}>
                      <h3>{qaItem.category}</h3>
                    </div>
                    {sortedquestions?.map((question) => {
                      return (
                        <div className="card-body">
                          <div className={evalStyle.question_block}>
                            <div className={evalStyle.questionn}>
                              {question.ishtml
                                ? ParseHtml(question.questionname)
                                : question.questionname}
                            </div>
                            <div
                              className={`${evalStyle.answerr} ${evalStyle.green} `}
                            >
                              {question.answer}
                            </div>
                            <div className="d-flex space-between align-center">
                              <div className={evalStyle["w-80"]}>
                                <textarea
                                  className="form-control"
                                  rows="3"
                                  cols="10"
                                  readOnly
                                >
                                  {question.notes}
                                </textarea>
                              </div>
                              <div className={evalStyle["w-20"]}>
                                {formscoringtype === "average" ? (
                                  <span>{question.score}</span>
                                ) : (
                                  <>
                                    <span>{question.score}</span>
                                    <span> out of </span>
                                    <span>{question.maxpoints}</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className={evalStyle.categories_questionsandans}>
            <div className="col-md-12">
              <div className="card">
                <div className={evalStyle["card-header"]}>
                  <h3>Additional Notes</h3>
                </div>
                {sortedAdditinalnotes?.map((note, index) => {
                  return (
                    <div className="card-body">
                      <label>{note.noteslable}</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        cols="10"
                        readOnly
                      >
                        {note.notesanswer}
                      </textarea>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row" id="AckEvalBlockShow">
          <div className="col-md-12">
            <div className="form-group">
              <label>Acknowledgement Notes</label>
              <textarea
                spellCheck="true"
                rows="5"
                id="acknowledgement"
                placeholder="Please add your comments regarding this interaction"
                className={`form-control child acknowledgement `}
              ></textarea>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-check"></div>
          </div>
        </div> */}
      </ModalBody>
    </div>
  );
};

export default EvaluationScreen;
