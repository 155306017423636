import { all, takeLatest, put, call } from "redux-saga/effects";
import ActionTypes from "../reduxHelper";
import axios from "axios";
// import axiosInstance from "../api";
import APIs from "../request";
function* apiCallHandler(
  apiFunc,
  action,
  successActionType,
  failureActionType
) {
  try {
    const response = yield call(apiFunc, action.payload);
    yield put({
      type: successActionType,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: failureActionType,
      payload: error,
    });
  }
}
function* getAgentDashboardOrgPrograms(action) {
  try {
    const response = yield APIs.getAgentDashboardOrgPrograms(action.payload);
    yield put({
      type: ActionTypes.SET_ORGANIZATION_DETAIL_SET,
      payload: {
        Organization: response.data[0]?.organization,
        Program: response.data[0]?.primaryPrograms[0],
      },
    });
    yield put({
      type: ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_FAILURE,
      payload: error,
    });
  }
}

function* getDimWorkWeeksAll(action) {
  try {
    const response = yield APIs.getDimWorkWeeksAll(action.payload);
    yield put({
      type: ActionTypes.SET_ORGANIZATION_DETAIL_SET,
      payload: {
        FrameData: response.data[0],
        Type: response.data[0]?.typeofrecord,
      },
    });
    yield put({
      type: ActionTypes.GET_DIM_WORK_WEEKS_ALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_DIM_WORK_WEEKS_ALL_FAILURE,
      payload: error,
    });
  }
}

function* getAgentDashboardOrgProgramsTimeFrame(action) {
  yield apiCallHandler(
    APIs.getAgentDashboardOrgProgramsTimeFrame,
    action,
    ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_TIME_FRAME_SUCCESS,
    ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_TIME_FRAME_FAILURE
  );
}

function* getAgentDetails(action) {
  yield apiCallHandler(
    APIs.getAgentDetails,
    action,
    ActionTypes.GET_AGENT_DETAILS_SUCCESS,
    ActionTypes.GET_AGENT_DETAILS_FAILURE
  );
}

function* getUserMetaData(action) {
  yield apiCallHandler(
    APIs.getUserMetaData,
    action,
    ActionTypes.GET_USER_META_DATA_SUCCESS,
    ActionTypes.GET_USER_META_DATA_FAILURE
  );
}

function* getAgentCoachHistory(action) {
  yield apiCallHandler(
    APIs.getAgentCoachHistory,
    action,
    ActionTypes.GET_AGENT_COACH_HISTORY_SUCCESS,
    ActionTypes.GET_AGENT_COACH_HISTORY_FAILURE
  );
}
function* getAgentCoachHistoryById(action) {
  yield apiCallHandler(
    APIs.getAgentCoachHistoryById,
    action,
    ActionTypes.GET_AGENT_COACH_HISTORY_BY_ID_SUCCESS,
    ActionTypes.GET_AGENT_COACH_HISTORY_BY_ID_FAILURE
  );
}

function* agentdbRecognizationDetails(action) {
  try {
    const response = yield APIs.agentdbRecognizationDetails(action.payload);
    yield put({
      type: ActionTypes.AGENT_DB_RECOGNIZATION_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.AGENT_DB_RECOGNIZATION_DETAILS_FAILURE,
      payload: error,
    });
  }
}

function* getAgentGameTable(action) {
  try {
    const response = yield APIs.getAgentGameTable(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_GAME_TABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_GAME_TABLE_FAILURE,
      payload: error,
    });
  }
}

function* getActivityDashboard(action) {
  try {
    const response = yield APIs.getActivityDashboard(action.payload);
    yield put({
      type: ActionTypes.GET_ACTIVITY_DASHBOARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ACTIVITY_DASHBOARD_FAILURE,
      payload: error,
    });
  }
}

function* getExecAgentDashboardMetrics(action) {
  try {
    const response = yield APIs.getExecAgentDashboardMetrics(action.payload);
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_METRICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_METRICS_FAILURE,
      payload: error,
    });
  }
}

function* getExecAGentBalanceScoreCardPopup(action) {
  try {
    const response = yield APIs.getExecAGentBalanceScoreCardPopup(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_BALANCE_SCORE_CARD_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_BALANCE_SCORE_CARD_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getdashboardconfigurationValues(action) {
  try {
    const response = yield APIs.getdashboardconfigurationValues(action.payload);
    yield put({
      type: ActionTypes.GET_DASHBOARD_CONFIGURATION_VALUES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_DASHBOARD_CONFIGURATION_VALUES_FAILURE,
      payload: error,
    });
  }
}

function* latestcompletedrecord(action) {
  try {
    const response = yield APIs.latestcompletedrecord(action.payload);
    yield put({
      type: ActionTypes.LATEST_COMPLETED_RECORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LATEST_COMPLETED_RECORD_FAILURE,
      payload: error,
    });
  }
}

function* getAgentCurrentCommitments(action) {
  try {
    const response = yield APIs.getAgentCurrentCommitments(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_CURRENT_COMMITMENTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_CURRENT_COMMITMENTS_FAILURE,
      payload: error,
    });
  }
}

function* getAgentBestPractices(action) {
  try {
    const response = yield APIs.getAgentBestPractices(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_BEST_PRACTICES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_BEST_PRACTICES_FAILURE,
      payload: error,
    });
  }
}

function* getCompanyOrgProgramConfigurations(action) {
  try {
    const response = yield APIs.getCompanyOrgProgramConfigurations(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_COMPANY_ORG_PROGRAM_CONFIGURATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_COMPANY_ORG_PROGRAM_CONFIGURATIONS_FAILURE,
      payload: error,
    });
  }
}

function* getMetricDetailsPopupListView(action) {
  try {
    const response = yield APIs.getMetricDetailsPopupListView(action.payload);
    yield put({
      type: ActionTypes.GET_METRICDETAILSPOPUP_LISTVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_METRICDETAILSPOPUP_LISTVIEW_FAILURE,
      payload: error,
    });
  }
}

function* getAgentMetricDetailsPopup(action) {
  try {
    const response = yield APIs.getAgentMetricDetailsPopup(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_METRIC_DETAILS_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_METRIC_DETAILS_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getCoachingsMetricDetailPopup(action) {
  try {
    const response = yield APIs.getCoachingsMetricDetailPopup(action.payload);
    yield put({
      type: ActionTypes.GET_COACHINGS_METRIC_DETAIL_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_COACHINGS_METRIC_DETAIL_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getMetricDetailsPopup(action) {
  try {
    const response = yield APIs.getMetricDetailsPopup(action.payload);
    yield put({
      type: ActionTypes.GET_METRIC_DETAILS_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_METRIC_DETAILS_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getMetricDetailsPopupTrendChart(action) {
  try {
    const response = yield APIs.getMetricDetailsPopupTrendChart(action.payload);
    yield put({
      type: ActionTypes.GET_METRIC_DETAILS_POPUP_TREND_CHART_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_METRIC_DETAILS_POPUP_TREND_CHART_FAILURE,
      payload: error,
    });
  }
}

function* getAgentDashboardMetrics(action) {
  try {
    const response = yield APIs.getAgentDashboardMetrics(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_DASHBOARD_METRICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_DASHBOARD_METRICS_FAILURE,
      payload: error,
    });
  }
}

function* getExecAgentdashboardCoachingDetails(action) {
  try {
    const response = yield APIs.getExecAgentdashboardCoachingDetails(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_COACHING_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_COACHING_DETAILS_FAILURE,
      payload: error,
    });
  }
}

function* getexecagentactivityhistoryview(action) {
  try {
    const response = yield APIs.getexecagentactivityhistoryview(action.payload);
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_ACTIVITY_HISTORY_VIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_EXEC_AGENT_ACTIVITY_HISTORY_VIEW_FAILURE,
      payload: error,
    });
  }
}

function* taskManagementModule(action) {
  try {
    const response = yield APIs.taskManagementModule(action.payload);
    yield put({
      type: ActionTypes.TASK_MANAGEMENT_MODULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.TASK_MANAGEMENT_MODULE_FAILURE,
      payload: error,
    });
  }
}

function* getqudarantthreecardsExecAgent(action) {
  try {
    const response = yield APIs.getqudarantthreecardsExecAgent(action.payload);
    yield put({
      type: ActionTypes.GET_QUDARANT_THREE_CARDS_EXEC_AGENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_QUDARANT_THREE_CARDS_EXEC_AGENT_FAILURE,
      payload: error,
    });
  }
}

function* getAgentCoachHistoryV3byactivityid(action) {
  try {
    const response = yield APIs.getAgentCoachHistoryV3byactivityid(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_AGENT_COACH_HISTORY_V3_BY_ACTIVITYID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_COACH_HISTORY_V3_BY_ACTIVITYID_FAILURE,
      payload: error,
    });
  }
}

function* getmultimonthscorecarddimworkweeks(action) {
  try {
    const response = yield APIs.getmultimonthscorecarddimworkweeks(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORECARD_DIM_WORK_WEEKS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORECARD_DIM_WORK_WEEKS_FAILURE,
      payload: error,
    });
  }
}

function* getmultimonthScoreCardPopup(action) {
  try {
    const response = yield APIs.getmultimonthScoreCardPopup(action.payload);
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getmultimonthscorecardemployeedetails(action) {
  try {
    const response = yield APIs.getmultimonthscorecardemployeedetails(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_EMPLOYEE_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_EMPLOYEE_DETAILS_FAILURE,
      payload: error,
    });
  }
}

function* getGameByCompanyGuidOrgProgramEmpid(action) {
  try {
    const response = yield APIs.getGameByCompanyGuidOrgProgramEmpid(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_GAME_BY_COMPANY_GUID_ORG_PROGRAM_EMP_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_GAME_BY_COMPANY_GUID_ORG_PROGRAM_EMP_ID_FAILURE,
      payload: error,
    });
  }
}

function* getMetricCalculationsMetricDetailPopup(action) {
  try {
    const response = yield APIs.getMetricCalculationsMetricDetailPopup(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_METRIC_CALCULATIONS_METRIC_DETAIL_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_METRIC_CALCULATIONS_METRIC_DETAIL_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getActivityHistory(action) {
  try {
    const response = yield APIs.getActivityHistory(action.payload);
    yield put({
      type: ActionTypes.GET_ACTIVITY_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ACTIVITY_HISTORY_FAILURE,
      payload: error,
    });
  }
}

function* getTaskSaveListForLastHistory(action) {
  try {
    const response = yield APIs.getTaskSaveListForLastHistory(action.payload);
    yield put({
      type: ActionTypes.GET_TASK_SAVE_LIST_FOR_LAST_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_TASK_SAVE_LIST_FOR_LAST_HISTORY_FAILURE,
      payload: error,
    });
  }
}

function* leaderBoardAPI(action) {
  try {
    const response = yield APIs.leaderBoardAPI(action.payload);
    yield put({
      type: ActionTypes.LEADER_BOARD_API_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.LEADER_BOARD_API_FAILURE,
      payload: error,
    });
  }
}

function* taskHistory(action) {
  try {
    const response = yield APIs.taskHistory(action.payload);
    yield put({
      type: ActionTypes.TASK_HISTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.TASK_HISTORY_FAILURE,
      payload: error,
    });
  }
}

function* getMultiMonthScoreCardPermission(action) {
  try {
    const response = yield APIs.getMultiMonthScoreCardPermission(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_PERMISSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_PERMISSION_FAILURE,
      payload: error,
    });
  }
}

function* getAgentScheduling(action) {
  try {
    const response = yield APIs.getAgentScheduling(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_SCHEDULING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_SCHEDULING_FAILURE,
      payload: error,
    });
  }
}

function* getAgentInsentiveTables(action) {
  try {
    const response = yield APIs.getAgentInsentiveTables(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_INSENTIVE_TABLES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_INSENTIVE_TABLES_FAILURE,
      payload: error,
    });
  }
}

function* getIncentiveCardsExportAndPopUp(action) {
  try {
    const response = yield APIs.getIncentiveCardsExportAndPopUp(action.payload);
    yield put({
      type: ActionTypes.GET_INCENTIVE_CARDS_EXPORT_AND_POPUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_INCENTIVE_CARDS_EXPORT_AND_POPUP_FAILURE,
      payload: error,
    });
  }
}

function* getCxPBEstimatedPayout(action) {
  try {
    const response = yield APIs.getCxPBEstimatedPayout(action.payload);
    yield put({
      type: ActionTypes.GET_CXPB_ESTIMATED_PAYOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_CXPB_ESTIMATED_PAYOUT_FAILURE,
      payload: error,
    });
  }
}

function* getS2SEstimatedPayout(action) {
  try {
    const response = yield APIs.getS2SEstimatedPayout(action.payload);
    yield put({
      type: ActionTypes.GET_S2S_ESTIMATED_PAYOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_S2S_ESTIMATED_PAYOUT_FAILURE,
      payload: error,
    });
  }
}

function* getCompayConfigurationValues(action) {
  try {
    const response = yield APIs.getCompayConfigurationValues(action.payload);
    yield put({
      type: ActionTypes.GET_COMPAY_CONFIGURATION_VALUES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_COMPAY_CONFIGURATION_VALUES_FAILURE,
      payload: error,
    });
  }
}

function* cxPBCalculatePotentialPayout(action) {
  try {
    const response = yield APIs.cxPBCalculatePotentialPayout(action.payload);
    yield put({
      type: ActionTypes.CXPB_CALCULATE_POTENTIAL_PAYOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.CXPB_CALCULATE_POTENTIAL_PAYOUT_FAILURE,
      payload: error,
    });
  }
}

function* getMTDMyLevelupTileDetails_YTD(action) {
  try {
    const response = yield APIs.getMTDMyLevelupTileDetails_YTD(action.payload);
    yield put({
      type: ActionTypes.GET_MTD_MY_LEVEL_UP_TILE_DETAILS_YTD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_MTD_MY_LEVEL_UP_TILE_DETAILS_YTD_FAILURE,
      payload: error,
    });
  }
}

function* getAgentAIMLConfigurations(action) {
  try {
    const response = yield APIs.getAgentAIMLConfigurations(action.payload);
    yield put({
      type: ActionTypes.GET_AGENT_AIML_CONFIGURATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AGENT_AIML_CONFIGURATIONS_FAILURE,
      payload: error,
    });
  }
}

function* getAIMLMessages(action) {
  try {
    const response = yield APIs.getAIMLMessages(action.payload);
    yield put({
      type: ActionTypes.GET_AIML_MESSAGES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_AIML_MESSAGES_FAILURE,
      payload: error,
    });
  }
}

function* getAdditionalLinksFlagMetricDetail(action) {
  try {
    const response = yield APIs.getAdditionalLinksFlagMetricDetail(
      action.payload
    );
    yield put({
      type: ActionTypes.GET_ADDITIONAL_LINKS_FLAG_METRIC_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_ADDITIONAL_LINKS_FLAG_METRIC_DETAIL_FAILURE,
      payload: error,
    });
  }
}

function* getIntradayAgentDashboardTicker(action) {
  try {
    const response = yield APIs.getIntradayAgentDashboardTicker(action.payload);
    yield put({
      type: ActionTypes.GET_INTRA_DAY_AGENT_DASHBOARD_TICKER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_INTRA_DAY_AGENT_DASHBOARD_TICKER_FAILURE,
      payload: error,
    });
  }
}

function* getCxPBRankingDetails(action) {
  try {
    const response = yield APIs.getCxPBRankingDetails(action.payload);
    yield put({
      type: ActionTypes.GET_CXPB_RANKING_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_CXPB_RANKING_DETAILS_FAILURE,
      payload: error,
    });
  }
}

function* postDataSaga(action) {
  try {
    const response = yield call(
      axios.post,
      "your_api_endpoint",
      action.payload
    );
    yield put({
      type: ActionTypes.POST_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.POST_DATA_FAILURE,
      payload: error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(
      ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_REQUEST,
      getAgentDashboardOrgPrograms
    ),
    yield takeLatest(
      ActionTypes.GET_DIM_WORK_WEEKS_ALL_REQUEST,
      getDimWorkWeeksAll
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_TIME_FRAME_REQUEST,
      getAgentDashboardOrgProgramsTimeFrame
    ),
    yield takeLatest(ActionTypes.GET_AGENT_DETAILS_REQUEST, getAgentDetails),
    yield takeLatest(ActionTypes.GET_USER_META_DATA_REQUEST, getUserMetaData),
    yield takeLatest(
      ActionTypes.GET_AGENT_COACH_HISTORY_REQUEST,
      getAgentCoachHistory
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_COACH_HISTORY_BY_ID_REQUEST,
      getAgentCoachHistoryById
    ),
    yield takeLatest(
      ActionTypes.AGENT_DB_RECOGNIZATION_DETAILS_REQUEST,
      agentdbRecognizationDetails
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_GAME_TABLE_REQUEST,
      getAgentGameTable
    ),
    yield takeLatest(
      ActionTypes.GET_ACTIVITY_DASHBOARD_REQUEST,
      getActivityDashboard
    ),
    yield takeLatest(
      ActionTypes.GET_EXEC_AGENT_DASHBOARD_METRICS_REQUEST,
      getExecAgentDashboardMetrics
    ),
    yield takeLatest(
      ActionTypes.GET_EXEC_AGENT_BALANCE_SCORE_CARD_POPUP_REQUEST,
      getExecAGentBalanceScoreCardPopup
    ),
    yield takeLatest(
      ActionTypes.GET_DASHBOARD_CONFIGURATION_VALUES_REQUEST,
      getdashboardconfigurationValues
    ),
    yield takeLatest(
      ActionTypes.LATEST_COMPLETED_RECORD_REQUEST,
      latestcompletedrecord
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_CURRENT_COMMITMENTS_REQUEST,
      getAgentCurrentCommitments
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_BEST_PRACTICES_REQUEST,
      getAgentBestPractices
    ),
    yield takeLatest(
      ActionTypes.GET_COMPANY_ORG_PROGRAM_CONFIGURATIONS_REQUEST,
      getCompanyOrgProgramConfigurations
    ),
    yield takeLatest(
      ActionTypes.GET_METRICDETAILSPOPUP_LISTVIEW_REQUEST,
      getMetricDetailsPopupListView
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_METRIC_DETAILS_POPUP_REQUEST,
      getAgentMetricDetailsPopup
    ),
    yield takeLatest(
      ActionTypes.GET_COACHINGS_METRIC_DETAIL_POPUP_REQUEST,
      getCoachingsMetricDetailPopup
    ),
    yield takeLatest(
      ActionTypes.GET_METRIC_DETAILS_POPUP_REQUEST,
      getMetricDetailsPopup
    ),
    yield takeLatest(
      ActionTypes.GET_METRIC_DETAILS_POPUP_TREND_CHART_REQUEST,
      getMetricDetailsPopupTrendChart
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_DASHBOARD_METRICS_REQUEST,
      getAgentDashboardMetrics
    ),
    yield takeLatest(
      ActionTypes.GET_EXEC_AGENT_DASHBOARD_COACHING_DETAILS_REQUEST,
      getExecAgentdashboardCoachingDetails
    ),
    yield takeLatest(
      ActionTypes.GET_EXEC_AGENT_ACTIVITY_HISTORY_VIEW_REQUEST,
      getexecagentactivityhistoryview
    ),
    yield takeLatest(
      ActionTypes.TASK_MANAGEMENT_MODULE_REQUEST,
      taskManagementModule
    ),
    yield takeLatest(
      ActionTypes.GET_QUDARANT_THREE_CARDS_EXEC_AGENT_REQUEST,
      getqudarantthreecardsExecAgent
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_COACH_HISTORY_V3_BY_ACTIVITYID_REQUEST,
      getAgentCoachHistoryV3byactivityid
    ),
    yield takeLatest(
      ActionTypes.GET_MULTI_MONTH_SCORECARD_DIM_WORK_WEEKS_REQUEST,
      getmultimonthscorecarddimworkweeks
    ),
    yield takeLatest(
      ActionTypes.GET_MULTI_MONTH_SCORE_CARD_POPUP_REQUEST,
      getmultimonthScoreCardPopup
    ),
    yield takeLatest(
      ActionTypes.GET_MULTI_MONTH_SCORE_CARD_EMPLOYEE_DETAILS_REQUEST,
      getmultimonthscorecardemployeedetails
    ),
    yield takeLatest(
      ActionTypes.GET_GAME_BY_COMPANY_GUID_ORG_PROGRAM_EMP_ID_REQUEST,
      getGameByCompanyGuidOrgProgramEmpid
    ),
    yield takeLatest(
      ActionTypes.GET_METRIC_CALCULATIONS_METRIC_DETAIL_POPUP_REQUEST,
      getMetricCalculationsMetricDetailPopup
    ),
    yield takeLatest(
      ActionTypes.GET_ACTIVITY_HISTORY_REQUEST,
      getActivityHistory
    ),
    yield takeLatest(
      ActionTypes.GET_TASK_SAVE_LIST_FOR_LAST_HISTORY_REQUEST,
      getTaskSaveListForLastHistory
    ),
    yield takeLatest(ActionTypes.LEADER_BOARD_API_REQUEST, leaderBoardAPI),
    yield takeLatest(ActionTypes.TASK_HISTORY_REQUEST, taskHistory),
    yield takeLatest(
      ActionTypes.GET_MULTI_MONTH_SCORE_CARD_PERMISSION_REQUEST,
      getMultiMonthScoreCardPermission
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_SCHEDULING_REQUEST,
      getAgentScheduling
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_INSENTIVE_TABLES_REQUEST,
      getAgentInsentiveTables
    ),
    yield takeLatest(
      ActionTypes.GET_INCENTIVE_CARDS_EXPORT_AND_POPUP_REQUEST,
      getIncentiveCardsExportAndPopUp
    ),
    yield takeLatest(
      ActionTypes.GET_CXPB_ESTIMATED_PAYOUT_REQUEST,
      getCxPBEstimatedPayout
    ),
    yield takeLatest(
      ActionTypes.GET_S2S_ESTIMATED_PAYOUT_REQUEST,
      getS2SEstimatedPayout
    ),
    yield takeLatest(
      ActionTypes.GET_COMPAY_CONFIGURATION_VALUES_REQUEST,
      getCompayConfigurationValues
    ),
    yield takeLatest(
      ActionTypes.CXPB_CALCULATE_POTENTIAL_PAYOUT_REQUEST,
      cxPBCalculatePotentialPayout
    ),
    yield takeLatest(
      ActionTypes.GET_MTD_MY_LEVEL_UP_TILE_DETAILS_YTD_REQUEST,
      getMTDMyLevelupTileDetails_YTD
    ),
    yield takeLatest(
      ActionTypes.GET_AGENT_AIML_CONFIGURATIONS_REQUEST,
      getAgentAIMLConfigurations
    ),
    yield takeLatest(ActionTypes.GET_AIML_MESSAGES_REQUEST, getAIMLMessages),
    yield takeLatest(
      ActionTypes.GET_ADDITIONAL_LINKS_FLAG_METRIC_DETAIL_REQUEST,
      getAdditionalLinksFlagMetricDetail
    ),
    yield takeLatest(
      ActionTypes.GET_INTRA_DAY_AGENT_DASHBOARD_TICKER_REQUEST,
      getIntradayAgentDashboardTicker
    ),
    yield takeLatest(
      ActionTypes.GET_CXPB_RANKING_DETAILS_REQUEST,
      getCxPBRankingDetails
    ),
    yield takeLatest(ActionTypes.POST_DATA_REQUEST, postDataSaga),
  ]);
}
