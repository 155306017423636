const CompanyGUIDs = {
  Acqueon: "4E4D465D-B5E2-450A-A108-460866C8FA5E",
  "Allied Global": "0A1F947E-9047-4753-8148-CE8C51888C19",
  Alorica: "64932101-78C3-49F0-AC5A-9ADBCB7D3ED1",
  "Arise Virtual Solutions Inc.": "D2676968-C552-4319-A0F9-22ACF007E441",
  "Baylor Scott & White": "23BA978B-CD86-4036-9DBE-377E19A7AF76",
  Centurylink: "5296211B-24E7-44BC-AF7D-D56C7BA6F351",
  Chewy: "E6A45C48-CD30-427D-B620-8E14EC21C5B8",
  Cognizant: "696AB4BF-51DC-44BC-B44B-BEEFF416EC79",
  Connexus: "88D9D6E8-8E18-4843-B260-50352065BF6C",
  "Cox Communications": "EA8CD862-A156-4933-8E7A-99C8006D657F",
  Discover: "EC7C39D0-7157-4EEC-B05B-EA723D0216D4",
  FirstSource: "9455DCE0-3D53-4E94-8A64-5DE90F8713A9",
  "Go Daddy": "51841AF1-EA61-4091-9106-C461ED5293A8",
  HGS: "95EFCAE2-E364-4B8C-8919-26DDEE3A432B",
  "HGS - CES": "FD18D467-E040-493C-9D29-B217D7034D0F",
  Inspiro: "47B2938C-2213-40D1-AE82-647FF94C2C83",
  iQor: "1740DFCD-EFB4-4F97-BF9A-229E737C53C3",
  "Iterum Connections": "E15DDFB3-9D20-42A8-B66B-88E0C260E182",
  "Metro by T-Mobile": "C184CA55-7D40-4CAC-A62C-34CE2801A6BC",
  Movate: "902C2D60-F9F2-442B-83AE-020BF7E49DBA",
  Navient: "11E83259-E303-41BF-B9D3-89454D019B60",
  Nearsol: "3DC4E355-7B44-411E-A23F-88CC5CB8965F",
  Numeris: "72A81DA2-2ED8-40B4-BFCC-F602171C1C38",
  Omnicare365: "8B5285E6-AB35-43B9-AB86-53A594F750B8",
  Opendoor: "8148A3EB-063A-42BC-9C89-BBB4B5C128DA",
  Outworx: "A663C4F8-7177-4CB6-A9AD-3D30DC3E286C",
  "Premier Bank": "905F6761-FF6C-4731-80F3-811652FBAE20",
  "Royal Caribbean": "4E5C4C79-9F74-46DC-9D59-A81F2EE0B5FA",
  "Sonic Automotive Inc": "DC2F48F3-B829-4915-821E-D9DF3803F4BA",
  SSG: "94EA9A3D-74B8-499B-B8D4-7C80334BDA13",
  "SSM Health": "30111817-A4EB-46FA-9235-270F0342CCE5",
  "Sun Country Airlines": "639DC6CB-C255-482B-9584-F07E533DDDA7",
  Teleperformance: "CB0A635E-E09C-47FA-AEF6-581173FAC0FE",
  "Telus International": "C412D0CC-19A9-4123-B515-DF0087DEFFA7",
  THDBPO: "76E93134-B348-4062-862C-35E0B13574A6",
  "The Home Depot": "AFE20497-F14C-49B5-B6F5-E4D282708861",
  Thrasio: "D0BC5C73-5C9B-4442-B63C-BD8FE59DB4E3",
  Transcosmos: "F2E2DA7D-05D9-4285-8BAA-6936575E942A",
  TTEC: "0E925029-77F5-483D-A1EF-008CB0133FDE",
  "UM Health": "B276B3E0-ACD3-4759-981D-7D7A36858F18",
  Universal: "3D896A9C-7E50-4953-A0EF-5586BBD8E58E",
  "Voice Team": "0DDE4CD9-57B4-4274-94C9-7E4F4E55A60F",
  "Woodforest National Bank": "1934BF18-5E0C-4250-9203-8CF84A5994BA",
};
Object.freeze(CompanyGUIDs);
export const currentCompanyGuid = sessionStorage.getItem("currentCompany");
// sessionStorage.removeItem("currentCompany");
export default CompanyGUIDs;
