import React, { useState, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import _expand from "../../assets/images/expand-icon.svg";
import _collapse from "../../assets/images/collapse-icon.svg";
import CustomTooltip from "../../customComponents/customTooltip";
import CustomDataTableBase from "../../customComponents/customTable";
import ReactDataTables from "../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { CoachingDetailsModal, AudioPlayModal } from "../modals";
import { isNullOrEmpty } from "../../utils";
import { DataTableLoader } from "../../utils/Loader";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";

const CoachingHistory = () => {
  const [toggleHistory, setToggleHistory] = useState(false);
  const [isCoachingDetails, setCoachingDetails] = useState(false);
  const [isAudioPlayer, setAudioPlayer] = useState(false);
  const [clickedRow, setClickedRow] = useState({});

  const { coachHistoryInfo, activeOrganizationInfo, isFetching } = useSelector(
    ({
      get_agent_coach_history: { data: coachHistoryData, isFetching },
      get_active_organization_detail: { data: activeOrganizationData },
    }) => {
      return {
        coachHistoryInfo: coachHistoryData || [],
        activeOrganizationInfo: activeOrganizationData || {},
        isFetching: isFetching,
      };
    },
    shallowEqual
  );
  const toggleCoachingDetailsModal = (rowItem) => {
    setClickedRow(rowItem);
    setCoachingDetails((prev) => !prev);
  };
  const toggleAudioPlayModal = (rowItem) => {
    setClickedRow(rowItem);
    setAudioPlayer((prev) => !prev);
  };
  const isAriseVirtualSolutionsInc =
    currentCompanyGuid === CompanyGUIDs["Arise Virtual Solutions Inc."];
  const iDate =
    currentCompanyGuid == CompanyGUIDs["Arise Virtual Solutions Inc."] ||
    currentCompanyGuid == CompanyGUIDs["Metro by T-Mobile"] ||
    currentCompanyGuid == CompanyGUIDs["The Home Depot"] ||
    currentCompanyGuid == CompanyGUIDs["Sonic Automotive Inc"] ||
    currentCompanyGuid == CompanyGUIDs["Movate"];

  const columns = useMemo(
    () => [
      {
        title: `<img className="coaching_history_image col-header-img" src="images/thumbs_up.svg" />`,
        data: "ack",
        sortable: false,
        render: (data, type, row) => {
          return data
            ? '<img className="coaching_history_image" src="images/thumbs_up.svg" />'
            : "";
        },
      },
      {
        title: "ID",
        data: "ncoachingid",
        render: function (data, type, row) {
          return `<span class="row-click">${data}</span>`;
        },
        onClick: (rowData, colIndex) =>
          toggleCoachingDetailsModal(rowData, colIndex),
      },
      {
        title: `<img className="audio_img col-header-img" src="images/audio.svg" />`,
        data: "recordpath",
        sortable: false,
        render: (data, type, row) => {
          return data?.includes(".mp3") || data?.includes(".wav")
            ? `<img className="audio_img" src="images/audio.svg" style="cursor: pointer;"  />`
            : "";
        },
        onClick: (rowData, colIndex) =>
          rowData.recordpath?.includes(".mp3") ||
          rowData.recordpath?.includes(".wav")
            ? toggleAudioPlayModal(rowData, colIndex)
            : null,
      },
      {
        title: "Date",
        data: "coacheddate",
        render: (data, type, row) => {
          return `<span class="d-none">${FormatDateFormat(
            data,
            "YYYYMMDD"
          )}</span> ${FormatDate(data)}`;
        },
      },
      {
        title: isAriseVirtualSolutionsInc ? "Enhanced By" : "Coached By",
        data: "coachedby",
      },
      {
        title: "Metric",
        data: "metricname",
      },
      {
        title: isAriseVirtualSolutionsInc
          ? "Enhancement Opportunity"
          : "Behavior",
        data: "behavior",
      },
      {
        title: isAriseVirtualSolutionsInc
          ? " Enhancement Behavior"
          : "Sub Behavior",
        data: "subBehavior",
      },
      {
        title: "Actual",
        data: "actual",
      },
      {
        title: "Commitment",
        data: "commitment",
      },
      {
        title:
          iDate && activeOrganizationInfo?.Program == "phones overall"
            ? "Follow Up Date"
            : "Commit Date",
        data: "commitdate",
        render: (data, type, row) => {
          return `<span class="d-none">${FormatDateFormat(
            data,
            "YYYYMMDD"
          )}</span> ${data ? FormatDate(data) : ""}`;
        },
      },
      {
        title:
          iDate && activeOrganizationInfo?.Program == "phones overall"
            ? "ACTUAL ON FOLLOW UP DATE"
            : "Actual On Commit Date",

        data: "commitdateactual",
      },
    ],
    [activeOrganizationInfo, isAriseVirtualSolutionsInc, iDate]
  );
  const rowStyles = (rowData) => {
    if (rowData.color) {
      return "border-left", `5px solid ${rowData.color}`;
    } else {
      return "border-left", `5px solid`;
    }
  };
  const rowBorderColor = (rowData) => {
    if (rowData.color) {
      return rowData.color; // Green for high salary
    } else {
      return ""; // Red for low salary
    }
  };
  return (
    <div className="coaching_history">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-start align-center">
              <div className="expand_bg">
                <img
                  src={toggleHistory ? _collapse : _expand}
                  className={toggleHistory ? "clpse" : "expd"}
                  alt={toggleHistory ? "Collapse" : "Expand"}
                />
              </div>
              <div>
                <a
                  className="view_all cei coach_his"
                  onClick={() => setToggleHistory((prev) => !prev)}
                >
                  Coaching History
                </a>
              </div>
            </div>
            {toggleHistory && (
              <div className="" id="coaching_history">
                <div className="row">
                  <div className="col-md-12">
                    <div className="coach_comparision1">
                      <div className="d-flex flex-start align-center">
                        <div className="flex justify-content-between">
                          <h4>Coaching History</h4>
                        </div>
                        <CustomTooltip
                          tooltipText="?"
                          tooltipDesc="List of all the coaching sessions conducted for
                              you and its details"
                        />
                      </div>
                      <div
                        className="agent_coaching_history"
                        id="coachingHistory_wrapper"
                      >
                        {isFetching ? (
                          <div className="text-center">
                            <DataTableLoader />
                          </div>
                        ) : (
                          <ReactDataTables
                            data={coachHistoryInfo}
                            columns={columns}
                            defaultSort={{ columnIndex: 1, direction: "desc" }}
                            // onRowClick={toggleCoachingDetailsModal}
                            rowStyles={rowStyles}
                            rowBorderColor={rowBorderColor}
                            className="px-3"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isCoachingDetails && (
          <CoachingDetailsModal
            clickedRow={clickedRow}
            showModal={isCoachingDetails}
            toggleModal={toggleCoachingDetailsModal}
          />
        )}
        {isAudioPlayer && (
          <AudioPlayModal
            clickedRow={clickedRow}
            showModal={isAudioPlayer}
            toggleModal={toggleAudioPlayModal}
          />
        )}
      </div>
    </div>
  );
};

export default CoachingHistory;
