import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CustomTooltip from "../../customComponents/customTooltip";
import CustomDataTableBase from "../../customComponents/customTable";
import ReactDataTables from "../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { CoachingDetailsModal } from "../modals";
import { DataTableLoader } from "../../utils/Loader";
const navigationActionCSS = {
  color: "#0e7e97",
  fontWeight: "600",
  cursor: "pointer",
  pointerEvents: "auto",
  textDecoration: "none",
};
const Recognition = (props) => {
  const [recognizationList, setRecognizationList] = useState([]);
  const [clickedRow, setClickedRow] = useState({});
  const [isCoachingDetails, setCoachingDetails] = useState(false);

  const { recognizationInfo, isFetching } = useSelector(
    ({
      agent_db_recognization_details: { data: recognizationData, isFetching },
    }) => {
      return {
        recognizationInfo: recognizationData || [],
        isFetching: isFetching,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (recognizationInfo?.length > 0) {
      setRecognizationList(recognizationInfo);
    }
  }, [recognizationInfo]);

  const toggleCoachingDetailsModal = useCallback((rowItem) => {
    setClickedRow(rowItem);
    setCoachingDetails((prev) => !prev);
  }, []);

  const columns = [
    {
      title: "RECOGNIZED BY	",
      data: "recognizedby",
      sortable: false,
      width: "25%",
    },
    {
      title: "REASON",
      data: "reason",
      sortable: false,
      width: "55%",
      render: function (data, type, row) {
        return data ? `<a className="table-navigation-action">${data}</a>` : "";
      },
      onClick: (rowData, colIndex) =>
        rowData.tasktype.toLowerCase() === "recognition"
          ? toggleCoachingDetailsModal(rowData, colIndex)
          : toggleCoachingDetailsModal(rowData, colIndex),
    },
    {
      title: "DATE",
      data: "createddate",
      type: "custom-date",
      sortable: false,
      width: "20%",
      render: (data, type, row) => {
        return `<span class="d-none">${FormatDateFormat(
          data,
          "YYYYMMDD"
        )}</span> ${FormatDate(data)}`;
      },
    },
  ];

  return (
    <div className="col-md-12">
      <div className="collapse show" id="recognition_badges_collapse">
        <div className="card">
          <div className="card-header">
            <div className="d-flex space-between align-center">
              <div className="badge_card_header">
                <div>Recognition</div>
                <CustomTooltip
                  tooltipText="?"
                  tooltipDesc="List of all the recognitions you have received"
                />
              </div>
              <div className="btn-badge-close">
                <button
                  className="btn btn-bordered"
                  onClick={() => {
                    props.toggleBadge("recognition_badges_collapse");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="badge_table">
              {isFetching ? (
                <div className="text-center">
                  <DataTableLoader />
                </div>
              ) : (
                <ReactDataTables
                  columns={columns}
                  data={recognizationList}
                  defaultSort={{ columnIndex: 2, direction: "desc" }}
                  autoWidth={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isCoachingDetails && (
        <CoachingDetailsModal
          clickedRow={clickedRow}
          showModal={isCoachingDetails}
          toggleModal={toggleCoachingDetailsModal}
        />
      )}
    </div>
  );
};

export default Recognition;
