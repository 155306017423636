import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTooltip from "../../../customComponents/customTooltip";
import { isNullOrEmpty } from "../../../utils";
import { FormatDate } from "../../../utils/dateFormat";
import { ParseHtml } from "../../../utils/parseHtml";
import { FormLoader } from "../../../utils/Loader";

const CoachingScreen = ({
  toggleModal,
  activeOrganizationInfo,
  activityHistoryInfo,
  isFetching,
}) => {
  const closeBtn = (
    <div className="links_right w-45">
      {activityHistoryInfo &&
        !isNullOrEmpty(activityHistoryInfo?.audiopath) && (
          <div className="coaching_audio">
            <audio
              controls
              controlsList="nodownload"
              style={{
                fontSize: "10px",
              }}
            >
              <source src={activityHistoryInfo?.audiopath} type="audio/mp3" />
            </audio>
          </div>
        )}
      <button className="modal_close" onClick={toggleModal} type="button">
        <img title="Attached File" src="images/close.svg" />
      </button>
    </div>
  );
  const {
    bublecolor,
    mmcfConfigValues,
    getMMCFAgentCoachingHistoryV4,
    microLearningCoachingForm,
    ackConfigList,
    canacknowledgecoaching,
    ismmcfcoaching,
  } = activityHistoryInfo || {};
  let isL1_Agent =
    activeOrganizationInfo?.titleroletype?.toLowerCase() == "l1" ||
    activeOrganizationInfo?.titleroletype?.toLowerCase() == "agent";

  const filterItems = (condition) => {
    return (
      getMMCFAgentCoachingHistoryV4?.filter((item) => {
        return condition(item);
      }) ?? []
    );
  };

  const filteredByActRichUrlMetric = filterItems((item) => {
    return (
      item.lableName !== "Ack" &&
      item.inputType !== "RichText" &&
      item.inputType !== "Url" &&
      item.lableType !== "MetricRichText" &&
      item.lableType !== "ProgramNotesPlainText"
    );
  });

  const filteredByMetric = filterItems((item) => {
    return item.lableType === "MetricRichText";
  });

  const filteredByUrl = filterItems((item) => {
    return item.inputType === "Url";
  });

  const filteredByRichTextMetric = filterItems((item) => {
    return (
      (item.inputType === "RichText" && item.lableType !== "MetricRichText") ||
      (item.InputType === "Text" && item.lableType === "ProgramNotesPlainText")
    );
  });

  const filteredByAck = filterItems((item) => {
    return item.lableName === "Ack" && item.lableValue != null;
  });
  return (
    <div>
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="d-flex flex-start align-center">
          <div className="title">Coaching Details</div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        {isFetching ? (
          <div className="text-center">
            <FormLoader width={800} />
          </div>
        ) : (
          <div className="row">
            {bublecolor?.toLowerCase() == "red" && (
              <div className="col-md-12">
                <div className="trending_below_commitment d-flex space-between align-center">
                  <div className="d-flex flex-start align-center">
                    <div className="trending">
                      <img src="images/icon_outline.svg" />
                    </div>
                    <div className="red"> Trending below Commitment</div>
                    <div>Review Coaching Details</div>
                  </div>
                </div>
              </div>
            )}
            {getMMCFAgentCoachingHistoryV4?.length > 0 && (
              <>
                <div className="coached_fields">
                  <div className="d-flex flex-start align-start">
                    {filteredByActRichUrlMetric?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            {item.InputType?.toLowerCase() === "date" &&
                            item.lableType !== "Ack date" ? (
                              <div className="form-control">
                                {FormatDate(item.lableValue)}
                              </div>
                            ) : (
                              <div className="form-control">
                                {ParseHtml(item.lableValue)}
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          {item.InputType?.toLowerCase() === "date" &&
                          item.lableType !== "Ack date" ? (
                            <div className="form-control">
                              {FormatDate(item.lableValue)}
                            </div>
                          ) : (
                            <div className="form-control">
                              {ParseHtml(item.lableValue)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {filteredByMetric?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            <div className="form-control">
                              {ParseHtml(item.lableValue)}
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            {ParseHtml(item.lableValue)}
                          </div>
                        </div>
                      );
                    })}
                    {filteredByUrl?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            <div className="form-control">
                              {ParseHtml(item.lableValue)}
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            {ParseHtml(item.lableValue)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="reviews_learning">
                  <h6>Reviewed Learning</h6>
                  <div>
                    {microLearningCoachingForm?.map((item, index) => {
                      return (
                        <b key={index}>
                          <a href={item.videopath} target="_blank">
                            {item.skillname}
                          </a>
                        </b>
                      );
                    })}
                  </div>
                </div>
                <div className="notes">
                  <h3>Notes</h3>
                </div>
                <div className="notes_fields">
                  {filteredByRichTextMetric?.map((item) => {
                    return isL1_Agent ? (
                      item.showdetailsonagentlevel === true && (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            {ParseHtml(item.lableValue)}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="form-group">
                        <label className="lbl">{item.lableName}</label>
                        <div className="form-control">
                          {ParseHtml(item.lableValue)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {filteredByAck?.length > 0 && (
                  <div className="afni_check">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="afni_radiobtn"
                          checked
                          disabled
                        />
                        <label className="form-check-label" for="afni_radiobtn">
                          {ackConfigList?.map(
                            (item) => item.configurationValue
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </ModalBody>
    </div>
  );
};

export default CoachingScreen;
