import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const MetricDetailTrendChart = ({ metricData, char_hei, activeMetric }) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (metricData && metricData.length !== 0) {
      const categories = [];
      const data = [];
      const actualData = [];
      metricData.forEach((item) => {
        categories.push(item.displayname);
        data.push(parseFloat(item.metricDetailNameValue));
        actualData.push(item.metricDetailNameActualValue);
      });
      setChartOptions({
        xAxis: {
          categories: categories,
          startOnTick: true,
          endOnTick: true,
          gridLineWidth: 0,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },
        legend: {
          align: "right",
          x: 0,
          verticalAlign: "top",
          y: -15,
          floating: false,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.background2) || "white",
          borderColor: "white",
          borderWidth: 1,
          shadow: false,
        },
        chart: {
          type: "line",
          height: char_hei,
        },
        title: {
          text: "",
        },

        series: [
          {
            name: activeMetric,
            color: "black",
            data: data,
            zIndex: 2,
          },
        ],
        credits: {
          enabled: false,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        legend: {
          enabled: true,
        },
        exportButton: {
          enabled: false,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                if (this.series.name == "Goal") {
                  return actualData[categories.indexOf(this.x)];
                } else {
                  return actualData[categories.indexOf(this.x)];
                }
              },
              color: "black",
              style: {
                fontWeight: "normal",
              },
            },
            enableMouseTracking: true,
          },
        },
      });
    }
  }, [metricData, char_hei, activeMetric]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default MetricDetailTrendChart;
