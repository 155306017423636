import { createReducer, createSelector } from "@reduxjs/toolkit";
import ActionTypes, { keys } from "../../reduxHelper";
import { generateAsyncHandlers, defaults } from "../handler";

export const initialState = {
  get_active_organization_detail: defaults,
  ...keys.reduce((state, key) => {
    state[key.toLowerCase()] = defaults;
    return state;
  }, {}),
};

const dataReducer = createReducer(initialState, (builder) => {
  builder.addCase(ActionTypes.SET_ORGANIZATION_DETAIL_SET, (state, action) => {
    state.get_active_organization_detail = {
      data: {
        ...state.get_active_organization_detail.data,
        ...action.payload,
      },
    };
  });
  keys.forEach((key) => {
    const stateKey = key.toLowerCase();
    // const actionTypePrefix = ActionTypes[key];
    if (key) {
      generateAsyncHandlers(builder, key, stateKey);
    }
  });
  builder.addDefaultCase((state, action) => state);
});

export default dataReducer;
