import KeyMirror from "keymirror";
import { put, call } from "redux-saga/effects";

const keyMapper = ["RESET", "REQUEST", "SUCCESS", "FAILURE", "SET"];
export const defaults = {
  isFetching: false,
  error: null,
  data: null,
};

export const generateAsyncHandlers = (builder, actionTypePrefix, stateKey) => {
  builder
    .addCase(`${actionTypePrefix}_REQUEST`, (state) => {
      state[stateKey] = { ...defaults, isFetching: true };
    })
    .addCase(`${actionTypePrefix}_SUCCESS`, (state, action) => {
      state[stateKey] = { ...defaults, data: action.payload };
    })
    .addCase(`${actionTypePrefix}_FAILURE`, (state, action) => {
      state[stateKey] = { ...defaults, error: action.payload };
    })
    .addCase(`${actionTypePrefix}_RESET`, (state) => {
      state[stateKey] = { ...defaults };
    });
};
function* apiCallHandler(
  apiFunc,
  action,
  successActionType,
  failureActionType
) {
  try {
    const response = yield call(apiFunc, action.payload);
    yield put({
      type: successActionType,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: failureActionType,
      payload: error,
    });
  }
}
function getActionTypes(keys) {
  const actions = keys.reduce((acc, key) => {
    keyMapper.forEach((mapper) => {
      acc[key + "_" + mapper] = null;
    });
    return acc;
  }, {});

  return KeyMirror(actions);
}
// const getActionTypes = () => {
//   let actions = {};
//   for (const key of keys) {
//     for (const mapper of keyMapper) {
//       actions[key + "_" + mapper] = undefined;
//     }
//   }
//   return keyMirror(actions);
// };
export { getActionTypes, apiCallHandler };
