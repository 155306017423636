import React from "react";
import { Nav, NavLink } from "reactstrap";

function TimeFrameNavTab({ toggleTab, activeTab, isMonthOnly }) {
  return (
    <Nav pills className="nav flex-column nav-pills" id="v-pillstimeframe-tab">
      <NavLink
        className={`nav-link ${
          activeTab === "v-pillstimeframe-monthly" && "active"
        }`}
        id="v-pillstimeframe-monthly-tab"
        onClick={() => toggleTab("v-pillstimeframe-monthly")}
      >
        Monthly
      </NavLink>
      {!isMonthOnly && (
        <>
          <NavLink
            className={`nav-link ${
              activeTab === "v-pillstimeframe-weekly" && "active"
            }`}
            id="v-pillstimeframe-weekly-tab"
            onClick={() => toggleTab("v-pillstimeframe-weekly")}
          >
            Weekly
          </NavLink>
          <NavLink
            className={`nav-link ${
              activeTab === "v-pillstimeframe-daily" && "active"
            }`}
            id="v-pillstimeframe-daily-tab"
            onClick={() => toggleTab("v-pillstimeframe-daily")}
          >
            Daily
          </NavLink>
        </>
      )}
    </Nav>
  );
}

export default TimeFrameNavTab;
