import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTooltip from "../../customComponents/customTooltip";
import { TrendChart, ViewDetailsFAQ } from "../partialScreens";
import { getExecAGentBalanceScoreCardPopup } from "../../store/actions";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";
import ReactDataTables from "../../customComponents/ReactDataTables";
import {
  BarChartLoader,
  RepeatableRowsLoader,
  DataTableLoader,
} from "../../utils/Loader";
import TimeFrame from "../../customComponents/timeFrame";
import SortableHeader from "../../utils/SortableHeader";
import { render } from "@testing-library/react";

const BalancedScorecard = ({
  showModal,
  toggleModal,
  toggleMultiMonthScoreModal,
  showMultiMonthScoreCard,
}) => {
  const dispatch = useDispatch();
  const rankTableRef = useRef(null);
  const scoreDetailsRef = useRef(null);
  const blockRef = useRef(null);
  const scoreHeaderRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [bsSortConfig, setBSSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [TableData, setData] = useState([]);
  const [BSTableData, setBSData] = useState([]);
  const {
    activeOrganizationInfo,
    agentBalanceScoreInfo,
    isFetching,
    workWeeksAllList,
  } = useSelector(
    ({
      get_active_organization_detail: { data: activeOrganizationData },
      get_dim_work_weeks_all: { data: workWeeksAllData },
      get_exec_agent_balance_score_card_popup: {
        data: agentBalanceScoreData,
        isFetching,
      },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        agentBalanceScoreInfo: agentBalanceScoreData,
        isFetching: isFetching,
        workWeeksAllList: workWeeksAllData,
      };
    },
    shallowEqual
  );
  const updateRankTableHeight = () => {
    if (
      scoreDetailsRef.current &&
      blockRef.current &&
      scoreHeaderRef.current &&
      rankTableRef.current
    ) {
      const rankTableHeight =
        scoreDetailsRef.current.offsetHeight +
        blockRef.current.offsetHeight -
        scoreHeaderRef.current.offsetHeight +
        18;
      rankTableRef.current.style.height = `${rankTableHeight}px`;
    }
  };
  const refreshModalData = (frameData, type) => {
    dispatch(
      getExecAGentBalanceScoreCardPopup({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        primaryprogram: activeOrganizationInfo?.Program,
        loginid: activeOrganizationInfo?.Loginid,
        accessloginid: activeOrganizationInfo?.Loginid,
        timeperiod: type || activeOrganizationInfo?.FrameData?.typeofrecord,
        recorddate:
          frameData?.transdate || activeOrganizationInfo?.FrameData?.transdate,
      })
    );
  };
  useEffect(() => {
    if (activeOrganizationInfo) {
      refreshModalData();
    }
  }, [dispatch, activeOrganizationInfo]);

  useEffect(() => {
    let timer;
    if (agentBalanceScoreInfo) {
      timer = setTimeout(() => {
        updateRankTableHeight();
      }, 100);
    }
    if (agentBalanceScoreInfo?.tableranks) {
      const tableranksData = [
        ...(agentBalanceScoreInfo?.tableranks || []),
      ].sort((a, b) => parseInt(a.balrank) - parseInt(b.balrank));
      setData(tableranksData);
    }
    if (agentBalanceScoreInfo?.tableDetails) {
      setBSData(agentBalanceScoreInfo?.tableDetails);
    }
    window.addEventListener("resize", updateRankTableHeight);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", updateRankTableHeight);
    };
  }, [agentBalanceScoreInfo]);
  const closeBtn = (
    <div className="d-flex">
      <div className="view_details">
        <ViewDetailsFAQ isButton={true} />
      </div>
      {showMultiMonthScoreCard && (
        <div className="view_details">
          <button
            className="btn btn-bordered"
            onClick={() => {
              toggleMultiMonthScoreModal();
              toggleModal();
            }}
          >
            Multi-Month Scorecard
          </button>
        </div>
      )}

      <button className="modal_close" onClick={toggleModal} type="button">
        <img src="images/close.svg" />
      </button>
    </div>
  );
  const { tableDetails, tablecharts } = agentBalanceScoreInfo || {};
  const rowBorderColor = (rowData) => {
    if (rowData.balancecolor) {
      return rowData.balancecolor;
    } else {
      return "";
    }
  };
  const coxScoreCardColumn = [
    {
      title: "Category",
      data: "category",
      sortable: false,
    },
    {
      title: "Metric",
      data: "column1",
      sortable: false,
    },
    {
      title: "Score",
      data: "score",
      sortable: false,
    },
    {
      title: "Rating",
      data: "rating",
      sortable: false,
      render: (data, type, row) => {
        const buttonStyle = `background-color: ${row.ratingbackground}; border-color: ${row.ratingbackground};`;
        return `<button
      class="btn btn-primary balance_color"
      style="${buttonStyle}"
    >
      ${data}
    </button>`;
      },
    },
    {
      title: "Weighting",
      data: "weights",
      sortable: false,
    },
    {
      title: "Goal",
      data: "goal",
      sortable: false,
    },
    {
      title: "Actual",
      data: "actual",
      sortable: false,
    },
  ];
  const scoreCardColumn = [
    {
      title: "Metric",
      data: "column1",
      sortable: false,
    },
    {
      title: "Cap Value",
      data: "capvalue",
      sortable: false,
    },
    {
      title: "Weighting",
      data: "weights",
      sortable: false,
    },
    {
      title: "Goal",
      data: "goal",
      sortable: false,
    },
    {
      title: "Actual",
      data: "actual",
      sortable: false,
    },
    {
      title: "% to Goal",
      data: "pertogoal",
      sortable: false,
    },
    {
      title: "Points Earned",
      data: "pointsearned",
    },
    {
      title: "Percentile Ranking",
      data: "percentileValue",
    },
  ];
  const rankingColumn = [
    {
      title: "User",
      data: "employeename",
      sortable: false,
      render: (data, type, row) => {
        const initials_empname = data
          .split(" ")
          .map((name) => name?.trimStart().substring(0, 1).toUpperCase())
          .join("")
          ?.substring(0, 2);
        return `<div class="rank_profile">
            <div class="d-flex flex-start align-items-center">
              <div class="score_rank">${row.balrank}</div>
              <div class="score_profile">
                <div class="initials_bs">${initials_empname}</div>
              </div>
              <div class="score_name">
                <div>${data}</div>
              </div>
            </div>
          </div>`;
      },
    },
    {
      title: "score",
      data: "balanceScore",
    },
    {
      title: "Percentile Ranking",
      data: "percentileValue",
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      //   className={className}
      backdrop={true}
      keyboard={true}
      id="balancescorecard_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal"
      >
        <div className="d-flex flex-start align-center">
          <div class="d-flex flex-start align-center">
            <span className="">Scorecard</span>
            <CustomTooltip
              tooltipText="?"
              tooltipDesc="View the details of your balanced scorecard here. It describes
                the metrics included, a trend chart and ranking."
            />
          </div>
          <div
            class="time_frame_block position-relative form-group w-100"
            style={{
              marginLeft: "50px",
              marginBottom: "0px",
            }}
          >
            <div className="form-group">
              <label className="position-absolute timeframe_lable">
                Time Frame
              </label>
              <TimeFrame
                workWeeksAllList={workWeeksAllList}
                getFrameData={(data) => {
                  refreshModalData(data?.frameData, data?.type);
                }}
                isMonthOnly={true}
              />
            </div>

            {/* <div class="position-absolute timeframe_lable">
              <div>Time Frame</div>
            </div> */}
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="balancescorecarddetails">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex space-between align-start">
              <div className="display-block">
                <div className="balance_scorecard_table" ref={scoreDetailsRef}>
                  <div className="card">
                    <div className="card-header" ref={scoreHeaderRef}>
                      <div>Balanced Scorecard</div>
                    </div>
                    <div
                      className="card-body custom-sort"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      {isFetching ? (
                        <div className="text-center">
                          <DataTableLoader />
                        </div>
                      ) : (
                        <>
                          <ReactDataTables
                            id="scorecard_details_table"
                            columns={
                              currentCompanyGuid ===
                              CompanyGUIDs["Cox Communications"]
                                ? coxScoreCardColumn
                                : scoreCardColumn
                            }
                            data={BSTableData}
                            // defaultSort={{ columnIndex: 2, direction: "desc" }}
                            autoWidth={false}
                            paging={false}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="trend_chart" ref={blockRef}>
                  <div className="card">
                    <div className="card-header">
                      <div>Trend Chart</div>
                    </div>
                    <div className="card-body">
                      <div id="mychartdetailsbsc">
                        {isFetching ? (
                          <div className="text-center">
                            <BarChartLoader />
                          </div>
                        ) : (
                          <TrendChart tablecharts={tablecharts} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scorecard_ranking">
                <div className="card">
                  <div className="card-header">
                    <div>Scorecard Ranking</div>
                  </div>
                  {isFetching ? (
                    <div className="text-center">
                      <RepeatableRowsLoader />
                    </div>
                  ) : (
                    <div
                      className="card-body custom-sort"
                      id="rank_table"
                      style={{
                        // height: "92vh",
                        overflow: "auto",
                      }}
                      ref={rankTableRef}
                    >
                      <ReactDataTables
                        id="scorecard_ranking_table"
                        columns={rankingColumn}
                        data={TableData}
                        rowBorderColor={rowBorderColor}
                        // defaultSort={{ columnIndex: 2, direction: "desc" }}
                        autoWidth={false}
                        paging={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BalancedScorecard;
