import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import DatePicker from "react-datepicker";
import TimeFrameFooter from "./timeFrameFooter";
import { FormatDate, SubDays } from "../../utils/dateFormat";

function TimeFrameTabContent({
  activeTab,
  defaultActiveTime,
  workWeeksAllList,
  applyAction,
  cancelAction,
}) {
  const [activeTime, setActiveTime] = useState(defaultActiveTime);
  const [monthTypeRecord, setMonthTypeRecord] = useState([]);
  const [weekTypeRecord, setWeekTypeRecord] = useState([]);
  const [startDate, setStartDate] = useState();

  const toggletime = (frameData) => setActiveTime(frameData);

  const filterByTypeOfRecord = (type) =>
    workWeeksAllList.filter((item) => item.typeofrecord === type);

  useEffect(() => {
    if (workWeeksAllList) {
      const monthRecords = filterByTypeOfRecord("Month");
      setMonthTypeRecord(monthRecords);
      setWeekTypeRecord(filterByTypeOfRecord("week"));
    }
    if (defaultActiveTime?.typeofrecord == "Daily") {
      setStartDate(defaultActiveTime?.transdate);
    }
  }, [workWeeksAllList, defaultActiveTime]);

  return (
    <TabContent
      className="tab-content"
      id="v-pillstimeframe-tabContent"
      activeTab={activeTab}
    >
      <TabPane tabId="v-pillstimeframe-monthly">
        <div className="month_selector">
          <ul>
            {monthTypeRecord.map((item) => {
              const { transdate, id, displayname } = item;
              return (
                <li
                  key={id}
                  id={transdate}
                  className={`dateselection monthly ${
                    activeTime.transdate === transdate && "active"
                  }`}
                  recordtype="Monthly"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggletime(item)}
                >
                  <div className="month d-flex space-between align-center">
                    <div className="month_date">{displayname}</div>
                    {activeTime.transdate === transdate && (
                      <div className="show_active">
                        <img
                          src="images/checkmark.svg"
                          alt="active-checkmark"
                        />
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <TimeFrameFooter
          disable={!activeTime.transdate}
          applyAction={() => applyAction(activeTime)}
          cancelAction={cancelAction}
        />
      </TabPane>
      <TabPane tabId="v-pillstimeframe-weekly">
        <div className="month_selector">
          <ul>
            {weekTypeRecord.map((item) => {
              const { transdate, id, displayname } = item;
              return (
                <li
                  key={id}
                  id={transdate}
                  className={`dateselection weekly ${
                    activeTime.transdate === transdate && "active"
                  }`}
                  recordtype="Weekly"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggletime(item)}
                >
                  <div className="month d-flex space-between align-center">
                    <div className="week_date">{displayname}</div>
                    {activeTime.transdate === transdate && (
                      <div className="show_active">
                        <img
                          src="images/checkmark.svg"
                          alt="active-checkmark"
                        />
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <TimeFrameFooter
          disable={!activeTime.transdate}
          applyAction={() => applyAction(activeTime)}
          cancelAction={cancelAction}
        />
      </TabPane>
      <TabPane
        tabId="v-pillstimeframe-daily"
        style={{
          paddingTop: "20px",
        }}
      >
        <div className="date_selector">
          <div
            className="input-group date dateselection"
            id="datepicker"
            recordtype="Daily"
          >
            <DatePicker
              inline
              selected={startDate}
              minDate={SubDays(new Date(), 31)}
              maxDate={new Date()}
              onChange={(date) => {
                setStartDate(date);
                let dailyData = {
                  displayname: FormatDate(date),
                  transdate: FormatDate(date),
                  typeofrecord: "Daily",
                };
                toggletime(dailyData);
              }}
            />
            <input type="text" className="form-control visibility-hidden" />
            <span className="input-group-append visibility-hidden">
              <span className="input-group-text bg-white d-block">
                <i className="fa fa-calendar"></i>
              </span>
            </span>
          </div>
        </div>
        <TimeFrameFooter
          disable={!activeTime.transdate}
          className="date_selection_save_footer"
          applyAction={() => applyAction(activeTime)}
          cancelAction={cancelAction}
        />
      </TabPane>
    </TabContent>
  );
}

export default TimeFrameTabContent;
