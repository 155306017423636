import moment from "moment";

export const FormatDateFormat = (date, format) => {
  return date != null ? moment(date).format(format) : null;
};
export const FormatDate = (date) => {
  return date != null ? moment(date).format("DD-MMM-YYYY") : null;
};
export const FormatDateStamp = (date) => {
  return date != null ? moment(date).format("DD/MM/YYYY hh:mm A") : null;
};
export const FormatDateMMdd = (date) => {
  return date != null ? moment(date).format("MM-dd-yyyy") : null;
};
export const FormatDateyyyyMMdd = (date) => {
  return date != null ? moment(date).format("yyyy-MM-dd") : null;
};
export const Formathhmm = (date) => {
  return date != null ? moment(date).format("hh:mm") : null;
};
export const FormathhmmA = (date) => {
  return date != null ? moment(date).format("hh:mm A") : null;
};
export const FormatIST = (date) => {
  return date != null ? moment(date).format("DD-MMM-YYYY h:mm A [IST]") : null;
};

export const SubDays = (date, days) => {
  return moment(date).subtract(days, "days").toDate();
};
