import React, { useEffect, useState, useRef } from "react";

const CustomSubMenu = ({ optionItem, menuIcon }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <div className="show_more_options">
      <div
        className="tool position-relative"
        onMouseEnter={() => setShowSubMenu((preStatus) => !preStatus)}
        onMouseLeave={() => setShowSubMenu((preStatus) => !preStatus)}
      >
        <img src={menuIcon ? menuIcon : "images/more-options-black.svg"} />
        <div className={`commit-tooltip ${showSubMenu && "block"}`}>
          {optionItem}
        </div>
      </div>
    </div>
  );
};

export default CustomSubMenu;
