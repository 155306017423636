import ActionTypes from "../reduxHelper";
import { createAction, nanoid } from "@reduxjs/toolkit";

export const setOrganizationDetail = (payload) => ({
  type: ActionTypes.SET_ORGANIZATION_DETAIL_SET,
  payload,
});

// export const getAgentDashboardOrgProgramsRequest = (queryParams) => ({
//   type: ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_REQUEST,
//   payload: queryParams,
// });
// Agent Dashboard Actions
export const getAgentDashboardOrgProgramsRequest = createAction(
  ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getAgentDashboardOrgProgramsTimeFrame = createAction(
  ActionTypes.GET_AGENT_DASHBOARD_ORG_PROGRAMS_TIME_FRAME_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
// Work Weeks Actions
export const getDimWorkWeeksAllRequest = createAction(
  ActionTypes.GET_DIM_WORK_WEEKS_ALL_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);

// Agent Details Actions
export const getAgentDetails = createAction(
  ActionTypes.GET_AGENT_DETAILS_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getAgentDetailsReset = createAction(
  ActionTypes.GET_AGENT_DETAILS_RESET,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getUserMetaData = createAction(
  ActionTypes.GET_USER_META_DATA_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getAgentCoachHistory = (queryParams) => ({
  type: ActionTypes.GET_AGENT_COACH_HISTORY_REQUEST,
  payload: queryParams,
});

export const getAgentCoachHistoryById = (queryParams) => ({
  type: ActionTypes.GET_AGENT_COACH_HISTORY_BY_ID_REQUEST,
  payload: queryParams,
});

export const agentdbRecognizationDetails = (queryParams) => ({
  type: ActionTypes.AGENT_DB_RECOGNIZATION_DETAILS_REQUEST,
  payload: queryParams,
});

export const getAgentGameTable = (queryParams) => ({
  type: ActionTypes.GET_AGENT_GAME_TABLE_REQUEST,
  payload: queryParams,
});

export const getActivityDashboard = (queryParams) => ({
  type: ActionTypes.GET_ACTIVITY_DASHBOARD_REQUEST,
  payload: queryParams,
});

export const getAgentCurrentCommitments = (queryParams) => ({
  type: ActionTypes.GET_AGENT_CURRENT_COMMITMENTS_REQUEST,
  payload: queryParams,
});
export const getAgentCurrentCommitmentsReset = (queryParams) => ({
  type: ActionTypes.GET_AGENT_CURRENT_COMMITMENTS_RESET,
  payload: queryParams,
});

export const getAgentBestPractices = (queryParams) => ({
  type: ActionTypes.GET_AGENT_BEST_PRACTICES_REQUEST,
  payload: queryParams,
});

export const getExecAgentDashboardMetrics = (queryParams) => ({
  type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_METRICS_REQUEST,
  payload: queryParams,
});

export const getExecAgentDashboardMetricsReset = (queryParams) => ({
  type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_METRICS_RESET,
  payload: queryParams,
});

export const getExecAGentBalanceScoreCardPopup = (queryParams) => ({
  type: ActionTypes.GET_EXEC_AGENT_BALANCE_SCORE_CARD_POPUP_REQUEST,
  payload: queryParams,
});

export const getdashboardconfigurationValues = (queryParams) => ({
  type: ActionTypes.GET_DASHBOARD_CONFIGURATION_VALUES_REQUEST,
  payload: queryParams,
});

export const latestcompletedrecord = (queryParams) => ({
  type: ActionTypes.LATEST_COMPLETED_RECORD_REQUEST,
  payload: queryParams,
});

export const getCompanyOrgProgramConfigurations = (queryParams) => ({
  type: ActionTypes.GET_COMPANY_ORG_PROGRAM_CONFIGURATIONS_REQUEST,
  payload: queryParams,
});

export const getMetricDetailsPopupListView = (queryParams) => ({
  type: ActionTypes.GET_METRICDETAILSPOPUP_LISTVIEW_REQUEST,
  payload: queryParams,
});

export const getAgentMetricDetailsPopup = (queryParams) => ({
  type: ActionTypes.GET_AGENT_METRIC_DETAILS_POPUP_REQUEST,
  payload: queryParams,
});

export const getCoachingsMetricDetailPopup = (queryParams) => ({
  type: ActionTypes.GET_COACHINGS_METRIC_DETAIL_POPUP_REQUEST,
  payload: queryParams,
});

export const getMetricDetailsPopup = (queryParams) => ({
  type: ActionTypes.GET_METRIC_DETAILS_POPUP_REQUEST,
  payload: queryParams,
});

export const getMetricDetailsPopupTrendChart = (queryParams) => ({
  type: ActionTypes.GET_METRIC_DETAILS_POPUP_TREND_CHART_REQUEST,
  payload: queryParams,
});

export const getAgentDashboardMetrics = (queryParams) => ({
  type: ActionTypes.GET_AGENT_DASHBOARD_METRICS_REQUEST,
  payload: queryParams,
});

export const getExecAgentdashboardCoachingDetails = (queryParams) => ({
  type: ActionTypes.GET_EXEC_AGENT_DASHBOARD_COACHING_DETAILS_REQUEST,
  payload: queryParams,
});

export const getexecagentactivityhistoryview = (queryParams) => ({
  type: ActionTypes.GET_EXEC_AGENT_ACTIVITY_HISTORY_VIEW_REQUEST,
  payload: queryParams,
});

export const taskManagementModule = (queryParams) => ({
  type: ActionTypes.TASK_MANAGEMENT_MODULE_REQUEST,
  payload: queryParams,
});

export const getqudarantthreecardsExecAgent = (queryParams) => ({
  type: ActionTypes.GET_QUDARANT_THREE_CARDS_EXEC_AGENT_REQUEST,
  payload: queryParams,
});

export const getAgentCoachHistoryV3byactivityid = (queryParams) => ({
  type: ActionTypes.GET_AGENT_COACH_HISTORY_V3_BY_ACTIVITYID_REQUEST,
  payload: queryParams,
});

export const getmultimonthscorecarddimworkweeks = (queryParams) => ({
  type: ActionTypes.GET_MULTI_MONTH_SCORECARD_DIM_WORK_WEEKS_REQUEST,
  payload: queryParams,
});
export const getmultimonthScoreCardPopup = (queryParams) => ({
  type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_POPUP_REQUEST,
  payload: queryParams,
});

export const getmultimonthscorecardemployeedetails = (queryParams) => ({
  type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_EMPLOYEE_DETAILS_REQUEST,
  payload: queryParams,
});

export const getGameByCompanyGuidOrgProgramEmpid = (queryParams) => ({
  type: ActionTypes.GET_GAME_BY_COMPANY_GUID_ORG_PROGRAM_EMP_ID_REQUEST,
  payload: queryParams,
});

export const getMetricCalculationsMetricDetailPopup = (queryParams) => ({
  type: ActionTypes.GET_METRIC_CALCULATIONS_METRIC_DETAIL_POPUP_REQUEST,
  payload: queryParams,
});

export const getActivityHistory = (queryParams) => ({
  type: ActionTypes.GET_ACTIVITY_HISTORY_REQUEST,
  payload: queryParams,
});

export const getTaskSaveListForLastHistory = (queryParams) => ({
  type: ActionTypes.GET_TASK_SAVE_LIST_FOR_LAST_HISTORY_REQUEST,
  payload: queryParams,
});

export const leaderBoardAPI = (queryParams) => ({
  type: ActionTypes.LEADER_BOARD_API_REQUEST,
  payload: queryParams,
});

export const taskHistory = (queryParams) => ({
  type: ActionTypes.TASK_HISTORY_REQUEST,
  payload: queryParams,
});

export const getMultiMonthScoreCardPermission = (queryParams) => ({
  type: ActionTypes.GET_MULTI_MONTH_SCORE_CARD_PERMISSION_REQUEST,
  payload: queryParams,
});

export const getAgentScheduling = (queryParams) => ({
  type: ActionTypes.GET_AGENT_SCHEDULING_REQUEST,
  payload: queryParams,
});
export const getAgentInsentiveTables = (queryParams) => ({
  type: ActionTypes.GET_AGENT_INSENTIVE_TABLES_REQUEST,
  payload: queryParams,
});

export const getIncentiveCardsExportAndPopUp = (queryParams) => ({
  type: ActionTypes.GET_INCENTIVE_CARDS_EXPORT_AND_POPUP_REQUEST,
  payload: queryParams,
});

export const getCxPBEstimatedPayout = (queryParams) => ({
  type: ActionTypes.GET_CXPB_ESTIMATED_PAYOUT_REQUEST,
  payload: queryParams,
});

export const getS2SEstimatedPayout = (queryParams) => ({
  type: ActionTypes.GET_S2S_ESTIMATED_PAYOUT_REQUEST,
  payload: queryParams,
});

export const getCompayConfigurationValues = (queryParams) => ({
  type: ActionTypes.GET_COMPAY_CONFIGURATION_VALUES_REQUEST,
  payload: queryParams,
});

export const cxPBCalculatePotentialPayout = (queryParams) => ({
  type: ActionTypes.CXPB_CALCULATE_POTENTIAL_PAYOUT_REQUEST,
  payload: queryParams,
});
export const getMTDMyLevelupTileDetails_YTD = (queryParams) => ({
  type: ActionTypes.GET_MTD_MY_LEVEL_UP_TILE_DETAILS_YTD_REQUEST,
  payload: queryParams,
});

export const getAIMLMessages = (queryParams) => ({
  type: ActionTypes.GET_AIML_MESSAGES_REQUEST,
  payload: queryParams,
});

export const getAgentAIMLConfigurations = (queryParams) => ({
  type: ActionTypes.GET_AGENT_AIML_CONFIGURATIONS_REQUEST,
  payload: queryParams,
});
export const getAdditionalLinksFlagMetricDetail = createAction(
  ActionTypes.GET_ADDITIONAL_LINKS_FLAG_METRIC_DETAIL_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getIntradayAgentDashboardTicker = createAction(
  ActionTypes.GET_INTRA_DAY_AGENT_DASHBOARD_TICKER_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const getCxPBRankingDetails = createAction(
  ActionTypes.GET_CXPB_RANKING_DETAILS_REQUEST,
  (queryParams) => ({ payload: { id: nanoid(), ...queryParams } })
);
export const postDataRequest = (payload) => ({
  type: ActionTypes.POST_DATA_REQUEST,
  payload,
});
