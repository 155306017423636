import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AuthContext } from "../../utils/CompanyContext";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomTooltip from "../../customComponents/customTooltip";
import {
  MetricDetails,
  MetricDetailsListView,
  LeaderBoardDetails,
} from "../partialScreens";
import {
  getCompanyOrgProgramConfigurations,
  getMetricDetailsPopupListView,
  getAgentMetricDetailsPopup,
  getMetricDetailsPopup,
  getCoachingsMetricDetailPopup,
  getMetricDetailsPopupTrendChart,
  getAgentDashboardMetrics,
  getMetricCalculationsMetricDetailPopup,
  getAdditionalLinksFlagMetricDetail,
} from "../../store/actions";
import { DataTableLoader } from "../../utils/Loader";

import MetricTimeFrameBlock from "../partialScreens/MetricTimeFrameBlock";
const ScorecardModal = ({
  clickedRow,
  setActivetab,
  showModal,
  isRankEnabled,
  toggleLeaderboardModal,
}) => {
  const dispatch = useDispatch();
  const { loginemptitle, usertype } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(setActivetab);
  const [activeView, setActiveView] = useState("Grid");
  const {
    activeOrganizationInfo,
    isAllMetricDetailsInverse,
    metricdetailspopupListview,
    metricdetailspopupDetailsview,
    coachingsMetricInfo,
    metricDetailsInfo,
    trendChartMetricDetailsInfo,
    agentDashboardMetricsInfo,
    isFetchingList,
    metricCalculationsInfo,
    commonReferenceLinks_adtl,
    commonReferenceLinkstwo_adtl,
  } = useSelector(
    ({
      get_coachings_metric_detail_popup: { data: coachingsMetricData },
      get_metric_details_popup: { data: metricDetailsData },
      get_active_organization_detail: { data: activeOrganizationData },
      get_metricdetailspopup_listview: {
        data: metricdetailspopupListviewData,
        isFetching,
      },
      get_agent_metric_details_popup: {
        data: metricdetailspopupDetailsViewData,
      },
      get_company_org_program_configurations: {
        data: companyOrgProgramConfigurationsData,
      },
      get_metric_details_popup_trend_chart: {
        data: trendChartMetricDetailsData,
      },
      get_agent_dashboard_metrics: { data: agentDashboardMetricsData },
      get_additional_links_flag_metric_detail: {
        data: additionalLinksFlagData,
      },
      get_metric_calculations_metric_detail_popup: {
        data: metricCalculationsData,
      },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        metricdetailspopupListview: metricdetailspopupListviewData || [],
        isFetchingList: isFetching,
        metricdetailspopupDetailsview: metricdetailspopupDetailsViewData || [],
        isAllMetricDetailsInverse: companyOrgProgramConfigurationsData?.[0],
        coachingsMetricInfo: coachingsMetricData,
        metricDetailsInfo: metricDetailsData || [],
        trendChartMetricDetailsInfo: trendChartMetricDetailsData || [],
        agentDashboardMetricsInfo: agentDashboardMetricsData || {},
        metricCalculationsInfo: metricCalculationsData?.[0] || {},
        commonReferenceLinks_adtl:
          additionalLinksFlagData?.[0]?.commonReferenceLinks_adtl || null,
        commonReferenceLinkstwo_adtl:
          additionalLinksFlagData?.[0]?.commonReferenceLinkstwo_adtl || null,
      };
    },
    shallowEqual
  );
  const fetchData = useCallback(
    (data) => {
      const { type, frameData } = data || {};
      const { employeeid, metricname, metricid, metrictype } = clickedRow || {};
      const inverseMetricType =
        isAllMetricDetailsInverse?.configurationValue?.toLowerCase() === "y"
          ? "INVERSE"
          : metrictype;
      const recordtype =
        type || activeOrganizationInfo?.FrameData?.typeofrecord;
      const recorddate =
        frameData?.transdate || activeOrganizationInfo?.FrameData?.transdate;

      dispatch(
        getMetricDetailsPopupListView({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          recordtype: recordtype,
          recorddate: recorddate,
          employeeid: employeeid,
          metricname: metricname,
          metricid: metricid,
          roletype: "L1",
          metrictype: inverseMetricType,
        })
      );
      dispatch(
        getAgentMetricDetailsPopup({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          recordtype: recordtype,
          recorddate: recorddate,
          employeeid: employeeid,
          metricname: metricname,
          metricid: metricid,
        })
      );
      dispatch(
        getMetricDetailsPopup({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          recordtype: recordtype,
          month: "04",
          year: "2024",
          weekstartdate: recorddate,
          recorddate: recorddate,
          employeeid: employeeid,
          metricname: metricname,
          metricid: metricid,
          roletype: "L1",
        })
      );
      dispatch(
        getCoachingsMetricDetailPopup({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          recordtype: recordtype,
          month: "04",
          year: "2024",
          weekstartdate: recorddate,
          recorddate: recorddate,
          employeeid: employeeid,
          metricname: metricname,
          metricid: metricid,
          roletype: "L1",
        })
      );
      {
        isRankEnabled &&
          dispatch(
            getAgentDashboardMetrics({
              companyguid: activeOrganizationInfo?.Companyguid,
              organization: activeOrganizationInfo?.Organization,
              primaryprogram: activeOrganizationInfo?.Program,
              timeperiod: recordtype,
              recorddate: recorddate,
              varemployee_loginid: employeeid,
              metricname: metricname,
              metricid: metricid,
              userlogedinemail: activeOrganizationInfo?.LoginEmail,
              loggedintitle: loginemptitle || "Admin",
              canenhance: activeOrganizationInfo?.performenchance || "N",
              usertype: usertype,
            })
          );
      }
    },
    [dispatch, clickedRow, isAllMetricDetailsInverse, activeOrganizationInfo]
  );
  useEffect(() => {
    const { employeeid, metricname, metricid, metrictype } = clickedRow || {};
    dispatch(
      getCompanyOrgProgramConfigurations({
        companyguid: activeOrganizationInfo?.Companyguid,
        org: activeOrganizationInfo?.Organization,
        configurationName: "isallmetricdetailsinverse",
      })
    );
    dispatch(
      getMetricCalculationsMetricDetailPopup({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        primaryprogram: activeOrganizationInfo?.Program,
        metricname: metricname,
        metricid: metricid,
      })
    );
    dispatch(
      getAdditionalLinksFlagMetricDetail({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        primaryprogram: activeOrganizationInfo?.Program,
        metricname: metricname,
        metricid: metricid,
      })
    );
  }, [dispatch, clickedRow, activeOrganizationInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  const toggleView = useCallback(
    (view) => {
      if (activeView !== view) {
        setActiveView(view);
      }
    },
    [activeView]
  );

  const fnGetmetricDetails = useCallback(
    (metricdetailname) => {
      const { employeeid, metricname, metricid, metrictype } = clickedRow || {};
      dispatch(
        getMetricDetailsPopupTrendChart({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          recordtype: activeOrganizationInfo?.FrameData?.typeofrecord,
          recorddate: activeOrganizationInfo?.FrameData?.transdate,
          employeeid: employeeid,
          metricname: metricname,
          metricid: metricid,
          metricDetailName: metricdetailname?.toUpperCase(),
          roletype: "L1",
        })
      );
    },
    [dispatch, clickedRow, activeOrganizationInfo]
  );
  const getFrameData = useCallback(
    ({ type, frameData }) => {
      fetchData({ type, frameData });
    },
    [fetchData]
  );
  const closeBtn = useMemo(
    () => (
      <div className="links_right">
        {activeTab == "metricdetails" && (
          <div className="list_tile_view">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeView === "List" ? "active" : ""
                  } `}
                  id="pills-list-tab"
                  onClick={() => toggleView("List")}
                >
                  <img src="images/list.svg" />
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeView === "Grid" ? "active" : ""
                  } `}
                  id="pills-grid-tab"
                  onClick={() => toggleView("Grid")}
                >
                  <img src="images/grid.svg" />
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="d-flex">
          <div className="additional_link">
            {commonReferenceLinks_adtl ? (
              <button
                className="btn btn-bordered"
                onClick={() => window.open(commonReferenceLinks_adtl, "_blank")}
              >
                Additional Link
              </button>
            ) : (
              <button className="btn btn-bordered disabled">
                Additional Link
              </button>
            )}
          </div>

          <div className="additional_link">
            {commonReferenceLinkstwo_adtl ? (
              <button
                className="btn btn-bordered"
                onClick={() =>
                  window.open(commonReferenceLinkstwo_adtl, "_blank")
                }
              >
                Additional Link
              </button>
            ) : (
              <button className="btn btn-bordered disabled">
                Additional Link
              </button>
            )}
          </div>
        </div>
        <button
          className="modal_close"
          onClick={() => {
            toggleLeaderboardModal();
          }}
          type="button"
        >
          <img src="images/close.svg" />
        </button>
      </div>
    ),
    [
      activeTab,
      activeView,
      commonReferenceLinks_adtl,
      commonReferenceLinkstwo_adtl,
      toggleView,
      toggleLeaderboardModal,
    ]
  );
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleLeaderboardModal}
      //   className={className}
      backdrop={true}
      keyboard={true}
      id="leaderboard_modal"
      style={{
        width: "90%",
        maxWidth: "90%",
      }}
    >
      <ModalHeader
        toggle={toggleLeaderboardModal}
        close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="d-flex flex-start align-center">
          <span className="">
            <strong>{clickedRow?.metricname}</strong>
          </span>
          <CustomTooltip
            tooltipText="?"
            tooltipContent={`<div>Metric Formula</div>
                          <div>${
                            metricCalculationsInfo?.metricCalculationDescription
                              ? metricCalculationsInfo.metricCalculationDescription
                              : ""
                          }</div>`}
          />
          <div className="tabs">
            <Nav pills id="background-pills-tab">
              <NavItem>
                <NavLink
                  id="pills-metricdetails-tab"
                  className={activeTab == "metricdetails" ? "active" : ""}
                  onClick={() => toggleTab("metricdetails")}
                >
                  Metric Details
                </NavLink>
              </NavItem>
              {isRankEnabled && (
                <NavItem>
                  <NavLink
                    className={activeTab == "leaderboard" ? "active" : ""}
                    onClick={() => toggleTab("leaderboard")}
                    id="pills-leaderboard-tab"
                  >
                    Leaderboard
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
          <div className="modal_timeframe time_frame_block">
            <MetricTimeFrameBlock getFrameData={getFrameData} />
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <TabContent id="pills-tabContent" activeTab={activeTab}>
          <TabPane id="pills-metricdetails" tabId="metricdetails">
            {activeView === "Grid" && (
              <div className="" id="pills-grid">
                <div id="metric_allmetricdetails_partialview" className="p-1">
                  <MetricDetails
                    clickedRow={clickedRow}
                    coachings={coachingsMetricInfo}
                    trendChartMetricDetails={trendChartMetricDetailsInfo}
                    fnGetmetricDetails={fnGetmetricDetails}
                    metricDetailsInfo={metricDetailsInfo?.[0]?.metricdetails}
                    metricdetailspopupDetailsview={
                      metricdetailspopupDetailsview
                    }
                  />
                </div>
              </div>
            )}
            {activeView === "List" && (
              <div className="" id="pills-list">
                <div className="w-100">
                  {isFetchingList ? (
                    <div className="text-center">
                      <DataTableLoader />
                    </div>
                  ) : (
                    <div className="listview">
                      <MetricDetailsListView
                        clickedRow={clickedRow}
                        metricdetailspopupListview={metricdetailspopupListview}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </TabPane>
          {isRankEnabled && (
            <TabPane id="pills-leaderboard" tabId="leaderboard">
              {activeTab === "leaderboard" && (
                <LeaderBoardDetails
                  clickedRow={clickedRow}
                  agentDashboardMetricsInfo={agentDashboardMetricsInfo}
                />
              )}
            </TabPane>
          )}
        </TabContent>
      </ModalBody>
    </Modal>
  );
};
export default React.memo(ScorecardModal);
