import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-dt/css/jquery.dataTables.css";
import "./tableStyles.css";

function ReactDataTables({
  id,
  data,
  columns,
  paging,
  options,
  onRowClick,
  pageLength,
  rowStyles,
  rowBorderColor,
  defaultSort,
  disableSorting,
  className,
  autoWidth,
  hideHeader,
  getRowClassName,
  fixedHeader,
  scrollY,
}) {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const initializeTable = () => {
    const dtColumns = columns.map((col) => ({
      title: col.title,
      data: col.data,
      orderable: disableSorting
        ? false
        : col.sortable !== undefined
        ? col.sortable
        : true,
      render: col.render,
      type: col.type,
      width: col.width,
      createdCell: (td, cellData, rowData, rowIdx, colIdx) => {
        if (col.align) {
          $(td).addClass(`${col.align}-align`);
        }
        if (col.onClick && !disableSorting) {
          $(td)
            .off("click")
            .on("click", (e) => {
              e.stopPropagation();
              col.onClick(rowData, colIdx);
            });
        }
      },
      className: col.align ? `${col.align}-align` : "",
    }));
    if ($.fn.dataTable.isDataTable(tableRef.current)) {
      const table = $(tableRef.current).DataTable();
      table.clear().rows.add(data).draw();
    } else {
      tableInstance.current = $(tableRef.current).DataTable({
        data,
        columns: dtColumns,
        order: defaultSort
          ? [[defaultSort.columnIndex, defaultSort.direction]]
          : [],
        createdRow: (row, rowData, rowIdx) => {
          // const styles = rowStyles(data);
          // if (styles) {
          //   $(row).css(styles);
          // }
          const borderColor = (rowBorderColor || (() => ""))(rowData);
          if (borderColor) {
            $(row)
              .find("td:first-child")
              .css("border-left", `5px solid ${borderColor}`);
          }
          // Add custom class name to the row
          const className = getRowClassName
            ? getRowClassName(rowData, rowIdx)
            : "";
          if (className) {
            $(row).addClass(className);
          }
          // Set max-width and min-width for <thead> <td> elements
          // Attach the row click handler here
          // if (onRowClick) {
          //   $(row)
          //     .off("click")
          //     .on("click", () => onRowClick(rowData, rowIdx));
          // }
        },
        info: false, // Disable the "Showing 1 to X of Y entries" text
        searching: false,
        paging: paging !== undefined ? paging : true,
        scrollCollapse: true,
        responsive: true,
        scrollX: true,
        scrollY: scrollY,
        autoWidth: autoWidth || false,
        // initComplete: function (settings, json) {
        //   $(".dataTables_scrollBody thead tr").css({ visibility: "collapse" });
        // },
        dom: 't<"pagination-container"p>',
        pageLength: pageLength || 5,
        fixedHeader: { fixedHeader },
        initComplete: function () {
          if (hideHeader) {
            $(tableRef.current).find("thead").css("visibility", "collapse");
          }
          // tableInstance.current?.columns.adjust().draw();
        },
        // sScrollXInner: "110%",
        ...options,
      });
    }
  };
  useEffect(() => {
    if (tableInstance.current) {
      tableInstance.current.clear().rows.add(data).draw();
    } else {
      initializeTable();
    }
  }, [data]);

  useEffect(() => {
    if (!tableInstance.current) {
      initializeTable();
    }
  }, [columns, options, rowBorderColor]);

  useEffect(() => {
    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy(true);
        tableInstance.current = null;
      }
    };
  }, []);

  return (
    // <div className={`custom-data-table ${className}`}>
    <div
      className={`custom-data-table ${className} ${
        hideHeader ? "hide-header" : ""
      }`}
    >
      <table
        id={id}
        style={{
          width: "100%",
        }}
        className="table table-bordered hover dataTable"
        ref={tableRef}
      ></table>
    </div>
  );
}

export default ReactDataTables;
