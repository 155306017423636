import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector, shallowEqual } from "react-redux";
import { isNullOrEmpty, GetOrdinal, getFirstChar } from "../../utils";
import { RepeatableRowsLoader } from "../../utils/Loader";
import ReactDataTables from "../../customComponents/ReactDataTables";

const TeamRanking = ({
  clickedRow,
  filterByMetEmpData,
  filterByMetData,
  filterByMetDataWithNull,
}) => {
  const [TableData, setTableData] = useState([]);

  useEffect(() => {
    if (filterByMetData) {
      const filteredByRankData = [...filterByMetData].sort(
        (a, b) => parseFloat(a.team_rank) - parseFloat(b.team_rank)
      );
      setTableData(filteredByRankData);
    }
  }, [filterByMetData]);

  const isFetching = useSelector(
    (state) => state.get_agent_dashboard_metrics.isFetching
  );

  const columns = [
    {
      title: "Rank",
      data: "team_rank",
      width: "15%",
      align: "left",
      render: (data) => {
        return `<div class="rank_number">
          <div><b>${
            !isNullOrEmpty(data) ? GetOrdinal(parseInt(data)) : "----"
          }</b></div>
        </div>`;
      },
    },
    {
      title: "name",
      data: "agentfirstname",
      width: "40%",
      align: "left",
      render: (data, type, row) => {
        return `<div class="d-flex flex-start align-center">
          <div class="ranked_person">
            <div class="first_last_name">
              <div class="space_name">
                ${getFirstChar(data)}<span></span>${getFirstChar(
          row.agentlastname
        )}
              </div>
            </div>
            <div class="ranked_person_details">
              <div class="ranked_person_name">${data} ${row.agentlastname}</div>
              <div class="ranked_person_title">${row.agenttitle}</div>
            </div>
          </div>
        </div>`;
      },
      // render: function (data, type, row) {
      //   return ReactDOMServer.renderToString(
      //     <RankingRow rowData={data} clickedRow={clickedRow} />
      //   );
      // },
    },
    {
      title: " ",
      data: "agentprogress",
      sortable: false,
      width: "30%",
      align: "left",
      render: (data, type, row) => {
        return `<div class="rank_progress">
          <div class="position-relative">
            <div class="current_progress" style="background-color: ${row?.metriccolor}; width: ${data}%; height: 10px; border-radius: 4px;"></div>
          </div>
        </div>`;
      },
    },
    {
      title: "score",
      data: "actual",
      type: "number",
      render: (data) => {
        return `<div class="rank_value">
          <div><b>${data}</b></div>
        </div>`;
      },
      width: "15%",
    },
  ];
  const getRowClassName = (rowData, rowIdx) => {
    return rowData.employeeid?.toLowerCase() ===
      clickedRow?.employeeid?.toLowerCase()
      ? "top_rank-in-tbl"
      : "transparent-bg";
  };
  return (
    <div>
      <div className="team_rank_description">
        <div>
          <h3>Team Ranking</h3>
        </div>
        <div className="gray-text">
          A ranked list of you and your team members based on the scores for
          this metric.
        </div>
      </div>
      {isFetching ? (
        <div className="text-center">
          <RepeatableRowsLoader width={700} rowHeight={100} />
        </div>
      ) : (
        <>
          <div
            className="top_rank"
            style={{
              paddingRight: "5px",
            }}
          >
            {filterByMetEmpData?.map((item, index) => {
              return (
                <div className="d-flex space-between align-center">
                  <div className="rank_number">
                    <div>
                      {item.team_rank == null ? (
                        <b>----</b>
                      ) : (
                        <b>
                          {!isNullOrEmpty(item.team_rank)
                            ? GetOrdinal(parseInt(item.team_rank))
                            : null}
                        </b>
                      )}
                    </div>
                  </div>
                  <div className="ranked_person">
                    <div className="d-flex flex-start align-start">
                      <div className="first_last_name">
                        <div className="space_name">
                          {item.agentfirstname?.substring(0, 1)}
                          <span></span>
                          {item.agentlastname?.substring(0, 1)}
                        </div>
                      </div>
                      <div className="ranked_person_details">
                        <div className="ranked_person_name">
                          {item.agentfirstname} {item.agentlastname}
                        </div>
                        <div className="ranked_person_title">
                          {item.agenttitle}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="rank_progress"
                    style={{
                      padding: "15px 20px",
                    }}
                  >
                    <div className="position-relative">
                      <div
                        className="current_progress"
                        style={{
                          backgroundColor: item.metriccolor,
                          width: `${item.agentprogress}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="rank_value">
                    <div>
                      <b>{item.actual}</b>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="leaderboard_table lr-no-border">
            <ReactDataTables
              columns={columns}
              data={TableData}
              getRowClassName={getRowClassName}
              defaultSort={{ columnIndex: 2, direction: "desc" }}
              autoWidth={false}
              paging={false}
            />
            {filterByMetDataWithNull?.length > 0 && (
              <ReactDataTables
                columns={columns}
                getRowClassName={getRowClassName}
                data={filterByMetDataWithNull}
                autoWidth={false}
                paging={false}
                hideHeader={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default TeamRanking;
