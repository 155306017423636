import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getInitials,
  CftItemRow,
  CXPBEstimatedPayoutDetailsTable,
} from "./coxUtils";
import "../../../assets/css/coxModal.css";
import CxPBRankingDetails from "./CxPBRankingDetails";

const CoxIncentivecxpbModal = ({
  clickedRow,
  showModal,
  toggleModal,
  cxPB_EstimatedPayoutDetails,
  cxPB_RankingDetails,
}) => {
  const [nestedModal, setNestedModal] = useState(false);
  const { activeOrganizationInfo } = useSelector(
    ({ get_active_organization_detail: { data: activeOrganizationData } }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
      };
    },
    shallowEqual
  );
  const [inputResults, setResults] = useState(
    cxPB_EstimatedPayoutDetails?.reduce((acc, item) => {
      acc[item.plaN_CMPNT_NM.toLowerCase()] = (item.results * 100).toFixed(2);
      return acc;
    }, {})
  );

  const toggleNested = useCallback((rowItem) => {
    setNestedModal((prev) => !prev);
  }, []);

  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );

  const { agentname, quarter, estimateD_PAYOUT, scorecards, results } =
    cxPB_EstimatedPayoutDetails?.[0] || {};

  const initials = getInitials(agentname);
  const quarterValue = "Q" + quarter;
  const filteredDetails = cxPB_EstimatedPayoutDetails?.filter(
    (item) => item.plaN_CMPNT_NM.toLowerCase() !== "customer focus time"
  );
  const specialOrganizations = [
    "residential technical support",
    "account services",
    "cb advanced technical support",
    "residential customer advocacy group",
    "cb technical support",
  ];
  const isSpecialOrganization = specialOrganizations.includes(
    activeOrganizationInfo?.Organization.toLowerCase()
  );

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      //   centered={true}
      id="cox_incentive"
      external={closeBtn}
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="cox_incentive_cxpb" className="p-0">
        {cxPB_EstimatedPayoutDetails?.length > 0 ? (
          <div className="container-fluid">
            <div
              className="row"
              style={{
                backgroundColor: "#f5f8fa",
              }}
            >
              <div className="col-md-7 pr-0">
                <div className="inc_leftblock">
                  <div className="incentive_block1">
                    <div className="flex space-between align-start">
                      <div className="detail_block">
                        <div className="inc_profile flex align-start">
                          <div className="inc_name_letters">
                            <div className="fs">{initials}</div>
                          </div>
                          <div className="inc_name_details">
                            <div className="namee" id="agent_details">
                              {agentname}
                            </div>
                            <p
                              style={{
                                fontWeight: "600px",
                              }}
                            >
                              Agent
                            </p>
                          </div>
                        </div>
                        <div className="inc_month flex align-center">
                          <div className="">Quarter</div>
                          <div>:</div>
                          <div
                            style={{
                              padding: "5px",
                              fontWeight: "500",
                            }}
                          >
                            {quarterValue}
                          </div>
                        </div>
                        <div className="inc_month flex align-center">
                          <div className="">Scorecards</div>
                          <div>:</div>
                          <div
                            style={{
                              padding: "5px",
                              fontWeight: "500",
                            }}
                          >
                            {scorecards}
                          </div>
                        </div>
                      </div>
                      <div
                        className="estimate_block"
                        style={{ marginTop: "46px" }}
                      >
                        <div className="white_bg">
                          <div
                            className={`mb-10 ${
                              estimateD_PAYOUT > 0 ? "" : "inc_red"
                            }`}
                            style={{ fontWeight: "200" }}
                          >
                            Estimated Payout
                          </div>
                          <div
                            className={`inc_dollar_cxpb ${
                              estimateD_PAYOUT > 0 ? "inc_green" : "inc_red"
                            }`}
                          >
                            ${estimateD_PAYOUT}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="incentive_block2"
                    style={{
                      marginBottom: "65px",
                    }}
                  >
                    <div className="table-responsive">
                      <table className="table tiles-table">
                        <thead>
                          <tr>
                            <th>Metric</th>
                            <th>Actual Score</th>
                            <th>Rank</th>
                            <th>Band</th>
                            <th>Estimated Payout</th>
                          </tr>
                        </thead>
                        <tbody>
                          <CftItemRow
                            estimatedPayout={cxPB_EstimatedPayoutDetails}
                          />
                          {/* <CXPBEstimatedPayoutDetailsTable
                            estimatedPayoutDetails={filteredDetails}
                          /> */}
                          {filteredDetails?.map((item, index) => {
                            const isCustomerResolveRate =
                              item.plaN_CMPNT_NM.toLowerCase() ===
                              "customer resolve rate";
                            const metricName = isCustomerResolveRate
                              ? "FCR"
                              : "Cust Sent";
                            const valueId = isCustomerResolveRate
                              ? "old_fcr_value"
                              : "old_ce_value";
                            const bandId = isCustomerResolveRate
                              ? "old_fcr_band"
                              : "old_ce_band";
                            const payoutId = isCustomerResolveRate
                              ? "old_fcr_payout"
                              : "old_ce_payout";
                            const payoutClass =
                              item.cftgate === "Y" && item.payout > 0
                                ? "inc_green"
                                : "inc_red";
                            const payoutAmount =
                              item.cftgate === "Y" && item.payout > 0
                                ? item.payout
                                : "0.00";
                            const rank =
                              item.cftgate === "Y" ? item.ranK1 : "N/A";
                            const band =
                              item.cftgate === "Y" ? item.band : "Not Eligible";
                            const value = (item.results * 100)?.toFixed(2);
                            return (
                              <tr key={index}>
                                <td
                                  id={
                                    isCustomerResolveRate
                                      ? "fcr_metric_name"
                                      : "ce_metric_name"
                                  }
                                >
                                  {metricName}
                                </td>
                                <td id={valueId}>{value}</td>
                                <td>{rank}</td>
                                <td id={bandId}>{band}</td>
                                <td className={payoutClass} id={payoutId}>
                                  ${payoutAmount}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pr-0">
                <div
                  className="inc_right_block1"
                  style={{
                    marginTop: "8px",
                  }}
                >
                  <div
                    className="bgg"
                    style={{
                      marginTop: "-6px",
                    }}
                  >
                    <div className="pl-15">
                      <h3>Calculate Potential CxPB Earnings</h3>
                      <div
                        className="pt-8 flex justify-content-center align-start"
                        style={{
                          marginBottom: "36px",
                        }}
                      >
                        <div className="total_months">
                          <div className="form-group">
                            <label>Number of Months</label>
                            <select className="form-control" id="noofmonths">
                              <option value="1" selected={scorecards == "1"}>
                                1
                              </option>
                              <option value="2" selected={scorecards == "2"}>
                                2
                              </option>
                              <option value="3" selected={scorecards == "3"}>
                                3
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="inc_potential_tile ideal">
                          <div>Potential Payout</div>
                          <div className="inc_val">$--.--</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val"></div>
                          </div>
                        </div>
                        <div className="show_money_gif hid">
                          <img
                            src="images/slotmachine.gif?2"
                            style={{ width: "180px" }}
                            alt="Slot Machine"
                          />
                        </div>
                        <div className="inc_potential_tile redd_tile hid">
                          <div className="headd">Potential Payout</div>
                          <div className="inc_val">$0.00</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val">$166.67</div>
                          </div>
                        </div>
                        <div className="inc_potential_tile green_tile hid">
                          <div className="headd">Potential Payout</div>
                          <div className="inc_val">$0.00</div>
                          <div className="flex flex-start align-center">
                            <div className="up_down_img"></div>
                            <div className="payout_val">$166.67</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="potential_table">
                      <div className="table-responsive">
                        <table className="table tiles-table">
                          <thead>
                            <tr>
                              <th>New Score</th>
                              <th>New Band</th>
                              <th>Potential Payout</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="inc_input">
                                <input
                                  disabled
                                  type="number"
                                  id="cft_value"
                                  className="form-control"
                                  value={(results * 100).toFixed(2)}
                                />
                              </td>
                              <td className="inc_input">N/A</td>
                              <td className="inc_input">
                                <button
                                  className="btn btn-primary none_met"
                                  id="potential_payout_cft"
                                >
                                  N/A
                                </button>
                              </td>
                            </tr>
                            {filteredDetails?.map((item, index) => {
                              const lowerCaseName =
                                item.plaN_CMPNT_NM.toLowerCase();
                              const nameMap = {
                                "customer resolve rate": {
                                  valueId: "fcr_value",
                                  bandId: "fcr_band",
                                  payoutId: "fcr_payout",
                                },
                              };
                              const defaultMap = {
                                valueId: "ce_value",
                                bandId: "ce_band",
                                payoutId: "ce_payout",
                              };
                              const { valueId, bandId, payoutId } =
                                nameMap[lowerCaseName] || defaultMap;
                              return (
                                <tr key={index}>
                                  <td className="inc_input">
                                    <input
                                      disabled
                                      type="number"
                                      className="form-control"
                                      id={valueId}
                                      value={inputResults[lowerCaseName]}
                                      // onChange={(e) =>
                                      //   handleChange(
                                      //     lowerCaseName,
                                      //     e.target.value
                                      //   )
                                      // }
                                    />
                                  </td>
                                  <td className="inc_input" id={bandId}>
                                    --.--
                                  </td>
                                  <td className="inc_input" id={payoutId}>
                                    $--.--
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="show_money">
                        <button
                          className="btn btn-primary"
                          id="calculate_potential_earn_cxpb"
                          style={{
                            fontSize: "17px",
                            background: "#348bf2",
                          }}
                        >
                          Show Me the money
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="term_cond"
              style={{
                backgroundColor: "#f5f8fa",
              }}
            >
              <div
                className="row"
                style={{
                  marginTop: "-19px",
                }}
              >
                <div
                  className="col-md-7"
                  style={{ paddingRight: "0px", marginTop: "-48px" }}
                >
                  <div className="inc_terms_cond">
                    <div>
                      Please remember that this data includes estimates only.
                      Every effort is made to ensure that all data is accurate,
                      but there are regular updates, occasional delays and
                      sometimes corrections that will cause differences between
                      the numbers reported here and elsewhere from one day to
                      the next. The estimates on this page do not guarantee any
                      correlation to final payout for the Customer Experience
                      Performance Bonus. Additionally, participants can be
                      deemed ineligible as a result of corrective action,
                      alternate incentive structures, or other items as
                      communicated by leadership.
                      {isSpecialOrganization &&
                        " You must also achieve a Meets Expectation for the Quarter on your scorecard to qualify for this payout."}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="inc_details">
                    <div className="flex justify-content-end">
                      <div className="mr-5">
                        <a
                          className="btn btn-primary"
                          onClick={toggleNested}
                          style={{
                            background: "#414d5a",
                            borderColor: "#414d5a",
                          }}
                        >
                          RANKING DETAIL
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-dialog" style={{ fontSize: "80px" }}>
            <p>No Data</p>
          </div>
        )}
        {nestedModal && (
          <CxPBRankingDetails
            cxPB_RankingDetails={cxPB_RankingDetails}
            showModal={nestedModal}
            toggleModal={toggleNested}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
export default CoxIncentivecxpbModal;
