import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTooltip from "../../customComponents/customTooltip";
import { isNullOrEmpty } from "../../utils";
import { FormatDate } from "../../utils/dateFormat";
import { ParseHtml } from "../../utils/parseHtml";
import {
  getAgentCoachHistoryById,
  getExecAgentdashboardCoachingDetails,
} from "../../store/actions";
import { FormLoader } from "../../utils/Loader";
const CoachingDetailsModal = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, coachHistoryByIdInfo, isFetching } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },

        get_exec_agent_dashboard_coaching_details: {
          data: coachHistoryByIdData,
          isFetching,
        },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          coachHistoryByIdInfo: coachHistoryByIdData,
          isFetching: isFetching,
        };
      },
      shallowEqual
    );
  useEffect(() => {
    dispatch(
      getExecAgentdashboardCoachingDetails({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        employeeid: activeOrganizationInfo?.Employeeid,
        primaryprogram: activeOrganizationInfo?.Program,
        accessloginid: activeOrganizationInfo?.Loginid,
        coachingid: clickedRow?.ncoachingid || clickedRow?.coachingid,
        canacknowledgecoaching: clickedRow?.canacknowledgecoaching,
        metricname: clickedRow?.metricname,
        bublecolor: clickedRow?.bublecolor,
        attachments: clickedRow?.attachments,
        audiopath: null,
      })
    );
  }, []);
  const closeBtn = (
    <div className="links_right w-45">
      {coachHistoryByIdInfo &&
        !isNullOrEmpty(coachHistoryByIdInfo?.audiopath) && (
          <div className="coaching_audio">
            <audio
              controls
              controlsList="nodownload"
              style={{
                fontSize: "10px",
              }}
            >
              <source src={coachHistoryByIdInfo?.audiopath} type="audio/mp3" />
            </audio>
          </div>
        )}
      {/* {!isNullOrEmpty(coachHistoryByIdInfo?.attachments) && (
            <div className="coaching_file_doc">
              <img
                title="attached"
                src="images/file_upload.svg"
                style={{
                  cursor: "pointer",
                }}
                onClick={toggleDocModal}
              />
            </div>
          )} */}
      <button className="modal_close" onClick={toggleModal} type="button">
        <img src="images/close.svg" />
      </button>
    </div>
  );
  const {
    bublecolor,
    mmcfConfigValues,
    getMMCFAgentCoachingHistoryV4,
    microLearningCoachingForm,
    ackConfigList,
    canacknowledgecoaching,
  } = coachHistoryByIdInfo || {};
  let isL1_Agent =
    activeOrganizationInfo?.titleroletype?.toLowerCase() == "l1" ||
    activeOrganizationInfo?.titleroletype?.toLowerCase() == "agent";

  const filterItems = (condition) => {
    return (
      getMMCFAgentCoachingHistoryV4?.filter((item) => {
        return condition(item);
      }) ?? []
    );
  };

  const filteredByActRichUrlMetric = filterItems((item) => {
    return (
      item.lableName !== "Ack" &&
      item.inputType !== "RichText" &&
      item.inputType !== "Url" &&
      item.lableType !== "MetricRichText" &&
      item.lableType !== "ProgramNotesPlainText"
    );
  });

  const filteredByMetric = filterItems((item) => {
    return item.lableType === "MetricRichText";
  });

  const filteredByUrl = filterItems((item) => {
    return item.inputType === "Url";
  });

  const filteredByRichTextMetric = filterItems((item) => {
    return (
      (item.inputType === "RichText" && item.lableType !== "MetricRichText") ||
      (item.InputType === "Text" && item.lableType === "ProgramNotesPlainText")
    );
  });

  const filteredByAck = filterItems((item) => {
    return item.lableName === "Ack" && item.lableValue != null;
  });
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      //   className={className}
      backdrop={true}
      keyboard={true}
      id="coaching_history_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="d-flex flex-start align-center">
          <div className="title">Coaching Details</div>
          <CustomTooltip
            tooltipText="?"
            tooltipDesc="View the details of the selected coaching session"
          />
        </div>
      </ModalHeader>
      <ModalBody id="">
        {isFetching ? (
          <div className="text-center">
            <FormLoader width={800} />
          </div>
        ) : (
          <div className="row">
            {bublecolor?.toLowerCase() == "red" && (
              <div className="col-md-12">
                <div className="trending_below_commitment d-flex space-between align-center">
                  <div className="d-flex flex-start align-center">
                    <div className="trending">
                      <img src="images/icon_outline.svg" />
                    </div>
                    <div className="red"> Trending below Commitment</div>
                    <div>Review Coaching Details</div>
                  </div>
                </div>
              </div>
            )}
            {getMMCFAgentCoachingHistoryV4?.length > 0 && (
              <>
                <div className="coached_fields">
                  <div className="d-flex flex-start align-start">
                    {filteredByActRichUrlMetric?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            {item.InputType?.toLowerCase() === "date" &&
                            item.lableType !== "Ack date" ? (
                              <div className="form-control">
                                {FormatDate(item.lableValue)}
                              </div>
                            ) : (
                              <div className="form-control">
                                {ParseHtml(item.lableValue)}
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          {item.InputType?.toLowerCase() === "date" &&
                          item.lableType !== "Ack date" ? (
                            <div className="form-control">
                              {FormatDate(item.lableValue)}
                            </div>
                          ) : (
                            <div className="form-control">
                              {ParseHtml(item.lableValue)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {filteredByMetric?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            <div className="form-control">
                              {ParseHtml(item.lableValue)}
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            {ParseHtml(item.lableValue)}
                          </div>
                        </div>
                      );
                    })}
                    {filteredByUrl?.map((item) => {
                      return isL1_Agent ? (
                        item.showdetailsonagentlevel === true && (
                          <div className="form-group">
                            <label className="lbl">{item.lableName}</label>
                            <div className="form-control">
                              <a href={item.lableValue} target="_blank">
                                {ParseHtml(item.lableValue)}
                              </a>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            <a href={item.lableValue} target="_blank">
                              {ParseHtml(item.lableValue)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="reviews_learning">
                  <h6>Reviewed Learning</h6>
                  <div>
                    {microLearningCoachingForm?.map((item, index) => {
                      return (
                        <b key={index}>
                          <a href={item.videopath} target="_blank">
                            {item.skillname}
                          </a>
                        </b>
                      );
                    })}
                  </div>
                </div>
                <div className="notes">
                  <h3>Notes</h3>
                </div>
                <div className="notes_fields">
                  {filteredByRichTextMetric?.map((item) => {
                    return isL1_Agent ? (
                      item.showdetailsonagentlevel === true && (
                        <div className="form-group">
                          <label className="lbl">{item.lableName}</label>
                          <div className="form-control">
                            {ParseHtml(item.lableValue)}
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="form-group">
                        <label className="lbl">{item.lableName}</label>
                        <div className="form-control">
                          {ParseHtml(item.lableValue)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {filteredByAck?.length > 0 && (
                  <div className="afni_check">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="afni_radiobtn"
                          checked
                          disabled
                        />
                        <label className="form-check-label" for="afni_radiobtn">
                          {ackConfigList?.map(
                            (item) => item.configurationValue
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default CoachingDetailsModal;
