import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomTooltip from "../../customComponents/customTooltip";
import { getLocalPathImage } from "../../utils";
import LearningLibraryViewFrame from "./learningLibraryViewFrame";
const LearningLibrary = ({
  defaultActive,
  showModal,
  toggleLearningModal,
  BestPractices,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActive || "new");
  const toggleTab = (tab) => activeTab !== tab && setActiveTab(tab);
  const [nestedModal, setNestedModal] = useState(false);
  const [clickedItem, setClickedItem] = useState({});

  const toggleNested = useCallback((rowItem) => {
    setClickedItem(rowItem);
    setNestedModal((prev) => !prev);
  }, []);
  const closeBtn = (
    <div className="d-flex">
      <button
        className="modal_close"
        onClick={toggleLearningModal}
        type="button"
      >
        <img src="images/close.svg" />
      </button>
    </div>
  );

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleLearningModal}
      backdrop={true}
      keyboard={true}
      id="learning_resource_modal"
    >
      <ModalHeader
        toggle={toggleLearningModal}
        close={closeBtn}
        className="custom-modal"
      >
        <div className="d-flex flex-start align-center">
          <span className="">Learning Library</span>
          <CustomTooltip
            tooltipText="?"
            tooltipDesc="The Learning Library contains training videos for you"
          />
          <div className="tabs">
            <Nav pills id="background-pills-tab">
              <NavItem>
                <NavLink
                  id="pills-new-tab"
                  className={activeTab == "new" ? "active" : ""}
                  onClick={() => toggleTab("new")}
                >
                  New
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab == "view" ? "active" : ""}
                  onClick={() => toggleTab("view")}
                  id="pills-view-tab"
                >
                  Viewed
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab == "all" ? "active" : ""}
                  onClick={() => toggleTab("all")}
                  id="pills-all-tab"
                >
                  All
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <TabContent id="pills-tabContent" activeTab={activeTab}>
          <TabPane id="pills-new" tabId="new">
            <div className="learning_images">
              <div className="d-flex flex-start align-start flex-wrap">
                {BestPractices?.filter((item) => item.isViewed === false)?.map(
                  (item) => (
                    <div
                      className="learning_resouce"
                      // onClick={() => insertreport(item.microid)}
                      key={item.microid}
                    >
                      <div>
                        <img
                          onClick={() => toggleNested(item)}
                          src={
                            item.imagepath && getLocalPathImage(item.imagepath)
                          }
                          alt=""
                        />
                      </div>
                      <div onClick={() => toggleNested(item)}>
                        <a
                        // href={item.videopath}
                        >
                          {item.skillname}
                        </a>
                      </div>
                      <div>{item.microlearningdescription}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </TabPane>
          <TabPane id="pills-view" tabId="view">
            <div className="learning_images">
              <div className="d-flex flex-start align-start flex-wrap">
                {BestPractices?.filter((item) => item.isViewed === true)?.map(
                  (item) => (
                    <div
                      className="learning_resouce"
                      // onClick={() => insertreport(item.microid)}
                      key={item.microid}
                    >
                      <div>
                        <img
                          onClick={() => toggleNested(item)}
                          src={
                            item.imagepath && getLocalPathImage(item.imagepath)
                          }
                          alt=""
                        />
                      </div>
                      <div onClick={() => toggleNested(item)}>
                        <a
                        // href={item.videopath}
                        >
                          {item.skillname}
                        </a>
                      </div>
                      <div>{item.microlearningdescription}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </TabPane>
          <TabPane id="pills-all" tabId="all">
            <div className="learning_images">
              <div className="d-flex flex-start align-start flex-wrap">
                {BestPractices?.map((item) => (
                  <div
                    className="learning_resouce"
                    // onClick={() => insertreport(item.microid)}
                    key={item.microid}
                  >
                    <div>
                      <img
                        onClick={() => toggleNested(item)}
                        src={
                          item.imagepath && getLocalPathImage(item.imagepath)
                        }
                        alt=""
                      />
                    </div>
                    <div onClick={() => toggleNested(item)}>
                      <a
                        style={{}}
                        // href={item.videopath}
                      >
                        {item.skillname}
                      </a>
                    </div>
                    <div>{item.microlearningdescription}</div>
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
        </TabContent>
        {nestedModal && (
          <LearningLibraryViewFrame
            clickedItem={clickedItem}
            showModal={nestedModal}
            toggleModal={toggleNested}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default LearningLibrary;
