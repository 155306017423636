import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Swal from "sweetalert2";
import { MetricPerformanceBar, GoalAction } from "../partialScreens";
import CustomTooltip from "../../customComponents/customTooltip";
import {
  ScorecardModal,
  LearningLibraryModal,
  CoachingDetailsModal,
} from "../modals";
import { FormatDate } from "../../utils/dateFormat";
import Currentcommitmenets from "../partialScreens/currentcommitmenets";
import { RepeatableRowsLoader } from "../../utils/Loader";
import {
  getExecAgentDashboardMetricsReset,
  getAgentDetailsReset,
  getAgentCurrentCommitmentsReset,
} from "../../store/actions";

const MetricCommitment = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLearningModalOpen, setLearningModalOpen] = useState(false);
  const [activeView, setActiveView] = useState("new");
  const [clickedRow, setClickedRow] = useState({});
  const [isLeaderBoard, setIsLeaderBoard] = useState(false);
  const [quadrantHei, setQuadrantHei] = useState(0);
  const [quadrant1Hei, setQuadrant1Hei] = useState(0);
  const [commitmentHei, setCommitmentHei] = useState(0);
  const [isCoachingDetails, setCoachingDetails] = useState(false);
  const [isRankEnabled, setRankEnabled] = useState(false);
  const [clickedCoachingRow, setClickedCoachingRow] = useState({});
  const {
    agentDetailInfo,
    currentCommitmentsInfo,
    agentDashboardMetricsInfo,
    latestCompletedRecordInfo,
    dashboardConfigurationInfo,
    agentBestPracticesInfo,
    isFetchingMetrics,
    agentDashboardTimeFrameInfo,
  } = useSelector(
    ({
      get_agent_details: { data: agentDetailData },
      get_agent_current_commitments: { data: currentCommitmentsData },
      get_exec_agent_dashboard_metrics: {
        data: agentDashboardMetricsData,
        isFetching,
      },
      latest_completed_record: { data: latestCompletedRecordData },
      get_dashboard_configuration_values: { data: dashboardConfigurationData },
      get_agent_best_practices: { data: agentBestPracticesData },
      get_agent_dashboard_org_programs_time_frame: {
        data: agentDashboardTimeFrameData,
      },
    }) => {
      return {
        agentDetailInfo: agentDetailData,
        currentCommitmentsInfo: currentCommitmentsData || [],
        agentDashboardMetricsInfo: agentDashboardMetricsData || [],
        isFetchingMetrics: isFetching,
        latestCompletedRecordInfo: latestCompletedRecordData?.[0] || {},
        dashboardConfigurationInfo: dashboardConfigurationData || [],
        agentBestPracticesInfo: agentBestPracticesData || [],
        agentDashboardTimeFrameInfo: agentDashboardTimeFrameData,
      };
    },
    shallowEqual
  );
  const commitmentBlockRef = useRef(null);
  const learningBlockRef = useRef(null);
  const metricTableRef = useRef(null);

  const toggleLeaderboardModal = useCallback((rowItem, isLeaderBoard) => {
    setClickedRow(rowItem);
    setIsLeaderBoard(isLeaderBoard);
    setModalOpen((prev) => !prev);
  }, []);

  const toggleLearningModal = useCallback((view) => {
    setLearningModalOpen((prev) => !prev);
    setActiveView(view);
  }, []);

  const toggleCoachingDetailsModal = useCallback((rowItem) => {
    setClickedCoachingRow(rowItem);
    setCoachingDetails((prev) => !prev);
  }, []);

  useEffect(() => {
    if (agentDashboardMetricsInfo) {
      const commitmentBlockHeight = commitmentBlockRef.current.offsetHeight;
      const learningBlockHeight = learningBlockRef.current.offsetHeight;
      const metricTableHeight = metricTableRef.current.offsetHeight;

      const newQuadrantHei = commitmentBlockHeight + learningBlockHeight;

      if (metricTableHeight <= 200) {
        const newQuadrant1Hei = newQuadrantHei + 75;
        if (quadrant1Hei !== newQuadrant1Hei) {
          setQuadrant1Hei(newQuadrant1Hei);
        }
        if (metricTableRef.current.style.height !== `${newQuadrantHei}px`) {
          metricTableRef.current.style.height = `${newQuadrantHei}px`;
        }
      } else {
        const newCommitmentHei = metricTableHeight - learningBlockHeight + 40;
        if (commitmentHei !== newCommitmentHei) {
          setCommitmentHei(newCommitmentHei);
        }
        if (
          commitmentBlockRef.current.style.height !== `${newCommitmentHei}px`
        ) {
          commitmentBlockRef.current.style.height = `${newCommitmentHei}px`;
        }
      }

      if (quadrantHei !== newQuadrantHei) {
        setQuadrantHei(newQuadrantHei);
      }
    }
  }, [agentDashboardMetricsInfo, quadrantHei, quadrant1Hei, commitmentHei]);
  useEffect(() => {
    if (agentDashboardTimeFrameInfo?.length == 0) {
      // reset metric related api data
      dispatch(getExecAgentDashboardMetricsReset({}));
      dispatch(getAgentDetailsReset({}));
      dispatch(getAgentCurrentCommitmentsReset({}));
      Swal.fire({
        text: "There is no data for you for this timeframe. Please select a different timeframe.",
      });
    }
  }, [agentDashboardTimeFrameInfo]);
  useEffect(() => {
    if (dashboardConfigurationInfo?.length > 0) {
      const updatedThroughOption = dashboardConfigurationInfo?.some(
        (c) => c.configurationName === "RANKS" && c.configurationValue === "Y"
      );
      setRankEnabled(updatedThroughOption);
    }
  }, [dashboardConfigurationInfo]);
  return (
    <div className="metric_commitment_block">
      <div className="metrics_block">
        <div className="card">
          <div className="card-header">
            <div className="d-flex flex-start align-center min-hei-40">
              <div className="metric_title">
                <b>Metrics</b>
              </div>
              <div className="gray-text">
                Data last processed on <span id="latprocesseddate"></span>
                {FormatDate(latestCompletedRecordInfo.processEndTime)}
              </div>
              <CustomTooltip
                tooltipText="?"
                tooltipDesc="List of the metrics for your program, your score, goal value and ranking"
              />
            </div>
          </div>
          <div className="card-body">
            <div className="metric_table" ref={metricTableRef}>
              {isFetchingMetrics ? (
                <div className="text-center">
                  <RepeatableRowsLoader />
                </div>
              ) : (
                <table className="table table-bordered w-100">
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "25%",
                        }}
                      >
                        Metric
                      </th>
                      <th
                        style={{
                          width: "50%",
                        }}
                      >
                        performance
                      </th>
                      <th
                        style={{
                          width: "25%",
                        }}
                      >
                        actual/goal
                      </th>
                    </tr>
                  </thead>
                  <tbody id="partialagentmetrics">
                    {agentDashboardMetricsInfo?.map((matricsItem, index) => {
                      return (
                        <tr
                          key={`${index}${matricsItem.metricname}`}
                          onClick={() => toggleLeaderboardModal(matricsItem)}
                        >
                          <td>{matricsItem.metricname}</td>
                          <td>
                            <MetricPerformanceBar
                              agentDetailInfo={agentDetailInfo}
                              rowItem={matricsItem}
                              isRankEnabled={isRankEnabled}
                              dashboardConfigurationInfo={
                                dashboardConfigurationInfo
                              }
                            />
                          </td>
                          <td className="third-col">
                            <GoalAction
                              toggleLeaderboardModal={toggleLeaderboardModal}
                              rowItem={matricsItem}
                              isRankEnabled={isRankEnabled}
                              dashboardConfigurationInfo={
                                dashboardConfigurationInfo
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                // <div id="quadrantid1"></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="commitment_learning_block">
        <div className="commitment_block" ref={commitmentBlockRef}>
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-start align-center min-hei-40">
                <div className="metric_title">
                  <b>Current Commitments</b>
                </div>
                <CustomTooltip
                  tooltipText="?"
                  tooltipDesc="Lists the various performance commitments made during
                        coaching sessions and its current status"
                />
              </div>
            </div>
            <div className="card-body">
              <div className="commitment_header commitment_table">
                <div className="d-flex space-between align-center">
                  <div className="col w-25">Metric</div>
                  <div className="d-flex space-between align-center col w-60">
                    <div>Start</div>
                    <div>commit</div>
                  </div>
                  <div className="col w-15 text-center">End</div>
                </div>

                <Currentcommitmenets
                  currentCommitmentsInfo={currentCommitmentsInfo}
                  toggleCoachingDetailsModal={toggleCoachingDetailsModal}
                />

                <div id="quadrantid2"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="learning_block" ref={learningBlockRef}>
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-start align-center min-hei-40">
                <div className="metric_title">
                  <b>Learning Resources</b>
                </div>
                <CustomTooltip
                  tooltipText="?"
                  tooltipDesc="The Learning Library contains training videos for you"
                />
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex space-between align-items-end pd-30">
                <div className="viewed_learning">
                  <div
                    className="new_learning"
                    onClick={() => {
                      toggleLearningModal("new");
                    }}
                  >
                    <div>New</div>
                    <div>
                      <strong>
                        {
                          agentBestPracticesInfo?.filter(
                            (x) => x.isViewed === false
                          ).length
                        }
                      </strong>
                    </div>
                  </div>
                  <div
                    className="viewed"
                    onClick={() => {
                      toggleLearningModal("view");
                    }}
                  >
                    <div>Viewed</div>
                    <div>
                      <strong>
                        {
                          agentBestPracticesInfo?.filter(
                            (x) => x.isViewed === true
                          ).length
                        }
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="view">
                  <button
                    className="btn btn-bordered view_all_practices"
                    onClick={() => {
                      toggleLearningModal("all");
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ScorecardModal
          clickedRow={clickedRow}
          showModal={isModalOpen}
          isRankEnabled={isRankEnabled}
          toggleLeaderboardModal={toggleLeaderboardModal}
          setActivetab={isLeaderBoard ? "leaderboard" : "metricdetails"}
        />
      )}
      {isLearningModalOpen && (
        <LearningLibraryModal
          defaultActive={activeView}
          showModal={isLearningModalOpen}
          BestPractices={agentBestPracticesInfo}
          toggleLearningModal={toggleLearningModal}
        />
      )}
      {isCoachingDetails && (
        <CoachingDetailsModal
          clickedRow={clickedCoachingRow}
          showModal={isCoachingDetails}
          toggleModal={toggleCoachingDetailsModal}
        />
      )}
    </div>
  );
};

export default MetricCommitment;
