import { getActionTypes } from "../core/handler";

export const keys = [
  "SET_ORGANIZATION_DETAIL",
  "GET_AGENT_DASHBOARD_ORG_PROGRAMS",
  "GET_AGENT_DASHBOARD_ORG_PROGRAMS_TIME_FRAME",
  "GET_DIM_WORK_WEEKS_ALL",
  "GET_AGENT_DETAILS",
  "GET_USER_META_DATA",
  "GET_AGENT_COACH_HISTORY",
  "GET_AGENT_COACH_HISTORY_BY_ID",
  "AGENT_DB_RECOGNIZATION_DETAILS",
  "GET_AGENT_GAME_TABLE",
  "GET_ACTIVITY_DASHBOARD",
  "GET_AGENT_CURRENT_COMMITMENTS",
  "GET_AGENT_BEST_PRACTICES",
  "GET_EXEC_AGENT_DASHBOARD_METRICS",
  "GET_DASHBOARD_CONFIGURATION_VALUES",
  "LATEST_COMPLETED_RECORD",
  "GET_EXEC_AGENT_BALANCE_SCORE_CARD_POPUP",
  "GET_COMPANY_ORG_PROGRAM_CONFIGURATIONS",
  "GET_METRICDETAILSPOPUP_LISTVIEW",
  "GET_AGENT_METRIC_DETAILS_POPUP",
  "GET_COACHINGS_METRIC_DETAIL_POPUP",
  "GET_METRIC_DETAILS_POPUP",
  "GET_METRIC_DETAILS_POPUP_TREND_CHART",
  "GET_AGENT_DASHBOARD_METRICS",
  "GET_EXEC_AGENT_DASHBOARD_COACHING_DETAILS",
  "GET_EXEC_AGENT_ACTIVITY_HISTORY_VIEW",
  "TASK_MANAGEMENT_MODULE",
  "GET_QUDARANT_THREE_CARDS_EXEC_AGENT",
  "GET_AGENT_COACH_HISTORY_V3_BY_ACTIVITYID",
  "GET_MULTI_MONTH_SCORECARD_DIM_WORK_WEEKS",
  "GET_MULTI_MONTH_SCORE_CARD_POPUP",
  "GET_MULTI_MONTH_SCORE_CARD_EMPLOYEE_DETAILS",
  "GET_GAME_BY_COMPANY_GUID_ORG_PROGRAM_EMP_ID",
  "GET_METRIC_CALCULATIONS_METRIC_DETAIL_POPUP",
  "GET_TASK_SAVE_LIST_FOR_LAST_HISTORY",
  "LEADER_BOARD_API",
  "GET_ACTIVITY_HISTORY",
  "TASK_HISTORY",
  "POST_DATA",
  "GET_MULTI_MONTH_SCORE_CARD_PERMISSION",
  "GET_AGENT_SCHEDULING",
  "GET_AGENT_INSENTIVE_TABLES",
  "GET_INCENTIVE_CARDS_EXPORT_AND_POPUP",
  "GET_CXPB_ESTIMATED_PAYOUT",
  "GET_S2S_ESTIMATED_PAYOUT",
  "GET_COMPAY_CONFIGURATION_VALUES",
  "CXPB_CALCULATE_POTENTIAL_PAYOUT",
  "GET_MTD_MY_LEVEL_UP_TILE_DETAILS_YTD",
  "GET_AGENT_AIML_CONFIGURATIONS",
  "GET_AIML_MESSAGES",
  "GET_ADDITIONAL_LINKS_FLAG_METRIC_DETAIL",
  "GET_INTRA_DAY_AGENT_DASHBOARD_TICKER",
  "GET_CXPB_RANKING_DETAILS",
];

const ActionTypes = getActionTypes(keys);
export default ActionTypes;
