import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getMTDMyLevelupTileDetails_YTD } from "../../../store/actions";
import CompanyGUIDs, { currentCompanyGuid } from "../../../utils/GUIDConst";
import { RepRankingLegendFAQ } from "../../../utils/FAQ";

const AgentMTDdetailsYTD = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, mtdDataInfo, isFetching } = useSelector(
    ({
      get_active_organization_detail: { data: activeOrganizationData },
      get_mtd_my_level_up_tile_details_ytd: { data: mtdData, isFetching },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        mtdDataInfo: mtdData,
        isFetching: isFetching,
      };
    },
    shallowEqual
  );
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );

  useEffect(() => {
    dispatch(
      getMTDMyLevelupTileDetails_YTD({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        primaryprogram: activeOrganizationInfo?.Program,
        recordtype: "YTD",
        employeeid: activeOrganizationInfo?.Employeeid,
      })
    );
  }, [dispatch]);

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      // centered={true}
      external={closeBtn}
      id="AgentMtd_YtdDetails"
      style={{
        width: "350px",
      }}
    >
      <ModalHeader className="custom-modal metric_header">
        <div className="flex space-between align-center">
          <div className="inc_hd">My Level UP-YTD</div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <table
          className="table table-bordered incentive1 tiles-table"
          style={{
            fontWeight: "normal",
            marginBottom: "0px",
            border: "1px solid rgb(0 0 0 / 42%)",
          }}
          id="inc_table"
        >
          <thead>
            <tr>
              <th className="text-center">Month</th>
              <th>USD</th>
            </tr>
          </thead>
          <tbody>
            {mtdDataInfo?.map((item, index) => (
              <tr key={index}>
                <td className={item.recmonth == "Total" ? "setBg" : ""}>
                  {item.recmonth}
                </td>
                <td className={item.recmonth == "Total" ? "setBg" : ""}>
                  {item.mtdValue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};
export default AgentMTDdetailsYTD;
