import React from "react";
import { ParseHtml } from "../../utils/parseHtml";

const CustomTooltip = (props) => {
  return (
    <div className="question_icon position-relative tool">
      <span>{props.tooltipText}</span>
      <div
        id="custom-tooltip"
        className="commit-tooltip hover_description text-left"
      >
        {props.tooltipContent ? (
          ParseHtml(props.tooltipContent)
        ) : (
          <div>
            <b>{props.tooltipDesc}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;
