import React, { useEffect, useState, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { color } from "highcharts";

const TrendChart = ({ tablecharts }) => {
  const [chartOptions, setChartOptions] = useState();
  useEffect(() => {
    if (tablecharts) {
      const categories = tablecharts.map((chart) => chart.recMonth);
      const seriesdata = tablecharts.map((chart) =>
        parseFloat(chart.balncescore)
      );
      setChartOptions({
        xAxis: {
          categories: categories,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        series: [{ name: "Score", data: seriesdata, color: "#7CB5EC" }],
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
      });
    }
  }, [tablecharts]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default TrendChart;
