import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormatDate } from "../../utils/dateFormat";
import CustomTooltip from "../../customComponents/customTooltip";
import { TrendChart } from "../partialScreens";
import MultiMonthScorecardDetailModal from "./multiMonthScorecardDetailModal";
import { getmultimonthscorecarddimworkweeks } from "../../store/actions";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";

const MultiMonthScorecardModal = ({ showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({});
  const [isScoreDetailModal, setScoreDetailModal] = useState(false);

  const { activeOrganizationInfo, dimworkweeksmultimonth } = useSelector(
    ({
      get_active_organization_detail: { data: activeOrganizationData },
      get_multi_month_scorecard_dim_work_weeks: { data: multiMonthDimWorkData },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        dimworkweeksmultimonth: multiMonthDimWorkData || [],
      };
    },
    shallowEqual
  );
  useEffect(() => {
    dispatch(
      getmultimonthscorecarddimworkweeks({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        type: "month",
      })
    );
  }, []);
  const handleChange = (event) => {
    let range = {
      ...dateRange,
      [event.target.id]: event.target.value,
    };
    setDateRange(range);
  };
  const toggleDetailScoreModal = () => setScoreDetailModal((pre) => !pre);

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      id="multimonth_scorecardmodal"
      centered
      style={{
        maxWidth: "40%",
      }}
    >
      <ModalBody id="balancescorecarddetails" className="p-0">
        <div className="row">
          <div className="col-md-12">
            <h3>Select the time frame to begin.</h3>
          </div>
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
              <div className="scorecard_text_note">
                All scores are based on balanced scorecard point values.
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="start_end_time">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Start Month - Year</th>
                    <th>End Month - Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="dimworkweeksmultimonth">
                    <td>
                      <select
                        className="form-control"
                        id="multimonthscorecardfromdate"
                        onChange={handleChange}
                      >
                        <option selected>Select Month - Year</option>
                        {dimworkweeksmultimonth?.map((item) => {
                          return (
                            <option value={FormatDate(item.startdate)}>
                              {item.displayname}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      <select
                        className="form-control"
                        id="multimonthscorecardtodate"
                        onChange={handleChange}
                      >
                        <option selected>Select Month - Year</option>
                        {dimworkweeksmultimonth?.map((item) => {
                          return (
                            <option value={FormatDate(item.startdate)}>
                              {item.displayname}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          className="col-sm-12 modal-footer pull-right text-end"
          style={{
            padding: "0px",
            backgroundColor: "#fff",
          }}
        >
          <button className="btn btn-secondary" onClick={toggleModal}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={toggleDetailScoreModal}
          >
            Start
          </button>
        </div>
      </ModalFooter>

      {isScoreDetailModal && (
        <MultiMonthScorecardDetailModal
          showModal={isScoreDetailModal}
          toggleModal={toggleDetailScoreModal}
          dateRange={dateRange}
        />
      )}
    </Modal>
  );
};

export default MultiMonthScorecardModal;
