import React, { useState } from "react";
import { isNullOrEmpty } from "../../../utils";
import { FormatDate } from "../../../utils/dateFormat";

const Currentcommitmenets = ({
  currentCommitmentsInfo,
  toggleCoachingDetailsModal,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState({});
  const handleTooltipHover = (id) => {
    setTooltipVisible((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };
  const arrayForSort = [...(currentCommitmentsInfo || [])];
  const agentCurrentCommitments = arrayForSort.sort((a, b) => {
    if (a.bublecolor < b.bublecolor) return 1;
    if (a.bublecolor > b.bublecolor) return -1;
    if (a.coacheddate < b.coacheddate) return 1;
    if (a.coacheddate > b.coacheddate) return -1;
    return 0;
  });
  return (
    <div className="commitment_table" id="currentcommitmenets">
      {agentCurrentCommitments?.map((item, index) => {
        let percentPix =
          item.currentprogressinpercentage == 100 ? "-11px" : "-1px";
        return (
          <div
            key={item.metricname + index}
            className="below_commitment new_row"
            onClick={() => toggleCoachingDetailsModal(item)}
            style={{
              cursor: "pointer",
            }}
          >
            <div className="d-flex space-between align-center">
              <div className="coach_metricname w-25 pd-15">
                <a
                  // onClick={() => toggleCoachingDetailsModal(item)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {item.metricname}
                </a>
              </div>
              <div className="commitment_progress w-60 pd-15">
                {/* Actual */}
                <div className="d-flex space-between align-center pb-7">
                  <div className="commit_actual">
                    {item.actualoncoacheddate}
                  </div>
                  <div className="commit_actual">{item.commitment}</div>
                </div>
                {/* commitment flow */}
                <div className="commitment_flow position-relative w-100">
                  <div className="start-date-bubble">
                    <div
                      className="tool"
                      onMouseEnter={() =>
                        handleTooltipHover(`${item.ncoachingid}1`)
                      }
                      onMouseLeave={() =>
                        handleTooltipHover(`${item.ncoachingid}1`)
                      }
                    >
                      <div
                        className={`commit-tooltip ${
                          tooltipVisible[`${item.ncoachingid}1`] && "block"
                        }`}
                        style={{
                          // top: "30px",
                          zIndex: "2",
                        }}
                      >
                        <ul>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>ID</div>
                              <div className="val">
                                <b>{item.ncoachingid}</b>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>Date</div>
                              <div className="val">
                                {FormatDate(item.coacheddate)}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>Coach</div>
                              <div className="val">
                                <b>{item.coachedby}</b>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="active_period_line"
                    style={{ width: `${item.currentprogressinpercentage}%` }}
                  ></div>
                  <div
                    className="current_bubble"
                    style={{
                      left: `${item.currentprogressinpercentage}%`,
                      marginLeft: percentPix,
                      backgroundColor: item.bublecolor
                        ? item.bublecolor
                        : "gray",
                    }}
                  >
                    <div
                      className="tool"
                      onMouseEnter={() =>
                        handleTooltipHover(`${item.ncoachingid}2`)
                      }
                      onMouseLeave={() =>
                        handleTooltipHover(`${item.ncoachingid}2`)
                      }
                    >
                      <div
                        className={`commit-tooltip ${
                          tooltipVisible[`${item.ncoachingid}2`] && "block"
                        }`}
                        style={{
                          // top: "30px",
                          right: "0px",
                        }}
                      >
                        <ul>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>Actual</div>
                              <div className="val">
                                <b>{item.currentactual}</b>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>Date</div>
                              <div className="val">
                                {FormatDate(item.currentactualdate)}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="end_date_bubble">
                    <div
                      className="tool"
                      onMouseEnter={() =>
                        handleTooltipHover(`${item.ncoachingid}3`)
                      }
                      onMouseLeave={() =>
                        handleTooltipHover(`${item.ncoachingid}3`)
                      }
                    >
                      <div
                        className={`commit-tooltip ${
                          tooltipVisible[`${item.ncoachingid}3`] && "block"
                        }`}
                        style={
                          {
                            // top: "30px",
                          }
                        }
                      >
                        <ul>
                          <li>
                            <div className="d-flex space-between align-center">
                              <div>Committment</div>
                              <div className="val">
                                <b>{item.commitment}</b>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div
                              className="d-flex space-between align-center"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              <div>Follow Up Date</div>
                              <div className="val">
                                {FormatDate(item.commitdate)}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="commit_dates d-flex space-between align-center">
                  <div className="commit_start_date">
                    <b>{FormatDate(item.coacheddate)}</b>
                  </div>
                  <div className="commit_end_date">
                    <b>{FormatDate(item.commitdate)}</b>
                  </div>
                </div>
              </div>

              <div className="actual w-15 pd-15 text-center">
                {item.datespanend == null || item.datespanend == 0
                  ? "Today"
                  : item.datespanend == 1
                  ? item.datespanend.toString() + " Day"
                  : item.datespanend.toString() + " Days"}
              </div>
            </div>
            {!isNullOrEmpty(item.bublecolor) &&
              item.bublecolor.toLowerCase() === "red" && (
                <div
                  className="trending_below_commitment d-flex space-between align-center"
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleCoachingDetailsModal(item);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex flex-start align-center">
                    <div className="trending">
                      <img src="images/icon_outline.svg" />
                    </div>
                    <div className="red"> Trending below Commitment</div>
                    <div>Review Coaching Details</div>
                  </div>
                  <div>
                    <img
                      onClick={() => toggleCoachingDetailsModal(item)}
                      src="images/arrow-right.svg"
                    />
                  </div>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default Currentcommitmenets;
