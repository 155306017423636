import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { getFirstChar, isNullOrEmpty } from "../../utils";

const AIML_FileCount = 20;
const AIMLMessageModal = ({
  showModal,
  toggleModal,
  aimlMessagesData,
  agentDetailInfo,
}) => {
  const [randomFile, setRandomFile] = useState("");
  useEffect(() => {
    if (AIML_FileCount > 0) {
      const randomIndex = Math.floor(Math.random() * AIML_FileCount);
      const twoDigitIndex = String(randomIndex + 1).padStart(2, "0");
      const randomFile = `images/AIML_BackgroundImages/-positive-messages-${twoDigitIndex}.jpg`;
      setRandomFile(randomFile);
    }
  }, []);
  const { loginmessages, date, createddatetime, gifblob } =
    aimlMessagesData || {};
  const {
    firstName,
    lastName,
    avataruri,
    title,
    backgroundmediaurl,
    profileimage,
  } = agentDetailInfo || {};
  const closeBtn = (
    <div className="links_right">
      {/* <span className="bold" id="dashboard-date">
        {FormatDateFormat(createddatetime, "DD-MMM-YYYY")}
      </span> */}
      <div className="d-flex flex-column">
        <button
          className="modal_close bg-transparent"
          onClick={toggleModal}
          type="button"
        >
          <img src="images/cross.svg" />
        </button>
        <span className="bold" id="dashboard-date">
          {FormatDate(new Date())}
        </span>
      </div>
    </div>
  );
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      id="AIMLPopup"
      style={{
        width: "550px",
      }}
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal"
      >
        <div className="modal-title">
          <div className="title_name" id="execagentalpha">
            {!isNullOrEmpty(profileimage) ? (
              <img
                src={`data:image/gif;base64,${profileimage}`}
                alt="Profile"
              />
            ) : !isNullOrEmpty(profileimage) ? (
              <img src={avataruri} alt="Profile" />
            ) : (
              <div className="">
                {getFirstChar(firstName)} {getFirstChar(lastName)}
              </div>
            )}
          </div>
          <div>
            <div id="execagentfullname">
              <strong>
                {firstName} {lastName}
              </strong>
            </div>
            <div id="execagenttitle">{title}</div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="" className="text-center">
        <div className="row">
          <div className="col-md-12">
            {randomFile && (
              <img
                src={randomFile}
                id="randomImage"
                className="modal-image"
                alt="Random Background"
              />
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="recognition-text" id="aimlmessage">
          {loginmessages}
        </div>
        <button
          type="button"
          className="dash-button btn-success"
          onClick={toggleModal}
        >
          Go to my dashboard
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default AIMLMessageModal;
