import React from "react";

const TimeFrameFooter = ({ applyAction, cancelAction, className, disable }) => {
  return (
    <div className={`${className} save_footer`}>
      <div>
        <button className="btn btn-cancel" onClick={cancelAction}>
          Cancel
        </button>
      </div>
      <div>
        <button
          className="btn btn-primary"
          onClick={applyAction}
          disabled={disable}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default TimeFrameFooter;
