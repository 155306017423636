import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CustomTooltip from "../../customComponents/customTooltip";
import CustomDataTableBase from "../../customComponents/customTable";
import ReactDataTables from "../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../utils/dateFormat";
import { DataTableLoader } from "../../utils/Loader";
import { LeaderBoardGameModal } from "../modals";

const Badge = (props) => {
  const [badgeList, setBadgeList] = useState([]);
  const [clickedRow, setClickedRow] = useState({});
  const [isModal, setModal] = useState(false);

  const { agentGameInfo, isFetching } = useSelector(
    ({ get_agent_game_table: { data: agentGameData, isFetching } }) => {
      return {
        agentGameInfo: agentGameData || [],
        isFetching: isFetching,
      };
    },
    shallowEqual
  );
  const toggleLeaderBoardGameModal = (rowItem) => {
    setClickedRow(rowItem);
    setModal((pre) => !pre);
  };
  useEffect(() => {
    if (agentGameInfo?.length > 0) {
      setBadgeList(agentGameInfo);
    }
  }, [agentGameInfo]);
  const columns = [
    {
      title: "badge",
      data: "badge",
      sortable: false,
      render: function (data, type, row) {
        return `<img height="30px" width="30px" src=${data} />`;
      },
    },
    {
      title: "description",
      data: "gamename",
      sortable: false,
      render: function (data, type, row) {
        return `<span class="table-navigation-action">${data}</span>`;
      },
      onClick: (rowData, colIndex) =>
        toggleLeaderBoardGameModal(rowData, colIndex),
    },
    {
      title: "Metric name",
      data: "metricname",
      sortable: false,
    },
    {
      title: "date",
      data: "badgedate",
      sortable: false,
      render: (data, type, row) => {
        return `<span class="d-none">${FormatDateFormat(
          data,
          "YYYYMMDD"
        )}</span> ${FormatDate(data)}`;
      },
    },
    {
      title: "category",
      data: "badgetype",
      sortable: false,
    },
    {
      title: "from",
      data: "createdfrom",
      sortable: false,
    },
  ];

  return (
    <div className="col-md-12">
      <div className="collapse show" id="badges_collapse">
        <div className="card">
          <div className="card-header">
            <div className="d-flex space-between align-center">
              <div className="badge_card_header">
                <div>Badges And Achievements</div>
                <CustomTooltip
                  tooltipText="?"
                  tooltipDesc="List of all the Badges and Achievements you have earned"
                />
              </div>
              <div className="btn-badge-close">
                <button
                  className="btn btn-bordered"
                  onClick={() => {
                    props.toggleBadge("collapseExample");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="badge_table" id="badge_acheivement_table">
              {isFetching ? (
                <div className="text-center">
                  <DataTableLoader />
                </div>
              ) : (
                <ReactDataTables
                  columns={columns}
                  data={badgeList}
                  defaultSort={{ columnIndex: 3, direction: "desc" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <LeaderBoardGameModal
          clickedRow={clickedRow}
          showModal={isModal}
          toggleModal={toggleLeaderBoardGameModal}
        />
      )}
    </div>
  );
};
export default Badge;
