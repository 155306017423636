import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  FormatDate,
  FormatDateMMdd,
  FormatDateFormat,
} from "../../utils/dateFormat";
import CustomTooltip from "../../customComponents/customTooltip";
import { TrendChart } from "../partialScreens";
import {
  getmultimonthScoreCardPopup,
  getmultimonthscorecardemployeedetails,
} from "../../store/actions";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";

const MultiMonthScorecardDetailModal = ({
  showModal,
  toggleModal,
  dateRange,
}) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, multiMonthPopupInfo, multiMonthEmpInfo } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },
        get_multi_month_scorecard_dim_work_weeks: {
          data: multiMonthDimWorkData,
        },
        get_multi_month_score_card_popup: { data: multiMonthPopupData },
        get_multi_month_score_card_employee_details: {
          data: multiMonthEmpData,
        },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          dimworkweeksmultimonth: multiMonthDimWorkData || [],
          multiMonthPopupInfo: multiMonthPopupData || [],
          multiMonthEmpInfo: multiMonthEmpData || [],
        };
      },
      shallowEqual
    );
  useEffect(() => {
    dispatch(
      getmultimonthScoreCardPopup({
        Employeeid: activeOrganizationInfo?.Loginid,
        FromDate: dateRange?.multimonthscorecardfromdate,
        ToDate: dateRange?.multimonthscorecardtodate,
      })
    );
    dispatch(
      getmultimonthscorecardemployeedetails({
        Employeeid: activeOrganizationInfo?.Loginid,
        FromDate: dateRange?.multimonthscorecardfromdate,
        ToDate: dateRange?.multimonthscorecardtodate,
      })
    );
  }, []);

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      id="monthly_scorecard_weights"
    >
      <ModalHeader
        toggle={toggleModal}
        // close={closeBtn}
        className="custom-modal p-0"
      >
        <div className="top_module">
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="modal_top">Scorecards</div>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="multimonthscorecardpopup" className="p-0">
        <div className="row px-2 pt-2">
          <div className="col-md-3">
            <div className="agent_scorecard_details">
              <h3>Agent Information</h3>
              {multiMonthEmpInfo?.map((item, index) => {
                return (
                  <div className="agent_details_block">
                    <div className="mb-1">
                      <div className="agent_name">Agent Name</div>
                      <div className="agent_detail">{item.employeeName}</div>
                    </div>
                    <div className="mb-1">
                      <div className="agent_name">Agent ID</div>
                      <div className="agent_detail">{item.employeeID}</div>
                    </div>
                    <div className="mb-1">
                      <div className="agent_name">Agent Program</div>
                      <div className="agent_detail">{item.program}</div>
                    </div>
                    <div className="mb-1">
                      <div className="agent_name">Agent Hire Date</div>
                      <div className="agent_detail">
                        {FormatDate(item.hireDate)}
                      </div>
                    </div>
                    <div className="mb-1">
                      <div className="agent_name">Agent Location</div>
                      <div className="agent_detail">{item.location}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <div className="right_block_top_module">
              <div className="">
                <div className="mt-5">
                  <div className="monthly_scorecard">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>Monthly Weighted Scorecards</th>
                        </tr>
                      </thead>
                      <tbody>
                        {multiMonthPopupInfo?.map((item, index) => {
                          return (
                            <tr
                              className={
                                item.isEditable
                                  ? "monthly_weight_status_bg"
                                  : ""
                              }
                            >
                              <td className="exportmonths">
                                {FormatDateFormat(item.monthyear, "MMM YYYY")}
                              </td>
                              <td className="balancescorecardweights">
                                {item.balanceScore}
                              </td>
                            </tr>
                          );
                        })}
                        <tr className="border-top">
                          <td>
                            <b>Overall</b>
                          </td>
                          <td>
                            <b id="overallweightscore"></b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <div className="scorecard_text_note_table">
                        All scores are based on balanced scorecard point values.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </ModalBody>
      <ModalFooter className="p-0">
        <div className="col-sm-12 pull-right text-end">
          <button className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>
          {/* <button type="button" className="btn btn-primary">
            Export
          </button> */}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MultiMonthScorecardDetailModal;
