import React from "react";

function RingSVG({ radius, percentage, color, progressCls, strokeArray }) {
  const style =
    percentage >= 100
      ? { stroke: color, strokeDashoffset: 0 }
      : percentage >= 0 && percentage < 100
      ? { stroke: color, strokeDashoffset: `${100 - percentage}` }
      : { stroke: "transparent", strokeDashoffset: 100 };
  return (
    <svg className="ring" viewBox="0 0 36 36">
      <circle className="ring-bg" cx="18" cy="18" r={radius}></circle>
      <circle
        className={`ring-progress ${progressCls}`}
        cx="18"
        cy="18"
        r={radius}
        style={{
          strokeDasharray: strokeArray,
          ...style,
        }}
      ></circle>
    </svg>
  );
}

const ScorecardCircle = ({ rangeData, balancescorecardvalue }) => {
  let sortedItems = [...(rangeData || [])].sort(
    (a, b) => b.startrange - a.startrange
  );
  const number =
    balancescorecardvalue !== null ? parseFloat(balancescorecardvalue) : null;

  return (
    <>
      <div className="activity-tracker">
        {sortedItems?.map((item, index) => {
          const lowerBound = parseFloat(item.startrange);
          const upperBound = parseFloat(item.endrange);
          // if (isNaN(lowerBound) || isNaN(upperBound) || number === null) {
          //   return null;
          // }
          const range = upperBound - lowerBound;
          const distanceFromLowerBound = number - lowerBound;

          const percentage =
            range > 0 ? Math.round((distanceFromLowerBound / range) * 100) : 0;
          let strokeDasharray;
          let radius;
          const viewBox = 36;
          let classSuffix;
          switch (index + 1) {
            case 1:
              radius = 16;
              strokeDasharray = 2 * Math.PI * radius;
              classSuffix = "high-5";
              break;
            case 2:
              radius = 12.5;
              strokeDasharray = 2 * Math.PI * radius;
              classSuffix = "high-4";
              break;
            case 3:
              radius = 9;
              strokeDasharray = 2 * Math.PI * radius;
              classSuffix = "mid-3";
              break;
            case 4:
              radius = 5.5;
              strokeDasharray = 2 * Math.PI * radius;
              classSuffix = "low-2";
              break;
            case 5:
              radius = 2;
              strokeDasharray = 2 * Math.PI * radius;
              classSuffix = "low-1";
              break;
            default:
              radius = 0;
              strokeDasharray = 0;
              classSuffix = "";
              break;
          }
          const cappedPercentage = Math.min(Math.max(percentage, 0), 100);
          let strokeDashoffset = strokeDasharray * (1 - cappedPercentage / 100);
          return (
            <svg
              key={index}
              className="ring"
              viewBox={`0 0 ${viewBox} ${viewBox}`}
            >
              <circle className="ring-bg" cx="18" cy="18" r={radius}></circle>
              <circle
                className={`ring-progress ${classSuffix}`}
                cx="18"
                cy="18"
                r={radius}
                style={{
                  strokeDasharray: strokeDasharray,
                  stroke: item.colorcode,
                  strokeDashoffset: strokeDashoffset,
                }}
              ></circle>
            </svg>
          );
        })}
      </div>
      {/* tooltip */}
      <div className="scorecard_range_tooltip">
        <ul>
          {rangeData?.map((item, index) => {
            const { colorcode, balscoretitle, startrange, endrange } = item;
            return (
              <li key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center">
                    <div
                      className="scorerange_color"
                      // style="background-color: @item.colorcode;"
                      style={{
                        backgroundColor: colorcode,
                      }}
                    ></div>
                    <div className="scorerangetext">{balscoretitle}</div>
                  </div>
                  <div className="scorerangevalue">
                    {startrange} - {endrange}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ScorecardCircle;
