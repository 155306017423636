import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomTooltip from "../../customComponents/customTooltip";
import { isNullOrEmpty } from "../../utils";
import { FormatDate } from "../../utils/dateFormat";
import { ParseHtml } from "../../utils/parseHtml";
import {
  getexecagentactivityhistoryview,
  getActivityHistory,
} from "../../store/actions";
import {
  CoachingScreen,
  TaskFollowUpScreen,
  EvaluationScreen,
} from "../partialScreens/ActivityDetails";

const ActivityDetailsModal = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const {
    activeOrganizationInfo,
    activityHistoryInfo,
    activityHistoryAllInfo,
    isFetchingCoaching,
    isFetching,
  } = useSelector(
    ({
      get_active_organization_detail: { data: activeOrganizationData },
      get_exec_agent_activity_history_view: {
        data: activityHistoryData,
        isFetching: isFetching,
      },
      get_activity_history: {
        data: activityHistoryAllData,
        isFetchingCoaching,
      },
    }) => {
      return {
        activeOrganizationInfo: activeOrganizationData || {},
        activityHistoryInfo: activityHistoryData,
        activityHistoryAllInfo: activityHistoryAllData,
        isFetching: isFetching,
        isFetchingCoaching: isFetchingCoaching,
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (clickedRow?.actionmodule == "Coaching") {
      dispatch(
        getexecagentactivityhistoryview({
          companyguid: activeOrganizationInfo?.Companyguid,
          id: clickedRow?.activityid,
          accessloginid: activeOrganizationInfo?.Loginid,
        })
      );
    } else {
      let formid = null;
      if (clickedRow?.hyperlink && clickedRow?.actionmodule == "evaluation") {
        const params = new URLSearchParams(clickedRow?.hyperlink.split("?")[1]);
        formid = params.get("formid");
      }
      dispatch(
        getActivityHistory({
          companyguid: activeOrganizationInfo?.Companyguid,
          organization: activeOrganizationInfo?.Organization,
          primaryprogram: activeOrganizationInfo?.Program,
          loginid: activeOrganizationInfo?.Loginid,
          username: activeOrganizationInfo?.UserName,
          employeeid: activeOrganizationInfo?.Loginid,
          taskType: "Coach",
          activityid: clickedRow?.activityid,
          actionmodule: clickedRow?.actionmodule,
          taskEvaluationCoachingid: clickedRow?.id,
          GameId: clickedRow?.id,
          date: clickedRow?.date,
          varcharemployee: activeOrganizationInfo?.Loginid,
          titlerole: "L1",
          evaluatedto: activeOrganizationInfo?.Loginid,
          evaluationid: clickedRow?.id,
          formid: formid,
        })
      );
    }
  }, []);

  const PropsConfig = {
    toggleModal: toggleModal,
    actionModule: clickedRow?.actionmodule,
    activeOrganizationInfo: activeOrganizationInfo,
    activityHistoryAllInfo: activityHistoryAllInfo,
    isFetching: isFetching || isFetchingCoaching,
  };

  const modalScreens = {
    Coaching: (
      <CoachingScreen
        {...PropsConfig}
        activityHistoryInfo={activityHistoryInfo}
      />
    ),
    "Task & Follow Up": <TaskFollowUpScreen {...PropsConfig} />,
    "Task Recognition": <TaskFollowUpScreen {...PropsConfig} />,
    CTC: <CoachingScreen {...PropsConfig} />,
    evaluation_qa: <EvaluationScreen {...PropsConfig} />,
    evaluation: <EvaluationScreen {...PropsConfig} />,
    Agent_Dashboard: <CoachingScreen {...PropsConfig} />,
    Game: <CoachingScreen {...PropsConfig} />,
    // 4: <CoachingScreen {...PropsConfig} />,
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      //   className={className}
      backdrop={true}
      keyboard={true}
      id="viewcoach"
      style={{
        maxWidth: "90%",
      }}
    >
      {modalScreens[clickedRow?.actionmodule]}
    </Modal>
  );
};
export default ActivityDetailsModal;
