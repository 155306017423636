import { GetOrdinal, isNullOrEmpty } from "../../utils";

const TeamProgramRank = ({ filterByMetEmpData }) => {
  //   if (filterByMetEmpData.length === 0) return null;

  const { team_rank, agentRankCount, program_rank, programRankCount } =
    filterByMetEmpData[0] || {};

  return (
    <div className="team_program_rank">
      <div className="team_rank_tile">
        <div>Team Rank</div>
        <div className="teamrank">
          <b>
            {!isNullOrEmpty(team_rank) ? GetOrdinal(parseInt(team_rank)) : "0"}{" "}
          </b>
          of {agentRankCount}
        </div>
      </div>
      <div className="program_rank_tile">
        <div>Program Rank</div>
        <div className="teamrank">
          <b>
            {!isNullOrEmpty(program_rank)
              ? GetOrdinal(parseInt(program_rank))
              : "0"}{" "}
          </b>
          of {programRankCount}
        </div>
      </div>
    </div>
  );
};

export default TeamProgramRank;
