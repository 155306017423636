import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  discoverFAQ,
  discover24FAQ,
  GoDaddyFAQ,
  IterumConnectionsFAQ,
  Omnicare365FAQ,
  SSGFAQ,
  CenturylinkFAQ,
  collearlystageFAQ,
  collmanualdialFAQ,
  collportfolioFAQ,
  collermsFAQ,
  MetrobyTMobileFAQ,
} from "../../utils/FAQ";
import CompanyGUIDs, { currentCompanyGuid } from "../../utils/GUIDConst";

const getFAQPath = (companyGuid, organization, program) => {
  organization = organization?.toLowerCase().trim();
  program = program?.toLowerCase().trim();

  switch (companyGuid) {
    case CompanyGUIDs["Go Daddy"]:
      return GoDaddyFAQ;
    case CompanyGUIDs["Iterum Connections"]:
      return IterumConnectionsFAQ;
    case CompanyGUIDs["Discover"]:
      return organization === "customer service and engagement"
        ? discover24FAQ
        : discoverFAQ;
    case CompanyGUIDs["Omnicare365"]:
      return organization === "united health group"
        ? Omnicare365FAQ
        : undefined;
    case CompanyGUIDs["SSG"]:
      return organization === "bissell" &&
        ["aus phone", "aus email", "deep cleaner phone"].includes(program)
        ? SSGFAQ
        : undefined;
    case CompanyGUIDs["Centurylink"]:
      return organization === "allied" && program === "service"
        ? CenturylinkFAQ
        : undefined;
    case CompanyGUIDs["Premier Bank"]:
      if (organization === "premier bankcard") {
        const perfProgram = program?.replace(/-/g, "").replace(/\s/g, "");
        const premierLookup = {
          collearlystage: collearlystageFAQ,
          collmanualdial: collmanualdialFAQ,
          collportfolio: collportfolioFAQ,
          collerms: collermsFAQ,
        };
        return premierLookup[perfProgram];
      }
      break;
    case CompanyGUIDs["Metro by T-Mobile"]:
      if (organization === "csr" || organization === "specialty") {
        if (["csr", "trbl", "cat"].includes(program)) {
          return MetrobyTMobileFAQ;
        }
      }
      break;
    default:
      return undefined;
  }
};

const ViewDetailsFAQ = ({ isButton }) => {
  const activeOrganizationInfo = useSelector(
    (state) => state.get_active_organization_detail.data || {},
    shallowEqual
  );
  const docPath = useMemo(() => {
    return getFAQPath(
      currentCompanyGuid,
      activeOrganizationInfo?.Organization,
      activeOrganizationInfo?.Program
    );
  }, [activeOrganizationInfo, currentCompanyGuid]);

  if (!docPath) {
    return null;
  }

  if (docPath)
    return isButton ? (
      <a href={docPath} target="_blank" rel="noopener noreferrer">
        <span className="btn btn-bordered view-details-FAQ"> View Details</span>
      </a>
    ) : (
      <li className="cursor-default">
        <a href={docPath} target="_blank" rel="noopener noreferrer">
          View Details
        </a>
      </li>
    );
};

export default React.memo(ViewDetailsFAQ);
