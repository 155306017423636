import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const MetricTrendChart = ({ clickedRow, metricData, char_hei }) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (metricData && metricData.length !== 0) {
      const months = [];
      const goals = [];
      const goalLabels = [];
      const actuals = [];
      const actualLabels = [];
      const tooltips = {};

      metricData.forEach((item) => {
        months.push(item.displayname);
        goals.push(parseFloat(item.goal));
        goalLabels.push(item.goaldatalable);
        actuals.push(parseFloat(item.actual));
        actualLabels.push(item.actualdatalable);
        tooltips[item.displayname] = item.tooltip;
      });
      setChartOptions({
        xAxis: {
          categories: months,
          startOnTick: true,
          endOnTick: true,
          gridLineWidth: 0,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },
        legend: {
          align: "right",
          x: 0,
          verticalAlign: "top",
          y: -15,
          floating: false,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.background2) || "white",
          borderColor: "white",
          borderWidth: 1,
          shadow: false,
        },
        chart: {
          type: "line",
          height: char_hei,
        },
        title: {
          text: "",
        },
        tooltip: {
          formatter: function () {
            return this.series.name === "Goal" ? false : tooltips[this.x];
          },
        },
        series: [
          {
            name: clickedRow?.metricname,
            color: "black",
            data: actuals,
            zIndex: 2,
          },
          {
            name: "Goal",
            lineWidth: 3,
            dashStyle: "ShortDash",
            color: "#6ccc76",
            data: goals,
            zIndex: 1,
          },
        ],
        credits: {
          enabled: false,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        legend: {
          enabled: true,
        },
        exportButton: {
          enabled: false,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.series.name === "Goal"
                  ? goalLabels[months.indexOf(this.x)]
                  : actualLabels[months.indexOf(this.x)];
              },
              color: "black",
              style: {
                fontWeight: "normal",
              },
            },
            enableMouseTracking: true,
          },
        },
      });
    }
  }, [metricData, char_hei, clickedRow]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default MetricTrendChart;
