import React from "react";
import { FormatDate } from "../../../utils/dateFormat";
const GoalAction = ({ rowItem, toggleLeaderboardModal, isRankEnabled }) => {
  const {
    metricname,
    metricid,
    team_rank,
    goal,
    actual,
    program_rank,
    teamAverage,
    programAverage,
    metrictype,
    programmetriccolor,
    prgramprogress,
    teammetriccolor,
    teamprogressbar,
    metriccolor,
    agentprogress,
    showcoachnow,
    lastupdateddate,
    programRankCount,
    agentRankCount,
  } = rowItem || {};
  return (
    <div className="d-flex space-between align-center" key={metricid}>
      <div
        className="actual_goal"
        onClick={() => toggleLeaderboardModal(rowItem)}
      >
        <div className="actual">
          <strong>{actual}</strong>
        </div>
        <span className="goal">{goal}</span>
      </div>
      <div className="updated_through position-relative">
        <div>
          <img src="images/more-options-black.svg" />
        </div>
        <div className="updated_through_dropdown">
          <ul>
            <li>
              <div className="gray-text">
                Updated through {FormatDate(lastupdateddate)}
              </div>
            </li>
            {isRankEnabled && (
              <>
                <li>
                  <div className="d-flex space-between align-items-start">
                    <div className="gray-text">Team Rank</div>
                    <div className="block text-right">
                      <div>
                        <b>{team_rank}</b>
                      </div>
                      <div className="gray-text">of {agentRankCount}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex space-between align-items-start">
                    <div className="gray-text">Program Rank</div>
                    <div className="block text-right">
                      <div>
                        <b>{program_rank}</b>
                      </div>
                      <div className="gray-text">of {programRankCount}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ledaer d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleLeaderboardModal(rowItem, true);
                      }}
                    >
                      Leaderboard
                    </button>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GoalAction;
