import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getAgentInsentiveTables } from "../../../store/actions";
import CompanyGUIDs, { currentCompanyGuid } from "../../../utils/GUIDConst";
import { RepRankingLegendFAQ } from "../../../utils/FAQ";

const AgentScheduleModal = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();
  const { activeOrganizationInfo, agentInsentiveInfo, isFetching } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },
        get_agent_insentive_tables: { data: agentInsentiveData, isFetching },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          agentInsentiveInfo: agentInsentiveData,
          isFetching: isFetching,
        };
      },
      shallowEqual
    );
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  const groupByMonthYear = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.monthYear]) {
        acc[item.monthYear] = [];
      }
      acc[item.monthYear].push(item);
      return acc;
    }, {});
  };
  useEffect(() => {
    dispatch(
      getAgentInsentiveTables({
        companyguid: activeOrganizationInfo?.Companyguid,
        organization: activeOrganizationInfo?.Organization,
        program: activeOrganizationInfo?.Program,
        employeeid: activeOrganizationInfo?.Employeeid,
        IncentiveTileBox: clickedRow?.title,
      })
    );
  }, [dispatch]);
  const sortedHeader = [...(agentInsentiveInfo?.incentiveHeaders || [])].sort(
    (a, b) => a.sortorder - b.sortorder
  );
  const groupedData = agentInsentiveInfo?.incentiveBody
    ? groupByMonthYear(agentInsentiveInfo.incentiveBody)
    : [];

  const sortedKeys = Object.keys(groupedData).sort(
    (a, b) => new Date(`01-${b}`) - new Date(`01-${a}`)
  );

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      // centered={true}
      external={closeBtn}
      id="agent_insentive_modal"
    >
      <ModalHeader
        // toggle={toggleModal}
        // close={closeBtn}
        className="custom-modal metric_header"
      >
        <div className="flex space-between align-center">
          <div className="inc_hd">{clickedRow?.title}</div>
          <div>
            {currentCompanyGuid === CompanyGUIDs["Premier Bank"] && (
              <a
                class="view_details"
                href={RepRankingLegendFAQ}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Details
              </a>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody id="">
        <table
          className="table table-bordered incentive1 tiles-table"
          style={{
            fontWeight: "normal",
            marginBottom: "0px",
            border: "1px solid rgb(0 0 0 / 42%)",
          }}
          id="inc_table"
        >
          <thead>
            <tr>
              {sortedHeader?.map((hitem) => (
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  {hitem.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedKeys?.map((monthYear, index) => (
              <tr key={index}>
                <td>{monthYear}</td>
                {groupedData[monthYear]
                  .sort((a, b) => a.sortorder - b.sortorder)
                  .map((item, subIndex) => (
                    <td key={subIndex} style={{ textAlign: "center" }}>
                      {item.metricvalue}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
        {currentCompanyGuid === CompanyGUIDs["Go Daddy"] && (
          <div className="col-md-12">
            <h3
              className="dis"
              style={{
                fontSize: "14px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <span className="disclaim">Disclaimer:</span>The value is an
              indication of the amount you might receive; however this could be
              higher or lower, subject to the scheme terms and conditions.
            </h3>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default AgentScheduleModal;
