import React, { useEffect, useContext } from "react";
import {
  TimeFrameBlock,
  AgentProfileInfo,
  MetricCommitment,
  CoachingHistory,
  ActivityHistory,
  Quadrant3cards,
} from "./components";

const Dashboard = () => {
  return (
    <div className="l1-dashboard">
      <div className="time_frame_block">
        <TimeFrameBlock />
        <div className="d-flex justify-content-between">
          <div className="left_dashboard">
            <AgentProfileInfo />

            <div className="metric-section">
              <MetricCommitment />
            </div>
            <CoachingHistory />
            <ActivityHistory />
          </div>
          <div className="right_dashboard">
            <Quadrant3cards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
