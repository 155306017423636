import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import withInitialData from "./withInitialData";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/css/topbar.css";
import "./assets/css/style.css";
import "./assets/css/style.min.css";
import "./assets/css/style_cu.css";
import "./assets/css/modifiedagentdb.css";
import "./assets/css/timeframe.css";
import "./assets/css/execagentdashboard.css";
import "./assets/css/quadrant3card.css";
import "./index.css";
const AppWithInitialData = withInitialData(App);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<div className="loading" />}>
    <HashRouter>
      <Provider store={store}>
        <AppWithInitialData />
      </Provider>
    </HashRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
