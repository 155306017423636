import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AudioPlayModal = ({ clickedRow, showModal, toggleModal }) => {
  const closeBtn = (
    <div className="links_right">
      <button className="modal_close" onClick={toggleModal} type="button">
        <img src="images/close.svg" />
      </button>
    </div>
  );
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      centered={true}
      id="audio_modal"
      style={{
        width: "450px",
      }}
    >
      <ModalHeader
        toggle={toggleModal}
        close={closeBtn}
        className="custom-modal metric_header"
      ></ModalHeader>
      <ModalBody id="" className="text-center">
        <div className="row">
          <div className="col-md-12">
            <div className="coaching_audio">
              <audio
                controls
                controlsList="nodownload"
                style={{
                  fontSize: "10px",
                }}
              >
                <source
                  id="setaudiofilepath"
                  src={clickedRow?.recordpath}
                  type="audio/mp3"
                />
              </audio>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AudioPlayModal;
