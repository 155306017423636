import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactDataTables from "../../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../../utils/dateFormat";

const PayoutHistoryDetails = ({ rowData, showModal, toggleModal }) => {
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  const columns = [
    {
      title: "month",
      data: "month",
    },
    {
      title: "year",
      data: "year",
    },
    {
      title: "ancillaries",
      data: "ancillaries",
    },
    {
      title: "cox_HOME_LIFE",
      data: "cox_HOME_LIFE",
    },
    {
      title: "Cox Mobile",
      data: "mobile_Lead",
    },
    {
      title: "Payout",
      data: "payout",
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      external={closeBtn}
      id="CxPBRankingDetails_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="">
        <div class="row">
          <div
            class="col-md-12"
            style={{
              padding: "15px 30px",
            }}
          >
            <ReactDataTables
              data={rowData}
              columns={columns}
              defaultSort={{ columnIndex: 0, direction: "desc" }}
              id="summary"
              paging={false}
              scrollY="75vh"
              fixedHeader={{
                header: false,
                footer: true,
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PayoutHistoryDetails;
