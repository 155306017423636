import React, { useEffect, useState, useRef } from "react";
import ReactDataTables from "../../customComponents/ReactDataTables";
import CustomDataTableBase from "../../customComponents/customTable";

const MetricDetailsListView = ({
  clickedRow,
  metricdetailspopupListview,
  metricdetailspopupDetailsview,
}) => {
  const COlName = metricdetailspopupListview?.[0] || {};
  const filteredList = metricdetailspopupListview?.filter(
    (x) => parseFloat(x.Calls) !== 0
  );
  const columns = [
    {
      title: "Contact type",
      data: "queName",
      render: function (data, type, row) {
        return `<span class="text-left">${data}</span>`;
      },
    },
    {
      title: COlName.callsDisplayName || "activity count",
      data: "calls",
      render: (data, type, row) => parseFloat(data),
    },
    {
      title: COlName.actualDisplayName || "actual",
      data: "actual",
      render: (data, type, row) => parseFloat(data),
    },
    {
      title: COlName.goalDisplayName || "goal",
      data: "goal",
      render: (data, type, row) => parseFloat(data),
    },
    {
      title: "Priority Value",
      data: "priority_Value",
      render: (data, type, row) => parseFloat(data),
    },
    {
      title: "Coaching priority",
      data: null,
      render: (data, type, row, meta) => meta.row + 1,
    },
  ];

  return (
    <div className="">
      <ReactDataTables
        columns={columns}
        data={filteredList}
        defaultSort={{ columnIndex: 5, direction: "desc" }}
        paging={false}
      />
    </div>
  );
};

export default MetricDetailsListView;
