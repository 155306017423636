import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const IntradayMetricsModal = ({
  sortedTickerData,
  ClickedTikcer,
  showModal,
  toggleModal,
}) => {
  const [TikcerData, setTikcerData] = useState();
  const { intraDayInfo } = useSelector(
    ({ get_intra_day_agent_dashboard_ticker: { data: intraDayData } }) => {
      return {
        intraDayInfo: intraDayData || [],
      };
    },
    shallowEqual
  );
  useEffect(() => {
    if (intraDayInfo != null) {
      // Create a distinct list of metric names
      const metriclist = [
        ...new Set(
          intraDayInfo.flatMap((b) => b.metricsData.map((a) => a.metricName))
        ),
      ];
      const metricresult = metriclist?.map((item) => {
        // Find the first metric data object that matches the current metric name
        const result = intraDayInfo
          .flatMap((b) => b.metricsData)
          .find((a) => a.metricName === item);

        // Return a new agenttickermetriclist object
        return {
          metricName: item,
          sortoder: result ? result.sortoder : null,
        };
      });
      const sortedMetricList = [...metricresult].sort(
        (a, b) => a.sortoder - b.sortoder
      );

      setTikcerData(sortedMetricList);
    }
  }, [intraDayInfo]);
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      //   centered={true}
      external={closeBtn}
      id="intraday-metrics-modal"
    >
      <ModalBody id="">
        <div class="col-md-12 pd-0" id="table_scroll">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th class="intra-sticky">Metric</th>
                {sortedTickerData?.map((item, index) => (
                  <th key={index}>{item.timeofreport}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {TikcerData?.map((item, index) => (
                <tr key={index}>
                  <td className="intra-sticky">
                    <div className="intraday_metric_name">
                      {item.metricName}
                    </div>
                  </td>
                  {sortedTickerData?.map((metricItem, metricIndex) => {
                    const metricDataItem = metricItem.metricsData.find(
                      (x) => x.metricName === item.metricName
                    );
                    return (
                      <td key={metricIndex} className="">
                        <div>
                          {metricDataItem ? metricDataItem.metricValue : ""}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default IntradayMetricsModal;
