import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactDataTables from "../../../customComponents/ReactDataTables";
import { FormatDate, FormatDateFormat } from "../../../utils/dateFormat";
import { render } from "@testing-library/react";
import { generateFlname, getFirstChar } from "../../../utils";

const CxPBRankingDetails = ({
  cxPB_RankingDetails,
  showModal,
  toggleModal,
}) => {
  const closeBtn = (
    <div
      className="links_right"
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <button
        className="modal_close bg-transparent"
        onClick={toggleModal}
        type="button"
      >
        <img src="images/cross.svg" />
      </button>
    </div>
  );
  const columns = [
    {
      title: "COE",
      data: "coe",
    },
    {
      title: "Agent",
      data: "agent",
      render: (data, type, row) => {
        return `<div class="manager">
            <div class="flname">${generateFlname(data)}</div>
            <div class="pl-1" style="margin-top:6px">
              <div class="managername">${data}</div>
            </div>
          </div>`;
      },
    },
    {
      title: "Network ID",
      data: "network_ID",
    },
    {
      title: "Hire Date",
      data: "hire_Date",
      render: (data, type, row) => {
        return `<span class="d-none">${FormatDateFormat(
          data,
          "YYYYMMDD"
        )}</span> ${FormatDateFormat(data, "DD/MM/YYYY")}`;
      },
    },
    {
      title: "Supervisor",
      data: "supervisor",
    },
    {
      title: "CFT Gate",
      data: "cfT_Gate",
    },
    {
      title: "Scorecard/Months",
      data: "scorecards_Months",
    },
    {
      title: "Metric",
      data: "metric",
    },

    {
      title: "Eligibility",
      data: "eligibility",
    },
    {
      title: "Result",
      data: "result",
    },
    {
      title: "Rank",
      data: "rank",
    },
    {
      title: "Band",
      data: "band",
      render: (data, type, row) => {
        return data?.toString() == "Not Eligible" ? "N/A" : data;
      },
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      backdrop={true}
      keyboard={true}
      external={closeBtn}
      id="CxPBRankingDetails_modal"
      style={{
        maxWidth: "90%",
      }}
    >
      <ModalBody id="">
        <div class="row">
          <div
            class="col-md-12"
            style={{
              padding: "15px 30px",
            }}
          >
            <ReactDataTables
              data={cxPB_RankingDetails}
              columns={columns}
              defaultSort={{ columnIndex: 0, direction: "desc" }}
              id="summary"
              paging={false}
              scrollY="75vh"
              fixedHeader={{
                header: false,
                footer: true,
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CxPBRankingDetails;
