import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const CustomSelect = (props) => {
  const { name, value, id, label, options, onChange } = props;
  return (
    <div className="form-group w-32">
      {label && <label htmlFor={id}>{label}</label>}
      <select className="form-select" id={id} value={value} onChange={onChange}>
        {options?.map((option, optIndex) => (
          <option key={`${option}${optIndex}`} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
const CustomSelectOption = (props) => {
  const { name, id, label, options, onChange } = props;
  return (
    <FormGroup className="form-group w32">
      {label && <Label for={id}>{label}</Label>}
      <Input
        className="form-select"
        id={id}
        name={name}
        type="select"
        onChange={onChange}
      >
        {options?.map((option, optIndex) => {
          return (
            <option key={`${option}${optIndex}`} value={option}>
              {option}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};
export default CustomSelectOption;
