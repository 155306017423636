import React, { useMemo } from "react";
import { Table } from "reactstrap";
import { FormatDateStamp } from "../../../utils/dateFormat";

const getInitials = (name) => {
  const initials = name
    .split(/[, ]+/)
    .filter(Boolean)
    .map((n) => n.charAt(0).toUpperCase())
    .join("");
  return initials.length >= 2 ? initials.substring(0, 2) : "";
};

const getTaskManagerDetails = (TaskManagerList, taskSaveId) =>
  TaskManagerList.find(
    (x) => parseInt(x.taskSaveId) === parseInt(taskSaveId)
  ) || null;

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString("en-US");

const getTaskColor = (taskType) => {
  switch (taskType?.toLowerCase()) {
    case "coach":
      return "#FFE9A8";
    case "hot topic":
      return "#D7EAFF";
    case "recognize":
    case "recognition":
      return "#CAE9C6";
    case "follow up":
      return "#FECCCD";
    default:
      return "#E7EAED";
  }
};

const Profile = ({ name, title, isSystem = false }) => (
  <div className="profile d-flex">
    {isSystem ? (
      <div style={{ float: "left", paddingRight: "2px" }}>
        <img
          src="images/amplifai_logo.png"
          className="rouded-circle cus-imgs"
          alt="System"
        />
      </div>
    ) : (
      <div className="initials_hist">{getInitials(name)}</div>
    )}
    <div style={{ padding: "0px 10px 0px 0px", textAlign: "left" }}>
      <div className="profilename" style={{ fontSize: "12px" }}>
        {isSystem ? "System" : name}
      </div>
      <div className="prpfilesubtitle">{isSystem ? "Amplifai" : title}</div>
    </div>
  </div>
);

const TaskButton = ({ taskType, taskColor }) => (
  <button
    className="btn btn-primary rec"
    style={{
      backgroundColor: taskColor,
      border: `1px solid ${taskColor}`,
      color: "#25282C",
    }}
  >
    {taskType}
  </button>
);

const MyHistoryTable = ({ rowData }) => {
  const { getTaskManagerList, getTaskManagerList1, taskManagemnetDataAccess } =
    rowData || {};

  const validStatusTypes = useMemo(
    () => [
      "canceled",
      "incomplete",
      "complete",
      "completed",
      "reopen",
      "viewed",
      "expired",
    ],
    []
  );

  const filteredAndSortedTasks = useMemo(
    () =>
      taskManagemnetDataAccess
        ?.filter((item) =>
          validStatusTypes.includes(item.statustype?.toLowerCase())
        )
        .sort((a, b) => parseInt(a.taskSaveId) - parseInt(b.taskSaveId)),
    [taskManagemnetDataAccess, validStatusTypes]
  );

  const validStatusTypes1 = useMemo(
    () => ["completed", "canceled", "archived", "expired"],
    []
  );

  const filteredAndSortedTasks1 = useMemo(
    () =>
      getTaskManagerList
        ?.filter(
          (x) =>
            validStatusTypes1.includes(x.Status?.toLowerCase()) &&
            x.taskSaveId === 0 &&
            !x.isAIMLGeneratedTask
        )
        .sort((a, b) => parseInt(a.taskSaveId) - parseInt(b.taskSaveId)),
    [getTaskManagerList, validStatusTypes1]
  );

  const filteredAndSortedTasks2 = useMemo(
    () =>
      getTaskManagerList
        ?.filter(
          (x) =>
            validStatusTypes1.includes(x.Status?.toLowerCase()) &&
            x.taskSaveId === 0 &&
            x.isAIMLGeneratedTask
        )
        .sort((a, b) => parseInt(a.taskSaveId) - parseInt(b.taskSaveId)),
    [getTaskManagerList, validStatusTypes1]
  );

  const renderRows = (tasks, isSystem = false) =>
    tasks?.map((item, index) => {
      const taskColor = getTaskColor(item.taskType);
      const initialsCreatedBy = getInitials(item.createdByName);
      const initialsCreatedTo = getInitials(item.userName);
      const taskId = item.taskSaveId || item.recordid;
      const taskType = item.taskType || item.tasktype;
      const createdByName = item.createdByName;
      const createdByTitle = item.createdByTitle;
      const userName = item.userName || item.username;
      const userTitle = item.usertitle;
      return (
        <tr
          key={index}
          id={taskId}
          className={item.taskstatus === "New" ? "newStatus" : ""}
        >
          <td>{taskId}</td>
          <td>
            <Profile
              name={createdByName}
              title={createdByTitle}
              isSystem={isSystem}
            />
          </td>
          <td>
            <TaskButton taskType={taskType} taskColor={taskColor} />
          </td>
          <td>
            <Profile name={userName} title={userTitle} />
          </td>
          <td>{item.metricName || item.metricname}</td>
          <td>
            <span className="localtimeconversion_myhistory">
              {FormatDateStamp(item.createdDate)}
            </span>
          </td>
          <td>{item.taskstatus || item.status}</td>
          <td>{item.referenceId}</td>
          <td>
            {item.acknowledgeDate && taskType.toLowerCase() === "follow up"
              ? formatDate(item.acknowledgeDate)
              : null}
          </td>
        </tr>
      );
    });

  return (
    <Table
      responsive
      id="view_all_Createdtasks1"
      className="table table-bordered task-management"
    >
      <thead>
        <tr>
          <th className="cellbg">ID</th>
          <th className="cellbg">From</th>
          <th className="cellbg">Task</th>
          <th className="cellbg">User</th>
          <th className="cellbg">Metric</th>
          <th className="cellbg">Created</th>
          <th className="cellbg">Status</th>
          <th className="cellbg">Reference ID</th>
          <th className="cellbg">Ack Date</th>
        </tr>
      </thead>
      <tbody id="bdyPending1">
        {renderRows(filteredAndSortedTasks)}
        {renderRows(filteredAndSortedTasks1, true)}
        {renderRows(filteredAndSortedTasks2, true)}
      </tbody>
    </Table>
  );
};

export default MyHistoryTable;
