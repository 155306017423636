import axios from "axios";
import { URLs } from "./configuration";

const apiUrl = "https://amplifav4webapi-m1coaching.azurewebsites.net/api/";
axios.defaults.baseURL = apiUrl;

const defaultParam = {
  companyguid: sessionStorage.getItem("currentCompany"),
};

const createApiMethod = (url) => (param) =>
  axios.get(url, { params: { ...defaultParam, ...param } });

const APIs = Object.keys(URLs).reduce((acc, key) => {
  acc[key] = createApiMethod(URLs[key]);
  return acc;
}, {});

export default APIs;
