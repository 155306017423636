import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormatDate, Formathhmm, FormatIST } from "../../utils/dateFormat";
import {
  getGameByCompanyGuidOrgProgramEmpid,
  leaderBoardAPI,
} from "../../store/actions";
import { FormLoader } from "../../utils/Loader";
import "../../assets/css/gamifyy/normalize.css";
import "../../assets/css/gamifyy/webflow.css";
import "../../assets/css/gamifyy/gamify.css";
import "../../assets/css/leaderboard.css";

const GameTitleHeader = (props) => {
  return (
    <div className="game-tile-header" key={props.key}>
      <div>
        <div>
          <strong>{props.headerTitle}</strong>
        </div>
        <div className="bold-text-10">{props.headerText}</div>
      </div>
    </div>
  );
};

const LeaderBoardGameModal = ({ clickedRow, showModal, toggleModal }) => {
  const dispatch = useDispatch();

  const { activeOrganizationInfo, gameByIdInfo, isFetching, leaderBoardInfo } =
    useSelector(
      ({
        get_active_organization_detail: { data: activeOrganizationData },
        leader_board_api: { data: leaderBoardData },
        get_game_by_company_guid_org_program_emp_id: {
          data: gameByData,
          isFetching,
        },
      }) => {
        return {
          activeOrganizationInfo: activeOrganizationData || {},
          // gameByIdInfo: gameByData,
          gameByIdInfo: leaderBoardData?.gamedata,
          leaderBoardInfo: leaderBoardData,
          isFetching: isFetching,
        };
      },
      shallowEqual
    );
  const updateHeights = () => {
    const winHeight = window.innerHeight;
    document
      .querySelectorAll(
        ".game-section, .video-background-section, .video-wrap, .video-embed"
      )
      .forEach((el) => {
        el.style.height = `${winHeight}px`;
      });

    const divBlockHeight = document.querySelector(".div-block-568--new");
    if (divBlockHeight) {
      const fullTabHeight = winHeight - divBlockHeight.offsetHeight - 100;
      document.querySelector(".lead_hei").style.height = `${fullTabHeight}px`;
    }
  };
  useEffect(() => {
    updateHeights();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeights);

    // dispatch(
    //   getGameByCompanyGuidOrgProgramEmpid({
    //     Organization: activeOrganizationInfo?.Organization,
    //     CreatedByEmployeeid: activeOrganizationInfo?.Employeeid,
    //     Programid: 0,
    //     Primaryprogram: activeOrganizationInfo?.Program,
    //   })
    // );
    dispatch(
      leaderBoardAPI({
        companyguid: activeOrganizationInfo?.Companyguid,
        Organization: activeOrganizationInfo?.Organization,
        GameId: clickedRow?.gameid || clickedRow?.gameId,
        Primaryprogram: activeOrganizationInfo?.Program,
      })
    );
    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, []);

  const {
    createdByEmployeeName,
    gameStatus,
    gameName,
    gameId,
    metricname,
    startdate,
    gameTimeFrame,
    startdatetime,
    enddatetime,
    gameWinnerType,
    numberOfWinners,
    thresholdDsiplayValue,
    gameBadgeidUrl,
    dataLastUpdatedDateTime,
    gameThemeUrl,
  } = gameByIdInfo?.[0] || {};
  const gameHeader = [
    {
      headerTitle: "Game Organizer",
      headerText: createdByEmployeeName,
    },
    {
      headerTitle: "Game Status",
      headerText: gameStatus,
    },
    {
      headerTitle: "Game",
      headerText: gameName,
    },
    {
      headerTitle: "Metric",
      headerText: metricname,
    },
  ];
  const sortedLeaderBoard = [
    ...(leaderBoardInfo?.gameleaderboard_data || []),
  ].sort((a, b) => {
    // Sort items with null MemberRank last
    if (a.memberRank === null) return 1;
    if (b.memberRank === null) return -1;
    // Then sort by MemberRank
    return a.memberRank - b.memberRank;
  });
  let i = 0;
  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      //   className={className}
      backdrop={true}
      keyboard={true}
      fullscreen={true}
      id="leaderboard-game_modal"
    >
      <ModalBody id="" className="p-0">
        {isFetching ? (
          <div className="text-center">
            <FormLoader width={800} />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 game_leaderboard pd-0">
                <div className="position-relative">
                  <div className="game-section">
                    <div className="d-flex">
                      <div className="div-block-568--new">
                        {gameHeader.map((item, index) => (
                          <GameTitleHeader
                            key={index}
                            headerTitle={item.headerTitle}
                            headerText={item.headerText}
                          />
                        ))}
                        <div className="game-tile-header range">
                          <div>
                            <div>
                              <strong>Start</strong>
                            </div>
                            <div className="bold-text-10 w-embed w-script">
                              <span id="gameStartDate">
                                {gameTimeFrame == "I"
                                  ? `${FormatDate(startdate)} ${Formathhmm(
                                      startdatetime
                                    )}`
                                  : FormatDate(startdate)}
                              </span>
                            </div>
                          </div>
                          {/* <div
                            className="div-block-571 _5p"
                            style="width: @p% !important"
                          ></div> */}
                          <div className="div-block-570"></div>
                          <div>
                            <div>
                              <strong>End</strong>
                            </div>
                            <div className="bold-text-10 w-embed w-script">
                              <span id="gameEndDate">
                                {gameTimeFrame == "I"
                                  ? `${FormatDate(enddatetime)} ${Formathhmm(
                                      enddatetime
                                    )}`
                                  : FormatDate(enddatetime)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <GameTitleHeader
                          headerTitle="Type"
                          headerText={gameWinnerType}
                        />
                        {gameWinnerType?.toLowerCase == "rank" ? (
                          <GameTitleHeader
                            headerTitle="No. of Winners"
                            headerText={numberOfWinners}
                          />
                        ) : (
                          <GameTitleHeader
                            headerTitle="Threshold Value"
                            headerText={thresholdDsiplayValue}
                          />
                        )}
                        <div className="game-tile-header badge-space">
                          <img
                            src={gameBadgeidUrl}
                            loading="lazy"
                            alt=""
                            className="image-153"
                          />
                        </div>
                      </div>
                      {/* <div className="menu_icon">
                        <img src="images/messageicn.svg" />
                      </div> */}
                    </div>
                    <div className="">
                      {/* leaderBoardInfo */}
                      <div className="div-block-841">
                        <div className="div-block-837 lead_hei">
                          <div className="div-block-682">
                            <div className="div-block-849">
                              {sortedLeaderBoard?.map((gameItem, index) => {
                                let barpercentage = 2;
                                let constasub = 98;
                                if (gameItem.memberRank != null) {
                                  barpercentage =
                                    constasub - parseInt(gameItem.memberRank);
                                }
                                if (barpercentage <= 0) {
                                  barpercentage = 1;
                                }
                                i = i + 3;
                                return (
                                  <div className="game-row leader-collection">
                                    <div className="game-cell rank">
                                      <div>{gameItem.memberRank}</div>
                                      <div className="game-row leader-collection"></div>
                                    </div>
                                    <div className="game-cell name">
                                      <div>
                                        {gameItem.gameMembeEmployeeName}
                                      </div>
                                    </div>
                                    <div className="game-row leader-collection"></div>
                                    <div className="game-row leader-collection"></div>
                                    <div className="game-cell stretch">
                                      <div className="game-status-bar">
                                        <div
                                          className="game-status-bar-active _95p"
                                          style={{
                                            width: `${barpercentage}%`,
                                          }}
                                        ></div>
                                        <div className="game-status-bar-bg">
                                          <div className="div-block-850">
                                            <img
                                              src={gameBadgeidUrl}
                                              alt=""
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="game-cell score">
                                      {leaderBoardInfo?.percentileFlag ? (
                                        <div>
                                          {gameItem.memberMetricDsiplayValue ||
                                            "0.00"}
                                        </div>
                                      ) : (
                                        <div>
                                          {gameItem.memberMetricDsiplayValue}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="localtimeconversion"
                      id="lastupdateddatetime"
                    >
                      Updated Through {FormatIST(dataLastUpdatedDateTime)}
                    </div>
                  </div>
                </div>
                <div className="video-background-section">
                  <div
                    className="video-wrap"
                    style={{
                      zIndex: "unset",
                    }}
                  >
                    <div className="video-embed w-embed w-iframe">
                      {gameThemeUrl != null && gameThemeUrl.includes(".mp4") ? (
                        <video
                          autoPlay
                          loop
                          muted
                          playsInline
                          id="myVideo"
                          className="leaderboard__video video_hei"
                        >
                          <source src={gameThemeUrl} type="video/mp4" />
                        </video>
                      ) : (
                        <img className="video_hei" src={gameThemeUrl} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 pd-0 gray_bg hid">
                <div className="game_hd">
                  <div>
                    <h3>Game Chat</h3>
                  </div>
                  <div className="closee">
                    <img src="~/Content/assets/images/cross.svg" />
                  </div>
                </div>
                <div id="partialgamechat"></div>
              </div> */}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default LeaderBoardGameModal;
